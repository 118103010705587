import React, { useState, useEffect } from "react";
import AddEditSection from "../../components/AddEditSection";
import Breadcrumb from "../../parts/Breadcrumb";
import labels from "../../constants/FuelType";
import configuration from '../../config';
import { toast } from 'react-toastify';
import UND from 'underscore'

// For Add Help Category
function Add({ history }) {
  const [fields, setFields] = useState({});
  const [languages, setLanguages] = useState([])
  const [errors, setErrors] = useState({});

  const statusOpt = [
    { id: 1, title: "Active", value: "active" },
    { id: 2, title: "Inactive", value: "inactive" },
  ];

  const [fieldArr, setFieldArr] = useState([
    { id: 2, title: "Status", name: "status", type: "Dropdown", options: statusOpt },
  ]);

  useEffect(() => {
    configuration.getAPI({ url: 'language/list', params: { status: 'active' } }).then((data) => {
      if (data.status === 200) {
        setLanguages(data.payload)
        let languages = data.payload;
        let fieldArr_data = [];
        languages.map((language, index) => {
          fieldArr_data.push({
            id: 3 + index,
            title: `Title [${language.title}]`,
            name: `title_${language.code}`,
            type: "TextInput"
          });
        })
        setFieldArr([...fieldArr_data, ...fieldArr])
      }
    }).catch(error => {
      return toast.error(error.message)
    });
  }, []);



  function handleValidation() {
    let flag = true;
    let error = {}

    if (!fields.status) {
      error['status'] = "Please select status"
      flag = false
    }
    UND.each(languages, (lang, ind) => {
      if (!fields['title_' + lang.code]) {
        error['title_' + lang.code] = "Please enter title in " + lang.title
        flag = false
      }
    })
    setErrors({ ...error })
    return flag;
  }

  function handleChange(field, e) {
    setFields({ ...fields, [field]: e.target.value })
  }

  const handleSubmit = () => {
    if (handleValidation()) {
      configuration.postAPI({ url: 'fuel-type/create', params: fields }).then((data) => {
        if (data.status === 200) {
          history.push('/fuel-type')
        } else if (data.status === 404) {
          return toast.error(data.error.message)
        } else {
          return toast.error('Something went wrong')
        }
      }).catch(error => {
        return toast.error(error.message)
      });
    }
  }

  return (
    <>
      <Breadcrumb module={labels.addFuelType} />
      <AddEditSection
        title="Add Fuel Type"
        path="/fuel-type"
        fields={fieldArr}
        fieldError={errors}
        fieldValue={fields}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
      />
    </>
  );
}

export default Add;