import AddEditSection from "../../../components/AddEditSection";
import Breadcrumb from "../../../parts/Breadcrumb";
import React, { useEffect, useState } from "react";
import labels from "../../../constants/VehicleMake";
import configuration from '../../../config';
import { toast } from 'react-toastify';
import { each } from 'underscore';

// For Edit State
let Id = "";
function Edit({ history }) {
  const statusOpt = [
    { id: 1, title: "Active", value: "active" },
    { id: 2, title: "Inactive", value: "inactive" },
  ];
  const [languages, setLanguages] = useState([])
  const [fieldArr, setFieldArr] = useState([
    // { id: 1, title: "Image or Icon ", name: "icon", type: "FileInput" },
    {
      id: 2,
      title: "Status",
      name: "status",
      type: "Dropdown",
      options: statusOpt,
    },
  ]);
  const [errors, setErrors] = useState({
    title: "",
    description: "",
    status: "",
  });
  const [icon, setIcon] = useState(null);
  const [fields, setFields] = useState({});
  function handleValidation() {
    let flag = true;
    let error = {}
    each(languages, (lang, ind) => {
      if (!fields['title_' + lang.code]) {
        error['title_' + lang.code] = "Please enter title in " + lang.title
        flag = false
      }
    })

    if (!fields.status) {
      error['status'] = "Please select status"
      flag = false
    }
    setErrors({ ...error })
    return flag;
  }
  const handleSubmit = () => {
    if (handleValidation()) {
      const formData = new FormData();
      if (icon) {
        formData.append('icon', icon);
      }
      formData.append('fields', JSON.stringify({ ...fields, lang_code: 'EN' }));
      configuration.postFormDataAPI({ url: 'make/update', params: formData }).then((data) => {
        if (data.status === 200) {
          history.push('/vehiclemake')
        } else if (data.status === 404) {
          return toast.error(data.error.message)
        } else {
          return toast.error('Something went wrong')
        }
      }).catch(error => {
        return toast.error(error.message)
      });
    }
  }
  function handleChange(field, e) {
    if (field === 'icon') {
      setIcon(e.target.files[0]);
      setFields({ ...fields, [field]: URL.createObjectURL(e.target.files[0]) })
    } else {
      setFields({ ...fields, [field]: e.target.value })
    }
  }
  function cancleImage(field, e) {
    setIcon(null);
    setFields({ ...fields, [field]: '' })
  }
  useEffect(() => {
    // console.log(props.location)
    configuration.getAPI({ url: 'language/list', params: { status: 'active' } }).then((data) => {
      if (data.status === 200) {
        setLanguages(data.payload)
        let languages = data.payload;
        let fieldArr_data = [];
        languages.map((language, index) => {
          fieldArr_data.push({
            id: 3 + index,
            title: `Title [${language.title}]`,
            name: `title_${language.code}`,
            type: "TextInput"
          });
        })
        setFieldArr([...fieldArr, ...fieldArr_data])
      }
    }).catch(error => {
      return toast.error(error.message)
    });

    var url = window.location.href;
    Id = url.substring(url.lastIndexOf("/") + 1);
    configuration.getAPI({ url: 'make/list', params: { vehicle_make_id: Id } }).then((data) => {
      // console.log(data)
      if (data.status === 200) {
        setFields(data.payload)
      }
    }).catch(error => {
      // console.log(error)
      return toast.error(error.message)
    });
    // console.log(configuration.baseURL);
  }, []);
  return (
    <>
      <Breadcrumb module={labels.editVehicleMake} />
      <AddEditSection title="Edit VehicleMake" path="/vehiclemake" fields={fieldArr} fieldValue={fields} fieldError={errors} handleChange={handleChange} cancleImage={cancleImage} handleSubmit={handleSubmit} />
    </>
  );
}

export default Edit;
