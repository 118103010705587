import { ServerIcon, DocumentReportIcon } from "@heroicons/react/outline";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Breadcrumb from "../../parts/Breadcrumb";
import BtnRedirect from "../../components/Form/Button/BtnRedirect";
import BtnSaveRecord from "../../components/Form/Button/BtnSaveRecord";
import Caption from "../../components/Caption";
import Dropdown from "../../components/Form/Dropdown";
import Label from "../../components/Form/Label";
import TextArea from "../../components/Form/TextArea";
import FileInput from "../../components/Form/FileInput";
import TextInput from "../../components/Form/TextInput";
import SwitchInput from "../../components/Form/SwitchInput";
import labels from "../../constants/User";
import configuration from '../../config';
import CountryCode from '../../country_code';
import { toast } from 'react-toastify';
import { each } from 'underscore';
import { reactLocalStorage } from 'reactjs-localstorage';
var jwt = require('jsonwebtoken');

// Dynamic class
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

// For Edit User
let Id = "";
function Edit({ history }) {
  const [subNavigation, setSubNavigation] = useState([
    { name: "Edit Profile ", href: "#", icon: DocumentReportIcon, current: true },
    { name: "Change Password ", href: "#", icon: ServerIcon, current: false },
  ]);
  const [tab, setTab] = useState(0)
  const statusOpt = [
    { id: 1, title: "Active", value: "active" },
    { id: 2, title: "Inactive", value: "inactive" },
    { id: 3, title: "Archive", value: "archive" },
  ];
  const [roleOpt, setRoleOpt] = useState([]);
  const fieldArr = [
    { id: 0, title: "First Name", name: "first_name", type: "TextInput" },
    { id: 1, title: "Last Name", name: "last_name", type: "TextInput" },
    { id: 2, title: "Country Code", name: "mobile_country_code", type: "Dropdown", options: CountryCode.countries },
    { id: 3, title: "Mobile", name: "mobile", type: "TextInput", is_number: true },
    { id: 4, title: "Email", name: "email", type: "TextInput" },
    // { id: 5, title: "IP", name: "ip", type: "TextInput" },
    { id: 6, title: "Role", name: "role_id", type: "Dropdown", options: roleOpt },
    { id: 7, title: "Photo", name: "photo", type: "FileInput" },
    // { id: 8, title: "Two Step Verification", name: "is_two_step_verification", type: "SwitchInput" },
    { id: 9, title: "Status", name: "status", type: "Dropdown", options: statusOpt },
  ];
  const fieldArr1 = [
    { id: 0, title: "Password", name: "password", type: "TextInput", is_password: true },
    { id: 1, title: "Confirm Password", name: "cpassword", type: "TextInput", is_password: true },
  ];
  const [photo, setPhoto] = useState(null);
  const [fields, setFields] = useState({});
  const [isAdmin, setIsAdmin] = useState(false);
  const [errors, setErrors] = useState({
    name: "",
    mobile: "",
    email: "",
    role_id: "",
    status: "",
  });
  const [passFields, setPassFields] = useState({});
  const [passErrors, setPassErrors] = useState({
    password: '',
    cpassword: ''
  });
  function setTabMenu(index) {
    setTab(index)
    const navigate = subNavigation;
    each(navigate, (element, i) => {
      let currentState = false;
      if (i === index) {
        currentState = true;
      }
      navigate[i].current = currentState;
    });
    setSubNavigation(navigate);
  }
  function getRole() {
    configuration.getAPI({ url: 'role/list', params: { status: "active" } }).then((data) => {
      if (data.status === 200) {
        if (data.payload.length > 0) {
          data.payload.forEach((element) => {
            element.title = element.title;
            element.value = element.role_id;
            element.id = element.role_id;
            return element;
          });
        }
        setRoleOpt(data.payload)
      }
    }).catch(error => {
      return toast.error(error.message)
    });
  }
  useEffect(() => {
    var token = reactLocalStorage.get('token');
    jwt.verify(token, configuration.appName, function (err, decoded) {
      if (decoded) {
        // console.log(decoded)
        setIsAdmin(decoded.email === configuration.firstAdmin || decoded.email === configuration.secondAdmin)
      }
    });
    var url = window.location.href;
    Id = url.substring(url.lastIndexOf("/") + 1);
    configuration.getAPI({ url: 'user/list', params: { user_id: Id } }).then((data) => {
      if (data.status === 200) {
        setFields(data.payload)
      }
    }).catch(error => {
      return toast.error(error.message)
    });
    getRole()
  }, []);

  function handleChange(field, e) {
    if (field === 'photo') {
      setPhoto(e.target.files[0]);
      setFields({ ...fields, [field]: URL.createObjectURL(e.target.files[0]) })
    } else if (field === 'is_two_step_verification') {
      setFields({ ...fields, [field]: e })
    } else {
      setFields({ ...fields, [field]: e.target.value })
    }
  }

  function handleChangePass(field, e) {
    setPassFields({ ...passFields, [field]: e.target.value })
  }

  function validation() {
    let flag = true;
    let error = {}
    if (!fields.first_name) {
      error['first_name'] = "Please enter valid first name"
      flag = false
    }

    if (!fields.last_name) {
      error['last_name'] = "Please enter valid last name"
      flag = false
    }

    if (!fields.mobile) {
      error['mobile'] = "Please enter valid mobile"
      flag = false
    }
    if (!fields.email) {
      error['email'] = "Please enter valid email"
      flag = false
    }
    if (!fields.role_id) {
      error['role_id'] = "Please select role"
      flag = false
    }
    // if (!fields.photo) {
    //   error['photo'] = "Please select photo"
    //   flag = false
    // }
    if (!fields.status) {
      error['status'] = "Please select status"
      flag = false
    }
    setErrors({ ...error })
    return flag;
  }

  function validationPass() {
    let flag = true;
    let passErrors = {}
    if (!passFields.password) {
      passErrors['password'] = "Please enter password"
      flag = false
    }

    if (!passFields.cpassword) {
      passErrors['cpassword'] = "Please enter confirm password"
      flag = false
    }

    if (passFields.cpassword !== passFields.password) {
      passErrors['cpassword'] = "Password and confirm password doesn't match."
      flag = false
    }
    setPassErrors({ ...passErrors })
    return flag;
  }

  const handleChangePassword = () => {
    if (validationPass()) {
      console.log(passFields)
      configuration.postAPI({ url: 'user/change-password', params: { ...passFields, user_id: Id } }).then((data) => {
        // console.log(data)
        if (data.status === 200) {
          // history.push('/user')
          setPassFields({ password: '', cpassword: '' })
          return toast.success('Password changed successfully')
        } else if (data.error) {
          return toast.error(data.error.message)
        } else {
          return toast.error('Something went wrong')
        }
      }).catch(error => {
        // console.log(error)
        return toast.error(error.message)
      });
    }
  }
  const handleSubmit = () => {
    if (validation()) {
      // console.log("fields");
      // console.log(fields);
      if (!isAdmin) {
        delete fields.role_id;
        delete fields.is_two_step_verification;
        delete fields.ip;
      }
      const formData = new FormData();
      if (photo) {
        formData.append('photo', photo);
      }
      formData.append('fields', JSON.stringify({ ...fields, lang_code: 'EN' }));
      configuration.postFormDataAPI({ url: 'user/update', params: formData }).then((data) => {
        // console.log(data)
        if (data.status === 200) {
          history.push('/user')
          // setData(data.payload)
        } else if (data.error) {
          return toast.error(data.error.message)
        } else {
          return toast.error('Something went wrong')
        }
      }).catch(error => {
        // console.log(error)
        return toast.error(error.message)
      });
    }
  }
  function cancleImage(field, e) {
    setPhoto(null);
    setFields({ ...fields, [field]: '' })
  }
  return (
    <>
      <Breadcrumb module={labels.editUsers} />
      <div className="py-6 sm:px-6">
        <div className="relative">
          <section className="px-4" aria-labelledby="contact-heading">
            <div className="flex flex-col">
              <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                  <div className="mx-auto py-8 lg:flex lg:items-center lg:justify-between">
                    <Caption title="Edit Users" />
                  </div>
                  <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg bg-white box_bg border-bottom-theme">
                    <div className="">
                      <main className="relative">
                        <div className="mx-auto">
                          <div className="">
                            <div className="divide-y divide-gray-200 lg:grid lg:grid-cols-12 lg:divide-y-0">
                              <aside className="py-6 lg:col-span-2">
                                <nav className="space-y-1">
                                  {subNavigation.map((item, index) => (
                                    <Link
                                      to="#"
                                      key={item.name}
                                      onClick={() => setTabMenu(index)}
                                      onKeyDown={() => setTabMenu(index)}
                                      className={classNames(
                                        item.current
                                          ? "bg-gray-200 text-gray-900 text-teal-700 hover:bg-teal-50 hover:text-teal-700 rounded-md"
                                          : "border-transparent text-gray-500 hover:bg-gray-50 hover:text-gray-500 text_theme",
                                        "group px-3 py-2 flex items-center text-base"
                                      )}
                                      aria-current={item.current ? "page" : null}
                                    >
                                      <item.icon
                                        className={classNames(
                                          item.current
                                            ? "text-teal-500 group-hover:text-teal-500"
                                            : "text-gray-400 group-hover:text-gray-500",
                                          "flex-shrink-0 -ml-1 mr-3 h-6 w-6"
                                        )}
                                        aria-hidden="true"
                                      />
                                      <span className="truncate">
                                        {item.name}
                                      </span>
                                    </Link>
                                  ))}
                                </nav>
                              </aside>
                              {tab === 0 ? <div className="md:pl-8 sm:py-7 col-span-10">
                                <section aria-labelledby="applicant-information-title">
                                  <div className="bg-white shadow sm:rounded-lg">
                                    <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                                      <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                                        {fieldArr.map((field) => {
                                          return (
                                            <div className="sm:col-span-3" key={field.id}>
                                              <Label title={field.title} />
                                              <div className="mt-1">
                                                {field.type === "TextInput" ? (
                                                  <TextInput
                                                    id={field.name}
                                                    name={field.name}
                                                    handleChange={handleChange}
                                                    value={fields[field.name]}
                                                    is_number={field.is_number || false}
                                                    is_password={field.is_password || false}
                                                    is_read_only={(!isAdmin && field.name === 'ip') || false}
                                                  />
                                                ) : field.type === "FileInput" ? (
                                                  <FileInput
                                                    id={field.name}
                                                    name={field.name}
                                                    handleChange={handleChange}
                                                    value={fields[field.name]}
                                                    cancleImage={cancleImage}
                                                  />
                                                ) : field.type === "Dropdown" ? (
                                                  <Dropdown
                                                    id={field.name}
                                                    name={field.name}
                                                    handleChange={handleChange}
                                                    value={fields[field.name]}
                                                    status={field.options || []}
                                                    is_read_only={(!isAdmin && field.name === 'role_id') || false}
                                                  />
                                                ) : field.type === "SwitchInput" ? (
                                                  <SwitchInput
                                                    id={field.name}
                                                    name={field.name}
                                                    handleChange={handleChange}
                                                    value={fields[field.name]}
                                                    is_read_only={!isAdmin || false}
                                                  />
                                                ) : field.type === "TextArea" ? (
                                                  <TextArea
                                                    id={field.name}
                                                    name={field.name}
                                                  />
                                                ) : null}
                                              </div>
                                              {errors[field.name] ? <Label title={field.title} fieldError={errors[field.name]} /> : null}
                                            </div>
                                          );
                                        })}
                                      </div>
                                      <div className="pt-5 border_color_theme">
                                        <div className="flex justify-end">
                                          <BtnRedirect title="Cancel" path="/user" />
                                          <BtnSaveRecord handleSubmit={handleSubmit} title="Submit" path="/user" />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </section>
                              </div> : ''}

                              {tab === 1 ? <div className="md:pl-8 sm:py-7 col-span-10">
                                <section aria-labelledby="applicant-information-title">
                                  <div className="bg-white shadow sm:rounded-lg">
                                    <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                                      <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                                        {fieldArr1.map((field) => {
                                          return (
                                            <div className="sm:col-span-3" key={field.id}>
                                              <Label title={field.title} />
                                              <div className="mt-1">
                                                {field.type === "TextInput" ? (
                                                  <TextInput
                                                    id={field.name}
                                                    name={field.name}
                                                    handleChange={handleChangePass}
                                                    value={passFields[field.name]}
                                                    is_number={field.is_number || false}
                                                    is_password={field.is_password || false}
                                                  />
                                                ) : null}
                                              </div>
                                              {passErrors[field.name] ? <Label title={field.title} fieldError={passErrors[field.name]} /> : null}
                                            </div>
                                          );
                                        })}
                                      </div>
                                      <div className="pt-5 border_color_theme">
                                        <div className="flex justify-end">
                                          <BtnRedirect title="Cancel" path="/user" />
                                          <BtnSaveRecord handleSubmit={handleChangePassword} title="Submit" path="/user" />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </section>
                              </div> : ''}
                            </div>
                          </div>
                        </div>
                      </main>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default Edit;
