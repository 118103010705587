import { HomeIcon } from "@heroicons/react/outline";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import React from "react";
import labels from "../constants/Breadcrumb";

// For Navigate
function Breadcrumb({ module, page = 'trips' }) {
  let breadcrumbArr = [];
  switch (module) {
    case "Dashboard":
      breadcrumbArr = [{ name: "Dashboard", href: "#", current: true }];
      break;
    case "Setting":
      breadcrumbArr = [
        { name: "Adminland", href: "#", current: true },
      ];
      break;
    case "PersonalSetting":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Settings", href: "#", current: true },
      ];
      break;
    case "Role":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Role", href: "#", current: true },
      ];
      break;
    case "AddRole":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Role", href: "/role", current: false },
        { name: "Add Role", href: "#", current: true },
      ];
      break;
    case "EditRole":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Role", href: "/role", current: false },
        { name: "Edit Role", href: "#", current: true },
      ];
      break;
    case "Language":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Language", href: "#", current: true },
      ];
      break;
    case "AddLanguage":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Language", href: "/language", current: false },
        { name: "Add Language", href: "#", current: true },
      ];
      break;
    case "EditLanguage":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Language", href: "/language", current: false },
        { name: "Edit Language", href: "#", current: true },
      ];
      break;
    case "Label":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Label", href: "#", current: true },
      ];
      break;
    case "AddLabel":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Label", href: "/label", current: false },
        { name: "Add Label", href: "#", current: true },
      ];
      break;
    case "EditLabel":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Label", href: "/label", current: false },
        { name: "Edit Label", href: "#", current: true },
      ];
      break;
    case "Tax":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Tax", href: "#", current: true },
      ];
      break;
    case "AddTax":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Tax", href: "/tax", current: false },
        { name: "Add Tax", href: "#", current: true },
      ];
      break;
    case "EditTax":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Tax", href: "/tax", current: false },
        { name: "Edit Tax", href: "#", current: true },
      ];
      break;
    case "Country":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Country", href: "#", current: true },
      ];
      break;
    case "AddCountry":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Country", href: "/country", current: false },
        { name: "Add Country", href: "#", current: true },
      ];
      break;
    case "EditCountry":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Country", href: "/country", current: false },
        { name: "Edit Country", href: "#", current: true },
      ];
      break;
    case "State":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "State", href: "#", current: true },
      ];
      break;
    case "AddState":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "State", href: "/state", current: false },
        { name: "Add State", href: "#", current: true },
      ];
      break;
    case "EditState":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "State", href: "/state", current: false },
        { name: "Edit State", href: "#", current: true },
      ];
      break;
    case "City":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "City", href: "#", current: true },
      ];
      break;
    case "AddCity":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "City", href: "/city", current: false },
        { name: "Add City", href: "#", current: true },
      ];
      break;
    case "EditCity":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "City", href: "/city", current: false },
        { name: "Edit City", href: "#", current: true },
      ];
      break;
    case "FestivalArea":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Festival Area", href: "#", current: true },
      ];
      break;
    case "AddFestivalArea":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Festival Area", href: "/festival-area", current: false },
        { name: "Add Festival Area", href: "#", current: true },
      ];
      break;
    case "EditFestivalArea":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Festival Area", href: "/festival-area", current: false },
        { name: "Edit Festival Area", href: "#", current: true },
      ];
      break;
    case "Zipcode":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Zipcode", href: "#", current: true },
      ];
      break;
    case "AddZipcode":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Zipcode", href: "/city", current: false },
        { name: "Add Zipcode", href: "#", current: true },
      ];
      break;
    case "EditZipcode":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Zipcode", href: "/city", current: false },
        { name: "Edit Zipcode", href: "#", current: true },
      ];
      break;
    case "HelpCategory":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Help Category", href: "#", current: true },
      ];
      break;
    case "AddHelpCategory":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Help Category", href: "/help-category", current: false },
        { name: "Add Help Category", href: "#", current: true },
      ];
      break;
    case "EditHelpCategory":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Help Category", href: "/help-category", current: false },
        { name: "Edit Help Category", href: "#", current: true },
      ];
      break;
    case "Help":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Help", href: "#", current: true },
      ];
      break;
    case "AddHelp":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Help", href: "/help", current: false },
        { name: "Add Help", href: "#", current: true },
      ];
      break;
    case "EditHelp":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Help", href: "/help", current: false },
        { name: "Edit Help", href: "#", current: true },
      ];
      break;
    case "CMS":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "CMS", href: "#", current: true },
      ];
      break;
    case "AddCMS":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "CMS", href: "/cms", current: false },
        { name: "Add CMS", href: "#", current: true },
      ];
      break;
    case "EditCMS":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "CMS", href: "/cms", current: false },
        { name: "Edit CMS", href: "#", current: true },
      ];
      break;
    case "Currency":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Currency", href: "#", current: true },
      ];
      break;
    case "AddCurrency":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Currency", href: "/currency", current: false },
        { name: "Add Currency", href: "#", current: true },
      ];
      break;
    case "EditCurrency":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Currency", href: "/currency", current: false },
        { name: "Edit Currency", href: "#", current: true },
      ];
      break;
    case "EmailTemplates":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Email Template", href: "#", current: true },
      ];
      break;
    case "AddEmailTemplate":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Email Template", href: "/email-template", current: false },
        { name: "Add Email Template", href: "#", current: true },
      ];
      break;
    case "EditEmailTemplate":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Email Template", href: "/email-template", current: false },
        { name: "Edit Email Template", href: "#", current: true },
      ];
      break;
    case "PushTemplate":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Push Templates", href: "#", current: true },
      ];
      break;
    case "AddPushTemplate":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Push Template", href: "/push-template", current: false },
        { name: "Add Push Template", href: "#", current: true },
      ];
      break;
    case "EditPushTemplate":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Push Template", href: "/push-template", current: false },
        { name: "Edit Push Template", href: "#", current: true },
      ];
      break;
    case "SmsTemplate":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "SMS Template", href: "#", current: true },
      ];
      break;
    case "AddSmsTemplate":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Sms Template", href: "/sms-template", current: false },
        { name: "Add Push Template", href: "#", current: true },
      ];
      break;
    case "EditSmsTemplate":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Sms Template", href: "/sms-template", current: false },
        { name: "Edit Push Template", href: "#", current: true },
      ];
      break;
    case "Users":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "User", href: "#", current: true },
      ];
      break;
    case "AddUsers":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "User", href: "/user", current: false },
        { name: "Add User", href: "#", current: true },
      ];
      break;
    case "EditUsers":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "User", href: "/user", current: false },
        { name: "Edit User", href: "#", current: true },
      ];
      break;
    case "Dispatcher":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Dispatcher", href: "#", current: true },
      ];
      break;
    case "AddDispatcher":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Dispatcher", href: "/dispatcher", current: false },
        { name: "Add Dispatcher", href: "#", current: true },
      ];
      break;
    case "EditDispatcher":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Dispatcher", href: "/dispatcher", current: false },
        { name: "Edit Dispatcher", href: "#", current: true },
      ];
      break;
    case "Company":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Company", href: "#", current: true },
      ];
      break;
    case "AddCompany":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Company", href: "/company", current: false },
        { name: "Add Company", href: "#", current: true },
      ];
      break;
    case "EditCompany":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Company", href: "/company", current: false },
        { name: "Edit Company", href: "#", current: true },
      ];
      break;
    case "Partner":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Partner", href: "#", current: true },
      ];
      break;
    case "AddPartner":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Partner", href: "/partner", current: false },
        { name: "Add Partner", href: "#", current: true },
      ];
      break;
    case "EditPartner":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Partner", href: "/partner", current: false },
        { name: "Edit Partner", href: "#", current: true },
      ];
      break;
    case "Billing":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Billing", href: "#", current: true },
      ];
      break;
    case "AddBilling":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Billing", href: "/billing", current: false },
        { name: "Add Billing", href: "#", current: true },
      ];
      break;
    case "EditBilling":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Billing", href: "/billing", current: false },
        { name: "Edit Billing", href: "#", current: true },
      ];
      break;
    case "VehicleMake":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Vehicle Make", href: "#", current: true },
      ];
      break;
    case "AddVehicleMake":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Vehicle Make", href: "/vehiclemake", current: false },
        { name: "Add Vehicle Make", href: "#", current: true },
      ];
      break;
    case "EditVehicleMake":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Vehicle Make", href: "/vehiclemake", current: false },
        { name: "Edit Vehicle Make", href: "#", current: true },
      ];
      break;
    case "VehicleModel":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Vehicle Modal", href: "#", current: true },
      ];
      break;
    case "AddVehicleModel":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Vehicle Modal", href: "/vehiclemodel", current: false },
        { name: "Add Vehicle Modal", href: "#", current: true },
      ];
      break;
    case "EditVehicleModel":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Vehicle Modal", href: "/vehiclemodel", current: false },
        { name: "Edit Vehicle Model", href: "#", current: true },
      ];
      break;
    case "VehicleYear":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Vehicle Year", href: "#", current: true },
      ];
      break;
    case "AddVehicleYear":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Vehicle Year", href: "/vehicleyear", current: false },
        { name: "Add Vehicle Year", href: "#", current: true },
      ];
      break;
    case "EditVehicleYear":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Vehicle Year", href: "/vehicleyear", current: false },
        { name: "Edit Vehicle Year", href: "#", current: true },
      ];
      break;
    case "Vehicle":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Vehicle", href: "#", current: true },
      ];
      break;
    case "AddVehicle":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Vehicle", href: "/vehicle", current: false },
        { name: "Add Vehicle", href: "#", current: true },
      ];
      break;
    case "EditVehicle":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Vehicle", href: "/vehicle", current: false },
        { name: "Edit Vehicle", href: "#", current: true },
      ];
      break;
    case "RideOption":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Ride Option", href: "#", current: true },
      ];
      break;
    case "AddRideOption":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Ride Option", href: "/rideoption", current: false },
        { name: "Add Ride Option", href: "#", current: true },
      ];
      break;
    case "EditRideOption":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Ride Option", href: "/rideoption", current: false },
        { name: "Edit Ride Option", href: "#", current: true },
      ];
      break;
    case "MobilityOption":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Mobility Option", href: "#", current: true },
      ];
      break;
    case "AddMobilityOption":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Mobility Option", href: "/mobilityoption", current: false },
        { name: "Add Mobility Option", href: "#", current: true },
      ];
      break;
    case "EditMobilityOption":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Mobility Option", href: "/mobilityoption", current: false },
        { name: "Edit Mobility Option", href: "#", current: true },
      ];
      break;
    case "VehicleDocument":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Vehicle Document", href: "#", current: true },
      ];
      break;
    case "AddVehicleDocument":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Vehicle Document", href: "/vehicle-document", current: false },
        { name: "Add Vehicle Document", href: "#", current: true },
      ];
      break;
    case "EditVehicleDocument":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Vehicle Document", href: "/vehicle-document", current: false },
        { name: "Edit Vehicle Document", href: "#", current: true },
      ];
      break;
    case "DriverDocument":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Driver Document", href: "#", current: true },
      ];
      break;
    case "AddDriverDocument":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Driver Document", href: "/driver-document", current: false },
        { name: "Add Driver Document", href: "#", current: true },
      ];
      break;
    case "EditDriverDocument":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Driver Document", href: "/driver-document", current: false },
        { name: "Edit Driver Document", href: "#", current: true },
      ];
      break;
    case "VehiclePrice":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Vehicle Price", href: "#", current: true },
      ];
      break;
    case "Commission":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Company Commission", href: "#", current: true },
      ];
      break;
    case "Services":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Services", href: "#", current: true },
      ];
      break;
    case "AddServices":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Services", href: "/services", current: false },
        { name: "Add Services", href: "#", current: true },
      ];
      break;
    case "EditServices":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Services", href: "/services", current: false },
        { name: "Edit Services", href: "#", current: true },
      ];
      break;
    case "RentalsPrice":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Rentals Price", href: "#", current: true },
      ];
      break;
    case "AddRentalsPrice":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Rentals Price", href: "/rental-price", current: false },
        { name: "Add Rentals Price", href: "#", current: true },
      ];
      break;
    case "EditRentalsPrice":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Rentals Price", href: "/rental-price", current: false },
        { name: "Edit Rentals Price", href: "#", current: true },
      ];
      break;
    case "OutStationPrice":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Intercity Price", href: "#", current: true },
      ];
      break;
    case "AddOutStationPrice":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Intercity Price", href: "/out-station-price", current: false },
        { name: "Add Intercity Price", href: "#", current: true },
      ];
      break;
    case "EditOutStationPrice":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Intercity Price", href: "/out-station-price", current: false },
        { name: "Edit Intercity Price", href: "#", current: true },
      ];
      break;
    case "Feature":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Feature", href: "#", current: true },
      ];
      break;
    case "AddFeature":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Feature", href: "/feature", current: false },
        { name: "Add Feature", href: "#", current: true },
      ];
      break;
    case "EditFeature":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Feature", href: "/feature", current: false },
        { name: "Edit Feature", href: "#", current: true },
      ];
      break;
    case "Insurance":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Insurance", href: "#", current: true },
      ];
      break;
    case "AddInsurance":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Insurance", href: "/insurance", current: false },
        { name: "Add Insurance", href: "#", current: true },
      ];
      break;
    case "EditInsurance":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Insurance", href: "/insurance", current: false },
        { name: "Edit Insurance", href: "#", current: true },
      ];
      break;
    case "RentalPackage":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Rental Package", href: "#", current: true },
      ];
      break;
    case "AddRentalPackage":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Rental Package", href: "/rental-package", current: false },
        { name: "Add Rental Package", href: "#", current: true },
      ];
      break;
    case "EditRentalPackage":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Rental Package", href: "/rental-package", current: false },
        { name: "Edit Rental Package", href: "#", current: true },
      ];
      break;
    case "RentalPackageDesc":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Rental Package Description", href: "#", current: true },
      ];
      break;
    case "AddRentalPackageDesc":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Rental Package Description", href: "/rental-package-desc", current: false },
        { name: "Add Rental Package Description", href: "#", current: true },
      ];
      break;
    case "EditRentalPackageDesc":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Rental Package Description", href: "/rental-package-desc", current: false },
        { name: "Edit Rental Package Description", href: "#", current: true },
      ];
      break;
    case "AdditionalCharge":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Additional Charge", href: "#", current: true },
      ];
      break;
    case "AddAdditionalCharge":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Additional Charge", href: "/additional-charge", current: false },
        { name: "Add Additional Charge", href: "#", current: true },
      ];
      break;
    case "EditAdditionalCharge":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Additional Charge", href: "/additional-charge", current: false },
        { name: "Edit Additional Charge", href: "#", current: true },
      ];
      break;
    case "Coupon":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Coupon", href: "#", current: true },
      ];
      break;
    case "AddCoupon":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Coupon", href: "/coupon", current: false },
        { name: "Add Coupon", href: "#", current: true },
      ];
      break;
    case "EditCoupon":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Coupon", href: "/coupon", current: false },
        { name: "Edit Coupon", href: "#", current: true },
      ];
      break;
    case "RatingCriterias":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Rating Criterias", href: "#", current: true },
      ];
      break;
    case "AddRatingCriterias":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Rating Criterias", href: "/rating-criterias", current: false },
        { name: "Add Rating Criterias", href: "#", current: true },
      ];
      break;
    case "EditRatingCriterias":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Rating Criterias", href: "/rating-criterias", current: false },
        { name: "Edit Rating Criterias", href: "#", current: true },
      ];
      break;
    case "CancelReason":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Cancel Reason", href: "#", current: true },
      ];
      break;
    case "AddCancelReason":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Cancel Reason", href: "/cancel-reason", current: false },
        { name: "Add Cancel Reason", href: "#", current: true },
      ];
      break;
    case "EditCancelReason":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Cancel Reason", href: "/cancel-reason", current: false },
        { name: "Edit Cancel Reason", href: "#", current: true },
      ];
      break;
    case "Banner":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Banner", href: "#", current: true },
      ];
      break;
    case "AddBanner":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Banner", href: "/banner", current: false },
        { name: "Add Banner", href: "#", current: true },
      ];
      break;
    case "EditBanner":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Banner", href: "/banner", current: false },
        { name: "Edit Banner", href: "#", current: true },
      ];
      break;
    case "Passenger":
      breadcrumbArr = [
        { name: "Passenger", href: "#", current: true },
      ];
      break;
    case "AddPassenger":
      breadcrumbArr = [
        { name: "Passenger", href: "/passenger", current: false },
        { name: "Add Passenger", href: "#", current: true },
      ];
      break;
    case "EditPassenger":
      breadcrumbArr = [
        { name: "Passenger", href: "/passenger", current: false },
        { name: "Edit Passenger", href: "#", current: true },
      ];
      break;
    case "PassengerProfile":
      breadcrumbArr = [
        { name: "Passenger", href: "/passenger", current: false },
        { name: "Passenger Profile", href: "#", current: true },
      ];
      break;
    case "Driver":
      breadcrumbArr = [
        { name: "Driver", href: "#", current: true },
      ];
      break;
    case "ApproveDriver":
      breadcrumbArr = [
        { name: "Approved Drivers", href: "#", current: true },
      ];
      break;
    case "UnapprovedDriver":
      breadcrumbArr = [
        { name: "Unapproved Drivers", href: "#", current: true },
      ];
      break;
    case "EnrollmentDriver":
      breadcrumbArr = [
        { name: "Enrollment Drivers", href: "#", current: true },
      ];
      break;
    case "ActiveDriver":
      breadcrumbArr = [
        { name: "Active Drivers", href: "#", current: true },
      ];
      break;
    case "InactiveDriver":
      breadcrumbArr = [
        { name: "Inactive Drivers", href: "#", current: true },
      ];
      break;
    case "ArchiveDriver":
      breadcrumbArr = [
        { name: "Archive Drivers", href: "#", current: true },
      ];
      break;
    case "OnlineDriver":
      breadcrumbArr = [
        { name: "Online Drivers", href: "#", current: true },
      ];
      break;
    case "OfflineDriver":
      breadcrumbArr = [
        { name: "Offline Drivers", href: "#", current: true },
      ];
      break;
    case "AddDriver":
      breadcrumbArr = [
        { name: "Driver", href: `/${page}`, current: false },
        { name: "Add Driver", href: "#", current: true },
      ];
      break;
    case "EditDriver":
      breadcrumbArr = [
        { name: "Driver", href: `/${page}`, current: false },
        { name: "Edit Driver", href: "#", current: true },
      ];
      break;
    case "DriverProfile":
      breadcrumbArr = [
        { name: "Driver", href: `/${page}`, current: false },
        { name: "Driver Profile", href: "#", current: true },
      ];
      break;
    case "Trips":
      breadcrumbArr = [
        { name: "Trips", href: "#", current: true },
      ];
      break;
    case "SosLog":
      breadcrumbArr = [
        { name: "SOS", href: "#", current: true },
      ];
      break;
    case "NotificationLog":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Notification Log", href: "#", current: true },
      ];
      break;
    case "SendNotification":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Notification", href: "/notification-log", current: true },
        { name: "Send Notification", href: "#", current: true },
      ];
      break;
    case "LoginLogs":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Login Log", href: "#", current: true },
      ];
      break;
    default:
    case "DataBackup":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Database Backup", href: "#", current: true },
      ];
      break;
    case "Permission":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Access Right", href: "#", current: true },
      ];
      break;
    case "TripsDetail":
      breadcrumbArr = (page === 'dashboard') ? [
        { name: "Dashboard", href: "/dashboard", current: false },
        { name: "Trips Detail", href: "#", current: true },
      ] : [
        { name: "Dashboard", href: "/dashboard", current: false },
        { name: (page === 'sos') ? "SOS" : "Trips", href: `/${page}`, current: false },
        { name: "Trips Detail", href: "#", current: true },
      ];
      break;
    case "TripsInvoice":
      breadcrumbArr = [
        { name: "Dashboard", href: "/dashboard", current: false },
        { name: "Trips", href: `/${page}`, current: false },
        { name: "Invoice", href: "#", current: true },
      ];
      break;
    case "TopRidePassengers":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Top Ride Passengers", href: "#", current: true },
      ];
      break;
    case "TopRideDrivers":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Top Trip Accepting Drivers", href: "#", current: true },
      ];
      break;
    case "TopBookingCompany":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Top Trip Booking Company", href: "#", current: true },
      ];
      break;
    case "TopBookingPartner":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Top Trip Booking Partner", href: "#", current: true },
      ];
      break;
    case "TopBookingDispatcher":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Top Trip Booking Dispatcher", href: "#", current: true },
      ];
      break;
    case "SalesRevenue":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Sales Revenue", href: "#", current: true },
      ];
      break;
    case "NetProfits":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Net Profits", href: "#", current: true },
      ];
      break;
    case "Discount":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Discount", href: "#", current: true },
      ];
      break;
    case "TaxReport":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Tax Report", href: "#", current: true },
      ];
      break;
    case "AdditionalChargeReport":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Additional Charge Report", href: "#", current: true },
      ];
      break;
    case "CancelTrips":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Cancelled Trips Reports", href: "#", current: true },
      ];
      break;
    case "SuccessTrips":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Success Trips Reports", href: "#", current: true },
      ];
      break;
    case "DiscountTrips":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Discount Trips Reports", href: "#", current: true },
      ];
      break;
    case "TopRatePassengers":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Top Rate Passengers", href: "#", current: true },
      ];
      break;
    case "TopRateDrivers":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Top Rate Drivers", href: "#", current: true },
      ];
      break;
    case "AndroidUsers":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Android Users", href: "#", current: true },
      ];
      break;
    case "IosUsers":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "iOS Users", href: "#", current: true },
      ];
      break;

    case "WalletRefill":
      breadcrumbArr = [
        { name: "Dashboard", href: "/dashboard", current: false },
        { name: "Wallet Refill", href: "#", current: true },
      ];
      break;
    case "WalletHistory":
      breadcrumbArr = [
        { name: "Dashboard", href: "/dashboard", current: false },
        { name: "Wallet History", href: "#", current: true },
      ];
      break;

    case "FuelType":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Fuel Type", href: "#", current: true },
      ];
      break;
    case "AddFuelType":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Fuel Type", href: "/fuelType", current: false },
        { name: "Add Fuel Type", href: "#", current: true },
      ];
      break;
    case "EditFuelType":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Fuel Type", href: "/fuelType", current: false },
        { name: "Edit Fuel Type", href: "#", current: true },
      ];
      break;

    case "Subscription":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Subscription", href: "#", current: true },
      ];
      break;
    case "AddSubscription":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Subscription", href: "/subscription", current: false },
        { name: "Add Subscription", href: "#", current: true },
      ];
      break;
    case "EditSubscription":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Subscription", href: "/subscription", current: false },
        { name: "Edit Subscription", href: "#", current: true },
      ];
      break;
  }

  return (
    <nav
      className="bg-white border-b border-gray-200 flex xl-breadcrumb breadcrumb_theme"
      aria-label="Breadcrumb"
    >
      <ol
        // eslint-disable-next-line jsx-a11y/aria-role
        role="listData"
        className="max-w-screen-xl w-full px-4 flex space-x-4 sm:px-6 lg:px-8"
      >
        <li className="flex">
          <div className="flex items-center">
            <Link
              to="/dashboard"
              className="text-gray-700 hover:text-indigo-600 icon_theme"
            >
              <HomeIcon className="flex-shrink-0 h-5 w-5" aria-hidden="true" />
              <span className="sr-only">{labels.home}</span>
            </Link>
          </div>
        </li>
        {breadcrumbArr.map((page) => (
          <li key={page.name} className="flex">
            <div className="flex items-center">
              <svg
                className="flex-shrink-0 w-6 h-full text-gray-200 breadcrumb_separator"
                viewBox="0 0 24 44"
                preserveAspectRatio="none"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
              >
                <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
              </svg>
              {page.current ? (
                <span
                  className="ml-4 text-base font-medium text-gray-400"
                  aria-current={page.current ? "page" : null}
                >
                  {page.name}
                </span>
              ) : (
                <Link
                  to={page.href}
                  className="ml-4 text-base font-medium text-gray-700 hover:text-indigo-600 icon_theme"
                  aria-current={page.current ? "page" : null}
                >
                  {page.name}
                </Link>
              )}
            </div>
          </li>
        ))}
      </ol>
    </nav>
  );
}

Breadcrumb.propTypes = {
  module: PropTypes.string,
};

Breadcrumb.defaultProps = {
  module: "",
};

export default Breadcrumb;
