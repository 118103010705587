import { BrowserRouter as Router, Switch } from "react-router-dom";
import React, { useEffect } from "react";
import { reactLocalStorage } from 'reactjs-localstorage';
import configuration from './config';
import { toast } from 'react-toastify';

import TrackerRoute from "./layout/TrackerRoute";

// Add Components
import AddCity from "./pages/City/Add";
import AddFestivalArea from "./pages/FestivalArea/Add";
import AddCms from "./pages/CMS/Add";
import AddCountry from "./pages/Country/Add";
import AddCurrency from "./pages/Currency/Add";
import AddEmailTemplate from "./pages/EmailTemplate/Add";
import AddHelp from "./pages/Help/Add";
import AddHelpCategory from "./pages/HelpCategory/Add";
import AddLabel from "./pages/Label/Add";
import AddLanguage from "./pages/Language/Add";
import AddPushTemplate from "./pages/PushTemplate/Add";
import AddRole from "./pages/Role/Add";
import AddSmsTemplate from "./pages/SmsTemplate/Add";
import AddState from "./pages/State/Add";
import AddTax from "./pages/Tax/Add";
import AddUsers from "./pages/User/Add";
import AddZipcode from "./pages/Zipcode/Add";

import AddVehicleMake from "./pages/VehicleMasters/VehicleMake/Add";
import AddVehicleModel from "./pages/VehicleMasters/VehicleModel/Add";
import AddVehicleYear from "./pages/VehicleMasters/VehicleYear/Add";
import AddVehicle from "./pages/VehicleMasters/Vehicle/Add";
import AddRideOption from "./pages/VehicleMasters/RideOption/Add";
import AddMobilityOption from "./pages/VehicleMasters/MobilityOption/Add";
import AddVehicleDocument from "./pages/VehicleMasters/VehicleDocument/Add";
import AddDriverDocument from "./pages/DriverDocument/Add";
import AddServices from "./pages/VehicleMasters/Services/Add";
import AddPassenger from "./pages/Passenger/Add";
import AddDriver from "./pages/Driver/Add";
import AddRentalPrice from "./pages/VehicleMasters/RentalPrice/Add";
import AddOutStationPrice from "./pages/VehicleMasters/OutStationPrice/Add";
import AddFeature from "./pages/VehicleMasters/Feature/Add";
import AddInsurance from "./pages/VehicleMasters/Insurance/Add";
import AddRentalPackage from "./pages/VehicleMasters/RentalPackage/Add";
import AddRentalPackageDesc from "./pages/VehicleMasters/RentalPackageDesc/Add";
import AddAdditionalCharge from "./pages/VehicleMasters/AdditionalCharge/Add";
import AddDispatcher from "./pages/Dispatcher/Add";
import AddPartner from "./pages/Partner/Add";
import AddCompany from "./pages/Company/Add";
import AddBilling from "./pages/Billing/Add";
import AddCoupon from "./pages/Coupon/Add";
import AddRatingCriterias from "./pages/RatingCriterias/Add";
import AddCancelReason from "./pages/CancelReason/Add";
import AddBanner from "./pages/Banner/Add";
import AddModule from "./pages/Module/Add";

// Edit Components
import EditCity from "./pages/City/Edit";
import EditFestivalArea from "./pages/FestivalArea/Edit";
import EditCms from "./pages/CMS/Edit";
import EditCountry from "./pages/Country/Edit";
import EditCurrency from "./pages/Currency/Edit";
import EditEmailTemplate from "./pages/EmailTemplate/Edit";
import EditHelp from "./pages/Help/Edit";
import EditHelpCategory from "./pages/HelpCategory/Edit";
import EditLabel from "./pages/Label/Edit";
import EditLanguage from "./pages/Language/Edit";
import EditPushTemplate from "./pages/PushTemplate/Edit";
import EditRole from "./pages/Role/Edit";
import EditSmsTemplate from "./pages/SmsTemplate/Edit";
import EditState from "./pages/State/Edit";
import EditTax from "./pages/Tax/Edit";
import EditUsers from "./pages/User/Edit";
import EditZipcode from "./pages/Zipcode/Edit";

import EditVehicleMake from "./pages/VehicleMasters/VehicleMake/Edit";
import EditVehicleModel from "./pages/VehicleMasters/VehicleModel/Edit";
import EditVehicleYear from "./pages/VehicleMasters/VehicleYear/Edit";
import EditVehicle from "./pages/VehicleMasters/Vehicle/Edit";
import EditRideOption from "./pages/VehicleMasters/RideOption/Edit";
import EditMobilityOption from "./pages/VehicleMasters/MobilityOption/Edit";
import EditVehicleDocument from "./pages/VehicleMasters/VehicleDocument/Edit";
import EditDriverDocument from "./pages/DriverDocument/Edit";
import EditServices from "./pages/VehicleMasters/Services/Edit";
import EditPassenger from "./pages/Passenger/Edit";
import EditDriver from "./pages/Driver/Edit";
import EditRentalPrice from "./pages/VehicleMasters/RentalPrice/Edit";
import EditOutStationPrice from "./pages/VehicleMasters/OutStationPrice/Edit";
import EditFeature from "./pages/VehicleMasters/Feature/Edit";
import EditInsurance from "./pages/VehicleMasters/Insurance/Edit";
import EditRentalPackage from "./pages/VehicleMasters/RentalPackage/Edit";
import EditRentalPackageDesc from "./pages/VehicleMasters/RentalPackageDesc/Edit";
import EditAdditionalCharge from "./pages/VehicleMasters/AdditionalCharge/Edit";
import EditDispatcher from "./pages/Dispatcher/Edit";
import EditPartner from "./pages/Partner/Edit";
import EditCompany from "./pages/Company/Edit";
import EditBilling from "./pages/Billing/Edit";
import EditCoupon from "./pages/Coupon/Edit";
import EditRatingCriterias from "./pages/RatingCriterias/Edit";
import EditCancelReason from "./pages/CancelReason/Edit";
import EditBanner from "./pages/Banner/Edit";
import EditModule from "./pages/Module/Edit";

// List Components
import City from "./pages/City/View";
import FestivalArea from "./pages/FestivalArea/View";
import Cms from "./pages/CMS/View";
import Country from "./pages/Country/View";
import Currency from "./pages/Currency/View";
import Dashboard from "./pages/Dashboard/View";
import Orders from "./pages/Orders/View";
import EmailTemplate from "./pages/EmailTemplate/View";
import ForgotPassword from "./pages/ForgotPassword/View";
import GuestRoute from "./layout/GuestRoute";
import Help from "./pages/Help/View";
import HelpCategory from "./pages/HelpCategory/View";
import Label from "./pages/Label/View";
import Language from "./pages/Language/View";
import LoginLog from "./pages/LoginLog/View";
import DataBackup from "./pages/DataBackup/View";
import Permission from "./pages/AccessRight/View";
import PersonalSettings from "./pages/Setting/View";
import PushTemplate from "./pages/PushTemplate/View";
import Role from "./pages/Role/View";
import Setting from "./pages/Setting/Menu";
import SignIn from "./pages/SignIn/View";
import OTP from "./pages/OTP/View";
import Reset from "./pages/Reset/View";
import SmsTemplate from "./pages/SmsTemplate/View";
import State from "./pages/State/View";
import Tax from "./pages/Tax/View";
import TemplateRoute from "./layout/TemplateRoute";
import Users from "./pages/User/View";
import Zipcode from "./pages/Zipcode/View";
import PageNotFound from "./pages/404/View";

import VehicleMake from "./pages/VehicleMasters/VehicleMake/View";
import VehicleModel from "./pages/VehicleMasters/VehicleModel/View";
import VehicleYear from "./pages/VehicleMasters/VehicleYear/View";
import Vehicle from "./pages/VehicleMasters/Vehicle/View";
import RideOption from "./pages/VehicleMasters/RideOption/View";
import MobilityOption from "./pages/VehicleMasters/MobilityOption/View";
import VehicleDocument from "./pages/VehicleMasters/VehicleDocument/View";
import DriverDocument from "./pages/DriverDocument/View";
import Services from "./pages/VehicleMasters/Services/View";
import Price from "./pages/VehicleMasters/Price/View";
import RentalPrice from "./pages/VehicleMasters/RentalPrice/View";
import OutStationPrice from "./pages/VehicleMasters/OutStationPrice/View";
import Commission from "./pages/VehicleMasters/Commission/View";
import Feature from "./pages/VehicleMasters/Feature/View";
import Insurance from "./pages/VehicleMasters/Insurance/View";
import RentalPackage from "./pages/VehicleMasters/RentalPackage/View";
import RentalPackageDesc from "./pages/VehicleMasters/RentalPackageDesc/View";
import AdditionalCharge from "./pages/VehicleMasters/AdditionalCharge/View";
import Passenger from "./pages/Passenger/View";
import PassengerProfile from "./pages/Passenger/Profile";
import Driver from "./pages/Driver/View";
import ApprovedDriver from "./pages/Driver/list/approved-driver";
import UnapprovedDriver from "./pages/Driver/list/unapproved-driver";
import EnrollmentDriver from "./pages/Driver/list/enrollment-driver";
import ActiveDriver from "./pages/Driver/list/active-driver";
import InactiveDriver from "./pages/Driver/list/inactive-driver";
import ArchiveDriver from "./pages/Driver/list/archive-driver";
import OnlineDriver from "./pages/Driver/list/online-driver";
import OfflineDriver from "./pages/Driver/list/offline-driver";
import DriverProfile from "./pages/Driver/Profile";
import Dispatcher from "./pages/Dispatcher/View";
import Partner from "./pages/Partner/View";
import Company from "./pages/Company/View";
import Billing from "./pages/Billing/View";
import Coupon from "./pages/Coupon/View";
import RatingCriterias from "./pages/RatingCriterias/View";
import CancelReason from "./pages/CancelReason/View";
import Banner from "./pages/Banner/View";
import Module from "./pages/Module/View";

import TopRidePassengers from "./pages/Report/UserReport/top-ride-passengers";
import TopRideDrivers from "./pages/Report/UserReport/top-ride-drivers";
import TopBookingCompany from "./pages/Report/UserReport/top-booking-company";
import TopBookingPartner from "./pages/Report/UserReport/top-booking-partner";
import TopBookingDispatcher from "./pages/Report/UserReport/top-booking-dispatcher";
import AndroidUsers from "./pages/Report/UserReport/android-users";
import IosUsers from "./pages/Report/UserReport/ios-users";

import SalesRevenue from "./pages/Report/FinancialReport/sales";
import NetProfits from "./pages/Report/FinancialReport/net-profit";
import Discount from "./pages/Report/FinancialReport/discount";
import TaxReport from "./pages/Report/FinancialReport/tax-report";
import AdditionalChargeReport from "./pages/Report/FinancialReport/additional-charge-report";

import TopRatePassengers from "./pages/Report/TripReport/top-rate-passengers";
import TopRateDrivers from "./pages/Report/TripReport/top-rate-drivers";
import SuccessTrips from "./pages/Report/TripReport/success-trips";
import DiscountTrips from "./pages/Report/TripReport/discount-trips";
import CancelTrips from "./pages/Report/TripReport/cancel-trips";

import Tracker from "./pages/Tracker/View";

import WalletRefill from "./pages/Wallet/WalletRefill";
import WalletHistory from "./pages/Wallet/WalletHistory";

import Trips from "./pages/Trips/View";
import NewTrips from "./pages/Trips/newTrip";
import FailTrips from "./pages/Trips/failTrip";
import AcceptedTrips from "./pages/Trips/acceptedTrip";
import StartedTrips from "./pages/Trips/startedTrip";
import CompletedTrips from "./pages/Trips/completedTrip";
import CancelledTrips from "./pages/Trips/cancelledTrip";
import ScheduledTrips from "./pages/Trips/scheduledTrip";
import TripsDetail from "./pages/Trips/TripsDetail";
import Invoice from "./pages/Trips/Invoice";
import CreateRequest from "./pages/CreateRequest/Add";

import NotificationLog from "./pages/Notification/log";
import NotificationSend from "./pages/Notification/send";
import SOS from "./pages/SOS/log";

import ViewFuelType from "./pages/FuelType/View";
import AddFuelType from "./pages/FuelType/Add";
import EditFuelType from "./pages/FuelType/Edit";

import ViewSubscription from "./pages/subscription/View";
import AddSubscription from "./pages/subscription/Add";
import EditSubscription from "./pages/subscription/Edit";

// Manage Routes
function App() {
  useEffect(() => {
    configuration.getAPI({ url: 'common/get-settings-new', params: {} }).then((data) => {
      if (data.status === 200) {
        document.title = data.payload.app_name
        const dark_mode = document.getElementById('style-direction');
        dark_mode.href = (reactLocalStorage.get('dark_mode') === 'yes') ? '/css/tailwind-dark.css' : '/css/tailwind-light.css';

        const css_name = document.getElementById('style-color');
        css_name.href = `/css/${(data.payload.admin_color) ? data.payload.admin_color : data.payload.admin_color}.css`;
      }
    }).catch(error => {
      return toast.error(error.message)
    });
  }, []);
  return (
    <main>
      <Router>
        <Switch>
          <GuestRoute exact path="/" component={SignIn} />
          <GuestRoute
            exact
            path="/forgot-password"
            component={ForgotPassword}
          />
          <GuestRoute exact path="/otp" component={OTP} />
          <GuestRoute exact path="/reset" component={Reset} />
          <TemplateRoute exact path="/dashboard" component={Dashboard} />
          <TemplateRoute exact path="/orders" component={Orders} />
          <TemplateRoute exact path="/setting" component={Setting} />
          <TemplateRoute
            exact
            path="/personal-settings"
            component={PersonalSettings}
          />
          <TemplateRoute exact path="/role" component={Role} />
          <TemplateRoute exact path="/role/add" component={AddRole} />
          <TemplateRoute exact path="/role/edit/:id" component={EditRole} />
          <TemplateRoute exact path="/language" component={Language} />
          <TemplateRoute exact path="/language/add" component={AddLanguage} />
          <TemplateRoute
            exact
            path="/language/edit/:id"
            component={EditLanguage}
          />
          <TemplateRoute exact path="/label" component={Label} />
          <TemplateRoute exact path="/label/add" component={AddLabel} />
          <TemplateRoute exact path="/label/edit/:id" component={EditLabel} />
          <TemplateRoute exact path="/tax" component={Tax} />
          <TemplateRoute exact path="/tax/add" component={AddTax} />
          <TemplateRoute exact path="/tax/edit/:id" component={EditTax} />
          <TemplateRoute exact path="/country" component={Country} />
          <TemplateRoute exact path="/country/add" component={AddCountry} />
          <TemplateRoute
            exact
            path="/country/edit/:id"
            component={EditCountry}
          />
          <TemplateRoute exact path="/state" component={State} />
          <TemplateRoute exact path="/state/add" component={AddState} />
          <TemplateRoute exact path="/state/edit/:id" component={EditState} />
          <TemplateRoute exact path="/city" component={City} />
          <TemplateRoute exact path="/city/add" component={AddCity} />
          <TemplateRoute exact path="/city/edit/:id" component={EditCity} />
          <TemplateRoute exact path="/festival-area" component={FestivalArea} />
          <TemplateRoute
            exact
            path="/festival-area/add"
            component={AddFestivalArea}
          />
          <TemplateRoute
            exact
            path="/festival-area/edit/:id"
            component={EditFestivalArea}
          />
          <TemplateRoute exact path="/zipcode" component={Zipcode} />
          <TemplateRoute exact path="/zipcode/add" component={AddZipcode} />
          <TemplateRoute
            exact
            path="/zipcode/edit/:id"
            component={EditZipcode}
          />
          <TemplateRoute exact path="/help-category" component={HelpCategory} />
          <TemplateRoute
            exact
            path="/help-category/add"
            component={AddHelpCategory}
          />
          <TemplateRoute
            exact
            path="/help-category/edit/:id"
            component={EditHelpCategory}
          />
          <TemplateRoute exact path="/help" component={Help} />
          <TemplateRoute exact path="/help/add" component={AddHelp} />
          <TemplateRoute exact path="/help/edit/:id" component={EditHelp} />
          <TemplateRoute exact path="/cms" component={Cms} />
          <TemplateRoute exact path="/cms/add" component={AddCms} />
          <TemplateRoute exact path="/cms/edit/:id" component={EditCms} />
          <TemplateRoute exact path="/currency" component={Currency} />
          <TemplateRoute exact path="/currency/add" component={AddCurrency} />
          <TemplateRoute
            exact
            path="/currency/edit/:id"
            component={EditCurrency}
          />
          <TemplateRoute
            exact
            path="/email-template"
            component={EmailTemplate}
          />
          <TemplateRoute
            exact
            path="/email-template/add"
            component={AddEmailTemplate}
          />
          <TemplateRoute
            exact
            path="/email-template/edit/:id"
            component={EditEmailTemplate}
          />
          <TemplateRoute exact path="/push-template" component={PushTemplate} />
          <TemplateRoute
            exact
            path="/push-template/add"
            component={AddPushTemplate}
          />
          <TemplateRoute
            exact
            path="/push-template/edit/:id"
            component={EditPushTemplate}
          />
          <TemplateRoute exact path="/sms-template" component={SmsTemplate} />
          <TemplateRoute
            exact
            path="/sms-template/add"
            component={AddSmsTemplate}
          />
          <TemplateRoute
            exact
            path="/sms-template/edit/:id"
            component={EditSmsTemplate}
          />
          <TemplateRoute exact path="/user" component={Users} />
          <TemplateRoute exact path="/user/add" component={AddUsers} />
          <TemplateRoute exact path="/user/edit/:id" component={EditUsers} />
          <TemplateRoute exact path="/log" component={LoginLog} />
          <TemplateRoute exact path="/data-backup" component={DataBackup} />
          <TemplateRoute exact path="/permission" component={Permission} />

          <TemplateRoute exact path="/vehiclemake" component={VehicleMake} />
          <TemplateRoute
            exact
            path="/vehiclemake/add"
            component={AddVehicleMake}
          />
          <TemplateRoute
            exact
            path="/vehiclemake/edit/:id"
            component={EditVehicleMake}
          />
          <TemplateRoute exact path="/vehiclemodel" component={VehicleModel} />
          <TemplateRoute
            exact
            path="/vehiclemodel/add"
            component={AddVehicleModel}
          />
          <TemplateRoute
            exact
            path="/vehiclemodel/edit/:id"
            component={EditVehicleModel}
          />
          <TemplateRoute exact path="/vehicleyear" component={VehicleYear} />
          <TemplateRoute
            exact
            path="/vehicleyear/add"
            component={AddVehicleYear}
          />
          <TemplateRoute
            exact
            path="/vehicleyear/edit/:id"
            component={EditVehicleYear}
          />
          <TemplateRoute exact path="/vehicle" component={Vehicle} />
          <TemplateRoute exact path="/vehicle/add" component={AddVehicle} />
          <TemplateRoute
            exact
            path="/vehicle/edit/:id"
            component={EditVehicle}
          />
          <TemplateRoute exact path="/rideoption" component={RideOption} />
          <TemplateRoute
            exact
            path="/rideoption/add"
            component={AddRideOption}
          />
          <TemplateRoute
            exact
            path="/rideoption/edit/:id"
            component={EditRideOption}
          />
          <TemplateRoute
            exact
            path="/mobilityoption"
            component={MobilityOption}
          />
          <TemplateRoute
            exact
            path="/mobilityoption/add"
            component={AddMobilityOption}
          />
          <TemplateRoute
            exact
            path="/mobilityoption/edit/:id"
            component={EditMobilityOption}
          />
          <TemplateRoute
            exact
            path="/vehicle-document"
            component={VehicleDocument}
          />
          <TemplateRoute
            exact
            path="/vehicle-document/add"
            component={AddVehicleDocument}
          />
          <TemplateRoute
            exact
            path="/vehicle-document/edit/:id"
            component={EditVehicleDocument}
          />
          <TemplateRoute
            exact
            path="/driver-document"
            component={DriverDocument}
          />
          <TemplateRoute
            exact
            path="/driver-document/add"
            component={AddDriverDocument}
          />
          <TemplateRoute
            exact
            path="/driver-document/edit/:id"
            component={EditDriverDocument}
          />
          <TemplateRoute exact path="/services" component={Services} />
          <TemplateRoute exact path="/services/add" component={AddServices} />
          <TemplateRoute
            exact
            path="/services/edit/:id"
            component={EditServices}
          />
          <TemplateRoute exact path="/passenger" component={Passenger} />
          <TemplateRoute exact path="/passenger/add" component={AddPassenger} />
          <TemplateRoute
            exact
            path="/passenger/edit/:id"
            component={EditPassenger}
          />
          <TemplateRoute
            exact
            path="/passenger/profile/:id"
            component={PassengerProfile}
          />
          <TemplateRoute exact path="/driver" component={Driver} />
          <TemplateRoute
            exact
            path="/approved-driver"
            component={ApprovedDriver}
          />
          <TemplateRoute
            exact
            path="/unapproved-driver"
            component={UnapprovedDriver}
          />
          <TemplateRoute
            exact
            path="/enrollment-driver"
            component={EnrollmentDriver}
          />
          <TemplateRoute exact path="/active-driver" component={ActiveDriver} />
          <TemplateRoute
            exact
            path="/inactive-driver"
            component={InactiveDriver}
          />
          <TemplateRoute exact path="/online-driver" component={OnlineDriver} />
          <TemplateRoute
            exact
            path="/offline-driver"
            component={OfflineDriver}
          />
          <TemplateRoute
            exact
            path="/archive-driver"
            component={ArchiveDriver}
          />
          <TemplateRoute exact path="/driver/add" component={AddDriver} />
          <TemplateRoute exact path="/driver/edit/:id" component={EditDriver} />
          <TemplateRoute
            exact
            path="/driver/profile/:id"
            component={DriverProfile}
          />
          <TemplateRoute exact path="/tracker" component={Tracker} />

          <TemplateRoute exact path="/walletrefill" component={WalletRefill} />
          <TemplateRoute
            exact
            path="/wallethistory"
            component={WalletHistory}
          />

          <TemplateRoute exact path="/trips" component={Trips} />
          <TemplateRoute exact path="/new-trips" component={NewTrips} />
          <TemplateRoute exact path="/fail-trips" component={FailTrips} />
          <TemplateRoute
            exact
            path="/accepted-trips"
            component={AcceptedTrips}
          />
          <TemplateRoute exact path="/started-trips" component={StartedTrips} />
          <TemplateRoute
            exact
            path="/completed-trips"
            component={CompletedTrips}
          />
          <TemplateRoute
            exact
            path="/cancelled-trips"
            component={CancelledTrips}
          />
          <TemplateRoute
            exact
            path="/scheduled-trips"
            component={ScheduledTrips}
          />
          <TemplateRoute
            exact
            path="/tripsdetail/:id"
            component={TripsDetail}
          />
          <TemplateRoute exact path="/invoice/:id" component={Invoice} />

          <TemplateRoute exact path="/price" component={Price} />
          <TemplateRoute exact path="/rental-price" component={RentalPrice} />
          <TemplateRoute
            exact
            path="/rental-price/add"
            component={AddRentalPrice}
          />
          <TemplateRoute
            exact
            path="/rental-price/edit/:id"
            component={EditRentalPrice}
          />
          <TemplateRoute
            exact
            path="/out-station-price"
            component={OutStationPrice}
          />
          <TemplateRoute
            exact
            path="/out-station-price/add"
            component={AddOutStationPrice}
          />
          <TemplateRoute
            exact
            path="/out-station-price/edit/:id"
            component={EditOutStationPrice}
          />
          <TemplateRoute exact path="/commission" component={Commission} />

          <TemplateRoute exact path="/dispatcher" component={Dispatcher} />
          <TemplateRoute
            exact
            path="/dispatcher/add"
            component={AddDispatcher}
          />
          <TemplateRoute
            exact
            path="/dispatcher/edit/:id"
            component={EditDispatcher}
          />
          <TemplateRoute exact path="/partner" component={Partner} />
          <TemplateRoute exact path="/partner/add" component={AddPartner} />
          <TemplateRoute
            exact
            path="/partner/edit/:id"
            component={EditPartner}
          />
          <TemplateRoute exact path="/company" component={Company} />
          <TemplateRoute exact path="/company/add" component={AddCompany} />
          <TemplateRoute
            exact
            path="/company/edit/:id"
            component={EditCompany}
          />
          <TemplateRoute exact path="/billing" component={Billing} />
          <TemplateRoute exact path="/billing/add" component={AddBilling} />
          <TemplateRoute
            exact
            path="/billing/edit/:id"
            component={EditBilling}
          />
          <TemplateRoute
            exact
            path="/create-request"
            component={CreateRequest}
          />
          <TemplateRoute exact path="/feature" component={Feature} />
          <TemplateRoute exact path="/feature/add" component={AddFeature} />
          <TemplateRoute
            exact
            path="/feature/edit/:id"
            component={EditFeature}
          />
          <TemplateRoute exact path="/insurance" component={Insurance} />
          <TemplateRoute exact path="/insurance/add" component={AddInsurance} />
          <TemplateRoute
            exact
            path="/insurance/edit/:id"
            component={EditInsurance}
          />
          <TemplateRoute
            exact
            path="/rental-package"
            component={RentalPackage}
          />
          <TemplateRoute
            exact
            path="/rental-package/add"
            component={AddRentalPackage}
          />
          <TemplateRoute
            exact
            path="/rental-package/edit/:id"
            component={EditRentalPackage}
          />
          <TemplateRoute
            exact
            path="/rental-package-desc"
            component={RentalPackageDesc}
          />
          <TemplateRoute
            exact
            path="/rental-package-desc/add"
            component={AddRentalPackageDesc}
          />
          <TemplateRoute
            exact
            path="/rental-package-desc/edit/:id"
            component={EditRentalPackageDesc}
          />
          <TemplateRoute
            exact
            path="/additional-charge"
            component={AdditionalCharge}
          />
          <TemplateRoute
            exact
            path="/additional-charge/add"
            component={AddAdditionalCharge}
          />
          <TemplateRoute
            exact
            path="/additional-charge/edit/:id"
            component={EditAdditionalCharge}
          />
          <TemplateRoute exact path="/coupon" component={Coupon} />
          <TemplateRoute exact path="/coupon/add" component={AddCoupon} />
          <TemplateRoute exact path="/coupon/edit/:id" component={EditCoupon} />
          <TemplateRoute exact path="/module" component={Module} />
          <TemplateRoute exact path="/module/add" component={AddModule} />
          <TemplateRoute exact path="/module/edit/:id" component={EditModule} />
          <TemplateRoute
            exact
            path="/rating-criterias"
            component={RatingCriterias}
          />
          <TemplateRoute
            exact
            path="/rating-criterias/add"
            component={AddRatingCriterias}
          />
          <TemplateRoute
            exact
            path="/rating-criterias/edit/:id"
            component={EditRatingCriterias}
          />
          <TemplateRoute exact path="/banner" component={Banner} />
          <TemplateRoute exact path="/banner/add" component={AddBanner} />
          <TemplateRoute exact path="/banner/edit/:id" component={EditBanner} />
          <TemplateRoute exact path="/cancel-reason" component={CancelReason} />
          <TemplateRoute
            exact
            path="/cancel-reason/add"
            component={AddCancelReason}
          />
          <TemplateRoute
            exact
            path="/cancel-reason/edit/:id"
            component={EditCancelReason}
          />
          <TemplateRoute
            exact
            path="/top-ride-passengers"
            component={TopRidePassengers}
          />
          <TemplateRoute
            exact
            path="/top-ride-drivers"
            component={TopRideDrivers}
          />
          <TemplateRoute
            exact
            path="/top-booking-company"
            component={TopBookingCompany}
          />
          <TemplateRoute
            exact
            path="/top-booking-partner"
            component={TopBookingPartner}
          />
          <TemplateRoute
            exact
            path="/top-booking-dispatcher"
            component={TopBookingDispatcher}
          />
          <TemplateRoute exact path="/android-users" component={AndroidUsers} />
          <TemplateRoute exact path="/ios-users" component={IosUsers} />
          <TemplateRoute exact path="/sales" component={SalesRevenue} />
          <TemplateRoute exact path="/net-profit" component={NetProfits} />
          <TemplateRoute exact path="/discount" component={Discount} />
          <TemplateRoute exact path="/tax-report" component={TaxReport} />
          <TemplateRoute
            exact
            path="/additional-charge-report"
            component={AdditionalChargeReport}
          />
          <TemplateRoute exact path="/success-trip" component={SuccessTrips} />
          <TemplateRoute
            exact
            path="/discount-trip"
            component={DiscountTrips}
          />
          <TemplateRoute exact path="/cancel-trip" component={CancelTrips} />
          <TemplateRoute
            exact
            path="/top-rate-passengers"
            component={TopRatePassengers}
          />
          <TemplateRoute
            exact
            path="/top-rate-drivers"
            component={TopRateDrivers}
          />
          <TemplateRoute
            exact
            path="/notification-log"
            component={NotificationLog}
          />
          <TemplateRoute
            exact
            path="/notification/send"
            component={NotificationSend}
          />
          <TemplateRoute exact path="/sos" component={SOS} />

          <TemplateRoute exact path="/fuel-type" component={ViewFuelType} />
          <TemplateRoute exact path="/fuel-type/add" component={AddFuelType} />
          <TemplateRoute exact path="/fuel-type/edit/:id" component={EditFuelType} />

          <TemplateRoute exact path="/subscription" component={ViewSubscription} />
          <TemplateRoute exact path="/subscription/add" component={AddSubscription} />
          <TemplateRoute exact path="/subscription/edit/:id" component={EditSubscription} />

          <GuestRoute component={PageNotFound} />
        </Switch>
      </Router>
    </main>
  );
}

export default App;
