import React, { useEffect, useState } from "react";
import AddEditSection from "../../components/AddEditSection";
import Breadcrumb from "../../parts/Breadcrumb";
import labels from "../../constants/FuelType";
import configuration from '../../config';
import { toast } from 'react-toastify';
import UND from 'underscore'

// For Edit Help Category
let Id = "";
function Edit({ history }) {
  const [fields, setFields] = useState({});
  const [languages, setLanguages] = useState([])
  const [errors, setErrors] = useState({});

  const statusOpt = [
    { id: 1, title: "Active", value: "active" },
    { id: 2, title: "Inactive", value: "inactive" },
  ];
  const [fieldArr, setFieldArr] = useState([])
  const columnArr = [
    { id: 2, title: "Status", name: "status", type: "Dropdown", options: statusOpt },
  ];

  useEffect(() => {
    var url = window.location.href;
    Id = url.substring(url.lastIndexOf("/") + 1);
    configuration.getAPI({ url: 'fuel-type/list', params: { fuel_type_id: Id } }).then((data) => {
      if (data.status === 200) {
        setFields(data.payload)
      }
    }).catch(error => {
      return toast.error(error.message)
    });
    getlanguages();
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  function getlanguages() {
    configuration.getAPI({ url: 'language/list', params: { status: 'active' } }).then((data) => {
      if (data.status === 200) {
        setLanguages(data.payload)
        let titleArr = []
        UND.each(data.payload, (lang, ind) => {
          titleArr.push({ id: ind, title: "Title[" + lang.title + "]", name: "title_" + lang.code, type: "TextInput" })
        })
        const fullCoulnmArr = titleArr.concat(columnArr);
        setFieldArr(fullCoulnmArr)
      }
    }).catch(error => {
      return toast.error(error.message)
    });
  }

  function handleValidation() {
    let flag = true;
    let error = {}

    if (!fields.status) {
      error['status'] = "Please select status"
      flag = false
    }
    UND.each(languages, (lang, ind) => {
      if (!fields['title_' + lang.code]) {
        error['title_' + lang.code] = "Please enter title in " + lang.title
        flag = false
      }
    })
    setErrors({ ...error })
    return flag;
  }

  function handleChange(field, e) {
    setFields({ ...fields, [field]: e.target.value })
  }

  const handleSubmit = () => {
    if (handleValidation()) {
      configuration.postAPI({ url: 'fuel-type/update', params: fields }).then((data) => {
        if (data.status === 200) {
          history.push('/fuel-type')
        } else if (data.error) {
          return toast.error(data.error.message)
        } else {
          return toast.error('Something went wrong')
        }
      }).catch(error => {
        return toast.error(error.message)
      });
    }
  }

  return (
    <>
      <Breadcrumb module={labels.editFuelType} />
      <AddEditSection
        title="Edit Fuel Type"
        path="/fuel-type"
        fields={fieldArr}
        fieldError={errors}
        fieldValue={fields}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
      />
    </>
  );
}

export default Edit;
