import React, { useState, useEffect } from "react";
import AddEditSection from "../../components/AddEditSection";
import Breadcrumb from "../../parts/Breadcrumb";
import labels from "../../constants/CMS";
import commonConstant from "../../constants/Common";
import configuration from '../../config';
import { toast } from 'react-toastify';
import UND from 'underscore'

// For Edit CMS
let Id = "";
function Edit({ history }) {
  const [fieldArr, setFieldArr] = useState([])
  const [fields, setFields] = useState({});
  const [errors, setErrors] = useState({});
  const [languages, setLanguages] = useState([])
  const [description, setDescription] = useState({})

  const statusOpt = [
    { id: 1, title: "Active", value: "active" },
    { id: 2, title: "Inactive", value: "inactive" },
    // { id: 3, title: "Archive", value: "archive" },
  ];

  const columnArr = [
    { id: 0, title: "Title", name: "title", type: "TextInput" },
    { id: 1, title: "Code", name: "template_code", type: "TextInput", is_read_only: true },
    {
      id: 2,
      title: "User Type",
      name: "user_type",
      type: "Dropdown",
      options: commonConstant.user_type,
    },
    { id: 3, title: "Status", name: "status", type: "Dropdown", options: statusOpt },
  ];

  useEffect(() => {
    var url = window.location.href;
    Id = url.substring(url.lastIndexOf("/") + 1);
    configuration.getAPI({ url: 'cms/list', params: { cms_id: Id } }).then((data) => {
      if (data.status === 200) {
        setFields(data.payload)
      }
    }).catch(error => {
      return toast.error(error.message)
    });
    getlanguages();
  }, []);

  function getlanguages() {
    configuration.getAPI({ url: 'language/list', params: { status: 'active' } }).then((data) => {
      if (data.status === 200) {
        setLanguages(data.payload)
        let descriptionArr = []
        let link_arr = [];
        UND.each(data.payload, (lang, ind) => {
          descriptionArr.push({ id: ind, title: "Description [" + lang.title + "]", name: "description_" + lang.code, type: "Editor", is_full_screen: true })
          link_arr.push({ id: ind, title: "Link[" + lang.title + "]", name: "link_" + lang.code, type: "CopyInput" })
        })
        const fullCoulnmArr = columnArr.concat(descriptionArr, link_arr);
        setFieldArr(fullCoulnmArr)
      }
    }).catch(error => {
      return toast.error(error.message)
    });
  }

  function handleChange(field, e) {
    if (field.search("description") >= 0) {
      setDescription({ ...description, [field]: e.target.getContent() })
    } else {
      setFields({ ...fields, [field]: e.target.value })
    }
  }

  function handleValidation() {
    let flag = true;
    let error = {}
    if (!fields.title) {
      error['title'] = "Please enter CMS title"
      flag = false
    }
    if (!fields.template_code) {
      error['template_code'] = "Please enter template code "
      flag = false
    }
    if (!fields.user_type) {
      error['user_type'] = "Please select user type"
      flag = false
    }
    if (!fields.status) {
      error['status'] = "Please select status"
      flag = false
    }

    // UND.each(languages, (lang, ind) => {
    //   if (!fields['description_' + lang.code]) {
    //     error['description_' + lang.code] = "Please enter description in " + lang.title
    //     flag = false
    //   }
    // })

    setErrors({ ...error })
    return flag;
  }

  const handleSubmit = () => {
    // let fullObj = {
    //   ...description,
    //   ...fields
    // };
    let fullObj = UND.extend(fields, description);
    if (handleValidation()) {
      configuration.postAPI({ url: 'cms/update', params: fullObj }).then((data) => {
        if (data.status === 200) {
          history.push('/cms')
        } else if (data.error) {
          return toast.error(data.error.message)
        } else {
          return toast.error('Something went wrong')
        }
      }).catch(error => {
        return toast.error(error.message)
      });
    }
  }

  return (
    <>
      <Breadcrumb module={labels.editCMS} />
      <AddEditSection
        title="Edit CMS"
        path="/cms"
        fields={fieldArr}
        fieldError={errors}
        fieldValue={fields}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
      />
    </>
  );
}

export default Edit;
