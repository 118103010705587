import React, { useEffect, useState } from "react";
import AddEditSection from "../../components/AddEditSection";
import Breadcrumb from "../../parts/Breadcrumb";
import labels from "../../constants/Subscription";
import configuration from "../../config";
import { toast } from "react-toastify";
import UND from "underscore";

// For Edit Help Category
let Id = "";
function Edit({ history }) {
  const [fields, setFields] = useState({});
  const [languages, setLanguages] = useState([]);
  const [errors, setErrors] = useState({});

  const statusOpt = [
    { id: 1, title: "Active", value: "active" },
    { id: 2, title: "Inactive", value: "inactive" },
  ];

  const userType = [
    { id: 1, title: "Day", value: "day" },
    { id: 2, title: "Trip", value: "trip" },
  ];

  const [fieldArr, setFieldArr] = useState([
    { id: 3, title: "Status", name: "status", type: "Dropdown", options: statusOpt },
    { id: 4, title: "Days / Trip", name: "value", type: "TextInput", is_number: true },
    { id: 5, title: "Type", name: "type", type: "Dropdown", options: userType },
    { id: 6, title: "Price", name: "price", type: "TextInput", is_number: true },
  ]);

  useEffect(() => {
    let url = window.location.href;
    let ID = url.substring(url.lastIndexOf("/") + 1);
    configuration
      .getAPI({ url: "language/list", params: { status: "active" } })
      .then((data1, index) => {
        // console.log(data)
        if (data1.payload) {
          setLanguages(data1.payload);
          configuration
            .getAPI({ url: "subscription/list", params: { ID } })
            .then((data) => {
              if (data.payload) {
                let titleObj = {};
                let fieldArr_data = [];
                UND.each(data1.payload, (lang, ind) => {
                  fieldArr_data.push({
                    id: 1 + ind,
                    title: `Title [${lang.title}]`,
                    name: `title_${lang.code}`,
                    type: "TextInput",
                  });
                  fieldArr_data.push({
                    id: 2 + ind,
                    title: `Description [${lang.title}]`,
                    name: `description_${lang.code}`,
                    type: "TextInput",
                  });

                  titleObj["title_" + lang.code] = data.payload.title[lang.code] ? data.payload.title[lang.code] : "";
                  titleObj["description_" + lang.code] = data.payload.description[lang.code]
                    ? data.payload.description[lang.code]
                    : "";
                });
                delete data.payload.title;
                setFields({ ...titleObj, ...data.payload });
                setFieldArr([...fieldArr_data, ...fieldArr]);
              }
            })
            .catch((error) => {
              return toast.error(error.message);
            });
        }
      })
      .catch((error) => {
        return toast.error(error.message);
      });
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  function getlanguages() {
    configuration
      .getAPI({ url: "language/list", params: { status: "active" } })
      .then((data) => {
        if (data.status === 200) {
          setLanguages(data.payload);
          let languages = data.payload;
          let fieldArr_data = [];
          languages.map((language, index) => {
            fieldArr_data.push({
              id: 1 + index,
              title: `Title [${language.title}]`,
              name: `title_${language.code}`,
              type: "TextInput",
            });
            fieldArr_data.push({
              id: 2 + index,
              title: `Description [${language.title}]`,
              name: `description_${language.code}`,
              type: "TextInput",
            });
          });

          setFieldArr([...fieldArr_data, ...fieldArr]);
        }
      })
      .catch((error) => {
        return toast.error(error.message);
      });
  }

  function handleValidation() {
    let flag = true;
    let error = {};
    UND.each(languages, (lang, ind) => {
      if (!fields["title_" + lang.code]) {
        error["title_" + lang.code] = "Please enter title in " + lang.title;
        flag = false;
      }
      if (!fields["description_" + lang.code]) {
        error["description_" + lang.code] = "Please enter description in " + lang.title;
        flag = false;
      }
    });
    if (!fields.type) {
      error["type"] = "Please select type";
      flag = false;
    }
    if (!fields.value) {
      error["value"] = "Please select value";
      flag = false;
    }
    if (!fields.price) {
      error["price"] = "Please select price";
      flag = false;
    }
    if (!fields.status) {
      error["status"] = "Please select status";
      flag = false;
    }
    setErrors({ ...error });
    return flag;
  }

  function handleChange(field, e) {
    setFields({ ...fields, [field]: e.target.value });
  }

  const handleSubmit = () => {
    if (handleValidation()) {
      let titleObj = {};
      let descriptionObj = {};
      UND.each(languages, (lang, ind) => {
        titleObj[lang.code] = fields["title_" + lang.code] ? fields["title_" + lang.code] : "";
        delete fields["title_" + lang.code];
        descriptionObj[lang.code] = fields["description_" + lang.code] ? fields["description_" + lang.code] : "";
        delete fields["description_" + lang.code];
      });
      fields.title = titleObj;
      fields.description = descriptionObj;
      configuration
        .postAPI({ url: "subscription/update", params: fields })
        .then((data) => {
          // console.log(data)
          if (data.payload) {
            toast.success("Updated Successfully");
            history.push("/subscription");
          } else if (data.error) {
            return toast.error(data.error.message);
          } else {
            return toast.error("Something went wrong");
          }
        })
        .catch((error) => {
          // console.log(error)
          return toast.error(error.message);
        });
    }
  };

  return (
    <>
      <Breadcrumb module={labels.editSubscription} />
      <AddEditSection
        title="Edit Subscription"
        path="/subscription"
        fields={fieldArr}
        fieldError={errors}
        fieldValue={fields}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
      />
    </>
  );
}

export default Edit;
