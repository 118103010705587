import { PhoneIcon, ServerIcon, PhoneOutgoingIcon, DocumentReportIcon, CurrencyRupeeIcon, LocationMarkerIcon, CogIcon, UserGroupIcon, CreditCardIcon, TruckIcon, ArchiveIcon, GiftIcon, QrcodeIcon, UsersIcon, DeviceMobileIcon, MailIcon } from "@heroicons/react/outline";
import React, { useState, useEffect, Fragment } from "react";
import Breadcrumb from "../../parts/Breadcrumb";
import { each, findWhere } from "underscore";
import labels from "../../constants/Passenger";
import { Link } from "react-router-dom";
import configuration from '../../config';
import Summary from './profile/summary';
import Trips from './profile/trips';
import FavouriteAddress from './profile/favouriteAddress';
import FavouriteDrivers from './profile/favouriteDrivers';
import Tickets from './profile/tickets';
import Contacts from './profile/Contacts';
import ContactsLog from './profile/contactsLog';
import Wallet from './profile/wallet';
import Referrals from './profile/referrals';
import Setting from './profile/setting';
import { toast } from 'react-toastify';
// Dynamic class
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

let Id = "";
// List Settings
function View() {
  const [subNavigation, setSubNavigation] = useState([
    { name: "Summary ", href: "#", icon: DocumentReportIcon, current: true },
    { name: "Favourite Address ", href: "#", icon: LocationMarkerIcon, current: false },
    { name: "Favourite Drivers ", href: "#", icon: UsersIcon, current: false },
    { name: "Emergency Contacts", href: "#", icon: PhoneIcon, current: false },
    /* { name: "Emergency Call Logs ", href: "#", icon: PhoneOutgoingIcon, current: false }, */
    { name: "Tickets ", href: "#", icon: GiftIcon, current: false },
    { name: "Trips ", href: "#", icon: LocationMarkerIcon, current: false },
    { name: "Wallet", href: "#", icon: CurrencyRupeeIcon, current: false },
    // { name: "Referrals ", href: "#", icon: UserGroupIcon, current: false },
    { name: "Other App Settings ", href: "#", icon: CogIcon, current: false },
  ]);

  const [tab, setTab] = useState(0)
  const [walletAccess, setWalletAccess] = useState(false)
  function setTabMenu(index) {
    setTab(index)
    const navigate = subNavigation;
    each(navigate, (element, i) => {
      let currentState = false;
      if (i === index) {
        currentState = true;
      }
      navigate[i].current = currentState;
    });
    setSubNavigation(navigate);
  }
  const [userData, setUserData] = useState({})

  useEffect(() => {
    // console.log(props.location)
    var url = window.location.href;
    Id = url.substring(url.lastIndexOf("/") + 1);
    configuration.getAPI({ url: 'passenger/detail', params: { user_id: Id } }).then((data) => {
      // console.log(data)
      if (data.status === 200) {
        setUserData(data.payload)
      }
    }).catch(error => {
      // console.log(error)
      return toast.error(error.message)
    });
    // console.log(configuration.baseURL);
    var roleID = localStorage.getItem('role_id');
    if (roleID && roleID !== '') {
      configuration.getAPI({ url: 'common/list-access', params: { roleID } }).then((data) => {
        if (data.payload) {
          // console.log(data.payload)
          let walletData = findWhere(data.payload, { code: "USER_WALLET" });
          setWalletAccess(walletData?.data?.is_view);
        }
      }).catch(error => {
        console.log(error)
        // return toast.error(error.message)
      });
      // history.push("/dashboard")
    } else {
      // history.push("/")
    }
  }, []);
  return (
    <>
      <Breadcrumb module={labels.passengerProfile} />
      <div className="py-6 sm:px-6">
        <div className="relative">
          <section className="px-4" aria-labelledby="contact-heading">
            <div className="flex flex-col">
              <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">

                  <div className="w-full md:flex md:items-center md:justify-between md:space-x-5 mb-6 mt-4">
                    <div className="flex items-top space-x-5">
                      <div className="flex-shrink-0">
                        <div className="relative">
                          <img
                            className="h-16 w-16 rounded-full"
                            src={(userData.photo) ? userData.photo : "/image/avatar.png"}
                            alt=""
                          />
                          <span className="absolute inset-0 shadow-inner rounded-full" aria-hidden="true" />
                        </div>
                      </div>
                      <div>
                        <h1 className="text-2xl font-bold text-gray-900">{userData.first_name} {userData.last_name}</h1>
                        <p class="mt-3 flex items-center text-base text-gray-500 font-medium sm:mr-6 sm:mt-0 capitalize py-1">
                          <DeviceMobileIcon
                            className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                          {userData.mobile_country_code} {userData.mobile}</p>
                        <p class="mt-3 flex items-center text-base text-gray-500 font-medium sm:mr-6 sm:mt-0 py-1">
                          <MailIcon
                            className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                          {userData.email}</p>
                        <p class="mt-3 flex items-center text-base text-gray-500 font-medium sm:mr-6 sm:mt-0 py-1">
                          <QrcodeIcon
                            className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                            {userData.invite_code}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="">
                    <main className="relative">
                      <div className="mx-auto">
                        <div className="">
                          <div className="divide-y divide-gray-200 lg:grid lg:grid-cols-12 lg:divide-y-0">
                            <aside className="py-6 lg:col-span-2">
                              <nav className="space-y-1">
                                {subNavigation.map((item, index) => {
                                  if (item.name !== 'Wallet' || (item.name === 'Wallet' && walletAccess)) {
                                    return (<Link
                                      to="#"
                                      key={item.name}
                                      onClick={() => setTabMenu(index)}
                                      onKeyDown={() => setTabMenu(index)}
                                      className={classNames(
                                        item.current
                                          ? "bg-gray-200 text-gray-900 text-teal-700 hover:bg-teal-50 hover:text-teal-700 rounded-md"
                                          : "border-transparent text-gray-500 hover:bg-gray-50 hover:text-gray-500 text_theme",
                                        "group px-3 py-2 flex items-center text-base"
                                      )}
                                      aria-current={item.current ? "page" : null}
                                    >
                                      <item.icon
                                        className={classNames(
                                          item.current
                                            ? "text-teal-500 group-hover:text-teal-500"
                                            : "text-gray-400 group-hover:text-gray-500",
                                          "flex-shrink-0 -ml-1 mr-3 h-6 w-6"
                                        )}
                                        aria-hidden="true"
                                      />
                                      <span className="truncate">
                                        {item.name}
                                      </span>
                                    </Link>
                                    )
                                  } else {
                                    return '';
                                  }

                                }
                                )}
                              </nav>
                            </aside>
                            {tab === 0 ? <Summary /> : ''}

                            {tab === 1 ? <FavouriteAddress /> : ''}

                            {tab === 2 ? <FavouriteDrivers /> : ''}

                            {tab === 3 ? <Contacts /> : ''}

                            {/* {tab === 4 ? <ContactsLog/> : ''} */}

                            {tab === 4 ? <Tickets /> : ''}

                            {tab === 5 ? <Trips /> : ''}

                            {tab === 6 ? <Wallet /> : ''}

                            {tab === 7 ? <Referrals /> : ''}

                            {tab === 8 ? <Setting /> : ''}

                          </div>
                        </div>
                      </div>
                    </main>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default View;
