
import Breadcrumb from "../../parts/Breadcrumb";
import { React, useEffect, Fragment, useState } from 'react'
import { Dialog, Transition, RadioGroup } from '@headlessui/react'
import labels from "../../constants/CreateRequest";
import configuration from '../../config';
import { toast } from 'react-toastify';
import { each } from 'underscore';
import Caption from "../../components/Caption";
import Dropdown from "../../components/Form/Dropdown";
import Label from "../../components/Form/Label";
import TextArea from "../../components/Form/TextArea";
import TextInput from "../../components/Form/TextInput";
import DateFnsUtils from "@date-io/date-fns";
import { TimePicker, DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import GoogleMapReact from 'google-map-react';
import { CheckCircleIcon, XIcon } from '@heroicons/react/solid'
import { Link } from 'react-router-dom'
// For Add Role


const mailingLists = [
  {
    id: 1, title: 'Ride', description: '',
    imageUrl:
      './image/sedan.svg',
  },
  {
    id: 2, title: 'Rental', description: '15% OFF',
    imageUrl:
      './image/sedan.svg',
  },
  {
    id: 3, title: 'Outstation', description: '25% OFF',
    imageUrl:
      './image/sedan.svg',
  },
]
const plans2 = [
  {
    name: 'Home',
    imageUrl:
      './image/home.svg',
  },
  {
    name: 'Work',
    imageUrl:
      './image/bag.svg',
  },
  {
    name: 'Others',
    imageUrl:
      './image/pin-grey.svg',
  },
]


const plans = [
  {
    name: 'Women Driver',
    imageUrl:
      './image/woman.svg',
  },
  {
    name: 'Wheel Chair',
    imageUrl:
      './image/wheel-chair.svg',
  },
  {
    name: 'Baby Seat',
    imageUrl:
      './image/baby.svg',
  },
]
const settings = [
  { name: 'Public access', description: 'This project would be available to anyone who has the link' },
  { name: 'Private to Project Members', description: 'Only members of this project would be able to access' },
  { name: 'Private to you', description: 'You are the only one able to access this project' },
  { name: 'Private to you', description: 'You are the only one able to access this project' },
]

const plans3 = [
  {
    name: '1 hr',
    km: '10 km',
  },
  {
    name: '2 hr',
    km: '20 km',
  },
  {
    name: '3 hr',
    km: '30 km',
  },
  {
    name: '4 hr',
    km: '50 km',
  },
  {
    name: '5 hr',
    km: '60 km',
  },
  {
    name: '5 hr',
    km: '60 km',
  },
  {
    name: '5 hr',
    km: '60 km',
  },
  {
    name: '5 hr',
    km: '60 km',
  },
]


const plans4 = [
  { id: 1, title: 'One-way', description: 'Get dropped off', },
  { id: 2, title: 'Round trip', description: 'Keep the car till re…', },
]


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function Add({ history }) {

  const [open, setOpen] = useState(false)
  const [open4, setOpen4] = useState(false)
  const [selectedMailingLists, setSelectedMailingLists] = useState(mailingLists[0])
  const [selected3, setSelected3] = useState(settings[0])
  const [open3, setOpen3] = useState(false)
  const [open6, setOpen6] = useState(false)
  const [selected4, setSelected4] = useState(plans3[0])

  const [selected, setSelected] = useState(plans[0])
  const [selectedDate, handleDateChange] = useState(new Date());
  const [rental, setRental] = useState(false)
  const [outstation, setOutstation] = useState(false)

  const statusOpt = [
    { id: 1, title: "Active", value: "active" },
    { id: 2, title: "Inactive", value: "inactive" },
    // { id: 3, title: "Archive", value: "archive" },
  ];
  const countryOpt = [
    { id: 1, title: "Abkhazia (+7 840)", value: "Abkhazia (+7 840)" },
    { id: 2, title: "Afghanistan (+93)", value: "Afghanistan (+93)" },
    { id: 3, title: "Albania (+355)", value: "Albania (+355)" },
    { id: 3, title: "Algeria (+213)", value: "Algeria (+213)" },
  ];
  const [roleOpt, setRoleOpt] = useState([]);
  const fieldArr = [
    { id: 0, title: "Country Code", name: "country-code", type: "Dropdown", options: countryOpt },
    { id: 1, title: "Mobile", name: "mobile", type: "TextInput", is_number: true },
    { id: 2, title: "First Name", name: "name", type: "TextInput" },
    { id: 2, title: "Last Name", name: "last", type: "TextInput" },
    { id: 3, title: "Email", name: "email", type: "TextInput", is_full_screen: true },
  ];


  const [errors, setErrors] = useState({
    name: "",
    mobile: "",
    email: "",
    password: "",
    confirmpassword: "",
    role_id: "",
    status: "",
  });

  const [fields, setFields] = useState({});

  function validation() {
    let flag = true;
    let error = {}
    if (!fields.name) {
      error['name'] = "Please enter name"
      flag = false
    }

    if (!fields.mobile) {
      error['mobile'] = "Please enter mobile"
      flag = false
    }
    if (!fields.email) {
      error['email'] = "Please enter email"
      flag = false
    }
    if (!fields.password) {
      error['password'] = "Please select password"
      flag = false
    }
    if (!fields.confirmpassword) {
      error['confirmpassword'] = "Please select confirm password"
      flag = false
    }
    if (!fields.role_id) {
      error['role_id'] = "Please select role"
      flag = false
    }
    if (!fields.status) {
      error['status'] = "Please select status"
      flag = false
    }
    setErrors({ ...error })
    return flag;
  }

  const handleSubmit = () => {
    if (validation()) {
      // console.log("fields");
      // console.log(fields);
      configuration.postAPI({ url: 'user/create', params: fields }).then((data) => {
        // console.log(data)
        if (data.status === 200) {
          history.push('/user')
          // setData(data.payload)
        } else if (data.error) {
          return toast.error(data.error.message)
        } else {
          return toast.error('Something went wrong')
        }
      }).catch(error => {
        // console.log(error)
        return toast.error(error.message)
      });
    }
  }

  useEffect(() => {
    configuration.getAPI({ url: 'role/list', params: { status: 'active' } }).then((data) => {
      if (data.status === 200) {
        let roleArr = roleOpt
        each(data.payload, (single, index) => {
          roleArr.push({ id: index + 1, title: single.title, value: single.role_id })
        })
        setRoleOpt(roleArr)
      }
    }).catch(error => {
      return toast.error(error.message)
    });
  }, [roleOpt]);

  function handleChange(field, e) {
    setFields({ ...fields, [field]: e.target.value })
  }


  const AnyReactComponent = ({ icon }) => <div><img style={{ width: "40px" }} src={icon} /></div>;
  let defaultSettings = {
    center: {
      lat: 23.0260094693156,
      lng: 72.55570555792589
    },
    zoom: 12,

  };
  const exampleMapStyles = [
    {
      "featureType": "water",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#e9e9e9"
        },
        {
          "lightness": 17
        }
      ]
    },
    {
      "featureType": "landscape",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#f5f5f5"
        },
        {
          "lightness": 20
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "geometry.fill",
      "stylers": [
        {
          "color": "#ffffff"
        },
        {
          "lightness": 17
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "geometry.stroke",
      "stylers": [
        {
          "color": "#ffffff"
        },
        {
          "lightness": 29
        },
        {
          "weight": 0.2
        }
      ]
    },
    {
      "featureType": "road.arterial",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#ffffff"
        },
        {
          "lightness": 18
        }
      ]
    },
    {
      "featureType": "road.local",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#ffffff"
        },
        {
          "lightness": 16
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#f5f5f5"
        },
        {
          "lightness": 21
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#dedede"
        },
        {
          "lightness": 21
        }
      ]
    },
    {
      "elementType": "labels.text.stroke",
      "stylers": [
        {
          "visibility": "on"
        },
        {
          "color": "#ffffff"
        },
        {
          "lightness": 16
        }
      ]
    },
    {
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "saturation": 36
        },
        {
          "color": "#333333"
        },
        {
          "lightness": 40
        }
      ]
    },
    {
      "elementType": "labels.icon",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "transit",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#f2f2f2"
        },
        {
          "lightness": 19
        }
      ]
    },
    {
      "featureType": "administrative",
      "elementType": "geometry.fill",
      "stylers": [
        {
          "color": "#fefefe"
        },
        {
          "lightness": 20
        }
      ]
    },
    {
      "featureType": "administrative",
      "elementType": "geometry.stroke",
      "stylers": [
        {
          "color": "#fefefe"
        },
        {
          "lightness": 17
        },
        {
          "weight": 1.2
        }
      ]
    }
  ];

  function selectService(service) {
    console.log(service)
    setRental(false)
    setOutstation(false)
    if (service === "Rental") {
      setRental(true)
    }
    if (service === "Outstation") {
      setOutstation(true)
    }
  }

  return (
    <>
      <Breadcrumb module={labels.CreateRequest} />
      <div className="py-6 sm:px-6 ">

        <div className="grid grid-cols-1 lg:grid-cols-1">
          <div className="py-8 col-span-2 px-4">
            <Caption title="Create Request" />
          </div>
          <div className="min-h-screen bg-gray-100 grid grid-cols-1 xl:grid-cols-3">

            <div className="relative  col-span-1 xl:col-span-2 min-height">
              <GoogleMapReact
                bootstrapURLKeys={{ key: "AIzaSyAhM7jq3L7rzuDFiglKccBLkSYMfBqlqnA" }}
                defaultCenter={defaultSettings.center}
                defaultZoom={defaultSettings.zoom}
                options={{
                  styles: exampleMapStyles
                }}
              >
                <AnyReactComponent
                  lat={defaultSettings.center.lat}
                  lng={defaultSettings.center.lng}
                  icon="/image/pin.png"
                />
              </GoogleMapReact>
            </div>
            <div className="py-5 px-5 shadow-md">
              <div className="mx-auto w-full">
                <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                  {fieldArr.map((field) => {
                    return (
                      <div className={(field.is_full_screen) ? "sm:col-span-6" : "sm:col-span-3"} key={field.id}>
                        <Label title={field.title} />
                        <div className="mt-1">
                          {field.type === "TextInput" ? (
                            <TextInput
                              id={field.name}
                              handleChange={handleChange}
                              value={fields[field.name]}
                              name={field.name}
                              is_number={field.is_number || false}
                              is_password={field.is_password || false}
                            />
                          ) : field.type === "Dropdown" ? (
                            <Dropdown
                              id={field.name}
                              name={field.name}
                              handleChange={handleChange}
                              value={fields[field.name]}
                              status={field.options || []}
                            />
                          ) : field.type === "TextArea" ? (
                            <TextArea
                              id={field.name}
                              name={field.name}
                            />
                          ) : null}
                        </div>
                        {errors[field.name] ? <Label title={field.title} fieldError={errors[field.name]} /> : null}
                      </div>
                    );
                  })}
                </div>

                <div class="xi mt-4 py-4 px-4 shadow-lg bg-white rounded-md">
                  <div class="relative ml-10">
                    <input name="pickup" title="" placeholder="54, rue du Gue Jacquet" autocomplete="off" class="custom_input from_input bor" />
                  </div>
                  <div class="sn centerdrop">
                    <div class="relative ml-10">
                      <img className='switch_pin cursor-pointer' src='./image/switch.png' alt='' />
                      <input name="destination" title="" placeholder="66, avenue Ferdinand" autocomplete="off" class="custom_input from_input bor" />
                      <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 add_loc" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
                      </svg>
                    </div>
                  </div>
                  {/* <div class="sn">
                  <div class="relative ml-10">
                    <input name="destination" title="" placeholder="Rue des Nations Unies" autocomplete="off" class="custom_input from_input bor" />
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 add_loc" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                    </svg>
                    <img className='fav' src='./image/favorite.svg' alt=""/>
                  </div>
                </div> */}
                  <div class=" xy xz  yc "></div>
                </div>



                <RadioGroup className="mt-5" value={selectedMailingLists} onChange={setSelectedMailingLists}>
                  <RadioGroup.Label className="text-base font-medium text-gray-900">Select Service</RadioGroup.Label>

                  <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-4">
                    {mailingLists.map((mailingList) => (
                      <RadioGroup.Option
                        key={mailingList.id}

                        value={mailingList}
                        className={({ checked, active }) =>
                          classNames(
                            checked ? 'border-transparent' : 'border-gray-300',
                            active ? 'ring-2 ring-red-600' : '',
                            'relative bg-white border rounded-lg shadow-sm p-4 text-center cursor-pointer focus:outline-none'
                          )
                        }
                      >
                        {({ checked, active }) => (
                          <>
                            <div className="">
                              <div className="" onClick={() => selectService(mailingList.title)}>
                                <RadioGroup.Label as="span" className="block text-base font-medium text-gray-900">
                                  <img className="w-28 mb-3 mt-3 mx-auto" src={mailingList.imageUrl} alt="" />
                                </RadioGroup.Label>
                                <RadioGroup.Label as="span" className="block text-lg font-medium text-gray-900">
                                  {mailingList.title}
                                </RadioGroup.Label>
                                <RadioGroup.Description as="span" className="mt-1  text-base text-gray-500">
                                  {mailingList.description}
                                </RadioGroup.Description>
                              </div>
                            </div>
                            <CheckCircleIcon
                              className={classNames(!checked ? 'invisible' : '', 'h-5 w-5 text-red-600 absolute right-2 top-2')}
                              aria-hidden="true"
                            />
                            <div
                              className={classNames(
                                active ? 'border' : 'border-2',
                                checked ? 'border-red-600' : 'border-transparent',
                                'absolute -inset-px rounded-lg pointer-events-none'
                              )}
                              aria-hidden="true"
                            />
                          </>
                        )}
                      </RadioGroup.Option>
                    ))}
                  </div>
                </RadioGroup>


                {(rental) ?
                  <>
                    <div className='flex items-center  mt-5 justify-between'>
                      <div>
                        <h3 className='font-bold text-lg'>2 hrs 20 km package</h3>
                        <h3 className='font-normal text-gray-400 text-base'>Extra charges on exceeding package.</h3>
                      </div>
                      <div className=''>
                        <h3 onClick={() => setOpen6(true)} className='font-medium text-red-600 text-base cursor-pointer'>View Details</h3>
                      </div>
                    </div>
                    <div className='rental-hours mt-5'>
                      <RadioGroup value={selected4} onChange={setSelected4}>
                        <RadioGroup.Label className="sr-only">Server size</RadioGroup.Label>
                        <div className="flex overflow-x-auto py-2 px-2 max-w-max gap-4 cus_scroll">
                          {plans3.map((plan) => (
                            <RadioGroup.Option
                              key={plan.name}
                              value={plan}
                              className={({ checked, active }) =>
                                classNames(
                                  checked ? 'border-transparent' : 'border-gray-300',
                                  active ? 'ring-2 ring-gray-600' : '',
                                  'relative bg-white border rounded-lg shadow-sm px-3 cursor-pointer sm:flex sm:justify-between focus:outline-none cus_w'
                                )
                              }
                            >
                              {({ active, checked }) => (
                                <>
                                  <div className="flex items-center">
                                    <div className="text-sm">
                                      <RadioGroup.Label as="p" className="font-medium text-gray-400 ">
                                        <h3 className='font-bold text-base text-gray-600 mt-2'>{plan.name}</h3>
                                        <p className='font-normal text-sm'>{plan.km}</p>
                                      </RadioGroup.Label>
                                    </div>
                                  </div>
                                  <div
                                    className={classNames(
                                      active ? 'border' : 'border-2',
                                      checked ? 'border-gray-600' : 'border-transparent',
                                      'absolute -inset-px rounded-lg pointer-events-none'
                                    )}
                                    aria-hidden="true"
                                  />
                                </>
                              )}
                            </RadioGroup.Option>
                          ))}
                        </div>
                      </RadioGroup>
                    </div>
                  </>
                  : ""}

                {(outstation) ?
                  <>
                    <div className='outstation mt-8'>
                      <RadioGroup className="mt-5" value={selected4} onChange={setSelected4}>
                        <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
                          {plans4.map((plan) => (
                            <RadioGroup.Option
                              key={plan.id}
                              value={plan}
                              className={({ checked, active }) =>
                                classNames(
                                  checked ? 'border-transparent' : 'border-gray-300',
                                  active ? 'ring-2 ring-red-600' : '',
                                  'relative bg-white border rounded-lg shadow-sm p-4  cursor-pointer focus:outline-none'
                                )
                              }
                            >
                              {({ checked, active }) => (
                                <>
                                  <div className="">
                                    <div className="">
                                      <RadioGroup.Label as="span" className="block text-base font-bold text-gray-900">
                                        {plan.title}
                                      </RadioGroup.Label>
                                      <RadioGroup.Description as="span" className="mt-1  text-base text-gray-500">
                                        {plan.description}
                                      </RadioGroup.Description>
                                    </div>
                                  </div>
                                  <CheckCircleIcon
                                    className={classNames(!checked ? 'invisible' : '', 'h-5 w-5 text-red-600 absolute right-2 top-2')}
                                    aria-hidden="true"
                                  />
                                  <div
                                    className={classNames(
                                      active ? 'border' : 'border-2',
                                      checked ? 'border-red-600' : 'border-transparent',
                                      'absolute -inset-px rounded-lg pointer-events-none'
                                    )}
                                    aria-hidden="true"
                                  />
                                </>
                              )}
                            </RadioGroup.Option>
                          ))}
                        </div>
                      </RadioGroup>
                    </div>
                  </>
                  : ""}


                <div className='flex items-center  mt-5 justify-between'>
                  <h3 className='font-bold text-lg'>Choose Vehicle</h3>
                  <div className='flex gap-4'>
                    <img onClick={() => setOpen(true)} className='cursor-pointer' src='./image/calendar.svg' alt='' />
                    <img onClick={() => setOpen4(true)} className='cursor-pointer' src='./image/note.svg' alt='' />
                  </div>
                </div>
                <div className='mobilityoption mt-5'>
                  <RadioGroup value={selected} onChange={setSelected}>
                    <RadioGroup.Label className="sr-only">Server size</RadioGroup.Label>
                    <div className="grid grid-cols-2 md:grid-cols-3 gap-4 ">
                      {plans.map((plan) => (
                        <RadioGroup.Option
                          key={plan.name}
                          value={plan}
                          className={({ checked, active }) =>
                            classNames(
                              checked ? 'border-transparent' : 'border-gray-300',
                              active ? 'ring-2 ring-gray-600' : '',
                              'relative bg-white border rounded-lg shadow-sm px-3 cursor-pointer sm:flex sm:justify-between focus:outline-none'
                            )
                          }
                        >
                          {({ active, checked }) => (
                            <>
                              <div className="flex items-center">
                                <div className="text-sm">
                                  <RadioGroup.Label as="p" className="font-medium text-gray-400 flex items-center">
                                    <img className="w-6 h-6 mb-2 mt-2 mx-auto mr-5" src={plan.imageUrl} alt="" />
                                    <p className='font-normal text-sm'>{plan.name}</p>
                                  </RadioGroup.Label>
                                </div>
                              </div>
                              <div
                                className={classNames(
                                  active ? 'border' : 'border-2',
                                  checked ? 'border-gray-600' : 'border-transparent',
                                  'absolute -inset-px rounded-lg pointer-events-none'
                                )}
                                aria-hidden="true"
                              />
                            </>
                          )}
                        </RadioGroup.Option>
                      ))}
                    </div>
                  </RadioGroup>
                </div>

                <div className='selectvehicle mt-5 mb-10'>
                  <RadioGroup value={selected3} onChange={setSelected3}>
                    <RadioGroup.Label className="sr-only">Privacy setting</RadioGroup.Label>
                    <div className="">
                      {/* {settings.map((setting, settingIdx) => ( */}
                      <RadioGroup.Option
                        key={settings[0].name}
                        value={settings[0]}
                        className={({ checked }) =>
                          classNames(
                            // settingIdx === 0 ? 'rounded-md rounded-md' : '',
                            // settingIdx === settings.length - 1 ? 'rounded-md rounded-md' : '',
                            checked ? 'bg-red-50 border-red-600 border-2 z-10' : 'bg-white',
                            'relative rounded-md mb-3  cursor-pointer focus:outline-none border-2 border-white'
                          )
                        }
                      >
                        {({ active, checked }) => (
                          <>
                            <div className="flex items-center justify-between p-4">
                              <div className={classNames(checked ? 'text-red-900' : 'text-gray-900', 'block text-basefont-medium')}>
                                <div className='flex'>
                                  <h3 className='mr-3 font-bold text-lg text-gray-600'>Bharuth GO</h3> <img onClick={() => setOpen3(true)} src='./image/information.svg' alt="" />
                                </div>
                                <p className='text-base text-gray-600 font-normal'>4 Seats</p>
                                <div className='flex justify-between mt-3'>
                                  <p className='font-bold text-lg text-red-600 mr-10'>₹25.50</p>

                                  <h3 className='mr-3 font-bold text-base text-gray-600 flex items-center'><img className='mr-2 w-4' src='./image/clock.svg' alt="" /> 1-5 min</h3>
                                </div>
                              </div>
                              <div>
                                <p className={classNames(checked ? 'text-red-700' : 'text-gray-500', 'block text-sm')}>
                                  <img className='w-24' src='./image/go.png' alt="" />
                                </p>
                              </div>
                            </div>

                          </>
                        )}
                      </RadioGroup.Option>
                      {/* ))} */}
                    </div>

                    <div className="">
                      {/* {settings.map((setting, settingIdx) => ( */}
                      <RadioGroup.Option
                        key={settings[1].name}
                        value={settings[1]}
                        className={({ checked }) =>
                          classNames(
                            // settingIdx === 0 ? 'rounded-md rounded-md' : '',
                            // settingIdx === settings.length - 1 ? 'rounded-md rounded-md' : '',
                            checked ? 'bg-red-50 border-red-600 border-2 z-10' : 'bg-white',
                            'relative rounded-md mb-3  cursor-pointer focus:outline-none border-2 border-white'
                          )
                        }
                      >
                        {({ active, checked }) => (
                          <>
                            <div className="flex items-center justify-between p-4">
                              <div className={classNames(checked ? 'text-red-900' : 'text-gray-900', 'block text-basefont-medium')}>
                                <div className='flex'>
                                  <h3 className='mr-3 font-bold text-lg text-gray-600'>Sedan Rentals</h3> <img onClick={() => setOpen3(true)} src='./image/information.svg' alt="" />
                                </div>
                                <p className='text-base text-gray-600 font-normal'>4 Seats</p>
                                <div className='flex justify-between mt-3'>
                                  <p className='font-bold text-lg text-red-600 mr-10'>₹35.00</p>

                                  <h3 className='mr-3 font-bold text-base text-gray-600 flex items-center'><img className='mr-2 w-4' src='./image/clock.svg' alt="" /> 1-5 min</h3>
                                </div>
                              </div>
                              <div>
                                <p className={classNames(checked ? 'text-red-700' : 'text-gray-500', 'block text-sm')}>
                                  <img className='w-24' src='./image/sedan2.png' alt="" />
                                </p>
                              </div>

                            </div>
                          </>
                        )}
                      </RadioGroup.Option>
                      {/* ))} */}
                    </div>

                    <div className="">
                      {/* {settings.map((setting, settingIdx) => ( */}
                      <RadioGroup.Option
                        key={settings[2].name}
                        value={settings[2]}
                        className={({ checked }) =>
                          classNames(
                            // settingIdx === 0 ? 'rounded-md rounded-md' : '',
                            // settingIdx === settings.length - 1 ? 'rounded-md rounded-md' : '',
                            checked ? 'bg-red-50 border-red-600 border-2 z-10' : 'bg-white',
                            'relative rounded-md mb-3  cursor-pointer focus:outline-none border-2 border-white'
                          )
                        }
                      >
                        {({ active, checked }) => (
                          <>
                            <div className="flex items-center justify-between p-4">
                              <div className={classNames(checked ? 'text-red-900' : 'text-gray-900', 'block text-basefont-medium')}>
                                <div className='flex'>
                                  <h3 className='mr-3 font-bold text-lg text-gray-600'>Bharuth Green</h3> <img onClick={() => setOpen3(true)} src='./image/information.svg' alt="" />
                                </div>
                                <p className='text-base text-gray-600 font-normal'>4 Seats</p>
                                <div className='flex justify-between mt-3'>
                                  <p className='font-bold text-lg text-red-600 mr-10'>₹20.00</p>

                                  <h3 className='mr-3 font-bold text-base text-gray-600 flex items-center'><img className='mr-2 w-4' src='./image/clock.svg' alt="" /> 1-5 min</h3>
                                </div>
                              </div>
                              <div>
                                <p className={classNames(checked ? 'text-red-700' : 'text-gray-500', 'block text-sm')}>
                                  <img className='w-24' src='./image/green.png' alt="" />
                                </p>
                              </div>

                            </div>
                          </>
                        )}
                      </RadioGroup.Option>
                      {/* ))} */}
                    </div>

                    <div className="">
                      {/* {settings.map((setting, settingIdx) => ( */}
                      <RadioGroup.Option
                        key={settings[3].name}
                        value={settings[3]}
                        className={({ checked }) =>
                          classNames(
                            // settingIdx === 0 ? 'rounded-md rounded-md' : '',
                            // settingIdx === settings.length - 1 ? 'rounded-md rounded-md' : '',
                            checked ? 'bg-red-50 border-red-600 border-2 z-10' : 'bg-white',
                            'relative rounded-md mb-3  cursor-pointer focus:outline-none border-2 border-white'
                          )
                        }
                      >
                        {({ active, checked }) => (
                          <>
                            <div className="flex items-center justify-between p-4">
                              <div className={classNames(checked ? 'text-red-900' : 'text-gray-900', 'block text-basefont-medium')}>
                                <div className='flex'>
                                  <h3 className='mr-3 font-bold text-lg text-gray-600'>Bharuth Pool</h3> <img onClick={() => setOpen3(true)} src='./image/information.svg' alt="" />
                                </div>
                                <p className='text-base text-gray-600 font-normal'>4 Seats</p>
                                <div className='flex justify-between mt-3'>
                                  <p className='font-bold text-lg text-red-600 mr-10'>₹20.00</p>

                                  <h3 className='mr-3 font-bold text-base text-gray-600 flex items-center'><img className='mr-2 w-4' src='./image/clock.svg' alt="" /> 1-5 min</h3>
                                </div>
                              </div>
                              <div>
                                <p className={classNames(checked ? 'text-red-700' : 'text-gray-500', 'block text-sm')}>
                                  <img className='w-24' src='./image/pool.png' alt="" />
                                </p>
                              </div>

                            </div>
                          </>
                        )}
                      </RadioGroup.Option>
                      {/* ))} */}
                    </div>
                  </RadioGroup>

                </div>

                <Link to="/">
                  <button
                    type="button"
                    className="w-full mt-5 px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                  >
                    Book Now
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={() => setOpen(true)}>
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="">

                  <div className="mt-3 text-center sm:mt-0 sm:text-left">
                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                      When do you want to be picked up?
                    </Dialog.Title>
                    <div className="mt-5 cus_date">
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                          disableToolbar
                          variant="inline"
                          label="Select Date"
                          value={selectedDate}
                          onChange={handleDateChange}
                        />
                      </MuiPickersUtilsProvider>
                    </div>
                    <div className="mt-5 cus_date">
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <TimePicker
                          variant="inline"
                          label="Select Time"
                          value={selectedDate}
                          onChange={handleDateChange}
                        />
                      </MuiPickersUtilsProvider>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto"
                    onClick={() => setOpen(false)}
                  >
                    Confirm
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:mt-0 sm:w-auto"
                    onClick={() => setOpen(false)}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
      <Transition.Root show={open4} as={Fragment}>
        <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={() => setOpen4(true)}>
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full sm:p-6">
                <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                    onClick={() => setOpen4(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="">

                  <div className="mt-3 text-center sm:mt-0 sm:text-left">
                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                      Note to driver
                    </Dialog.Title>
                    <p className='text-gray-600 font-normal'>Let driver know more about your requests.</p>
                    <div className="mt-5">
                      <textarea
                        rows={4}
                        name="comment"
                        id="comment"
                        className="shadow-sm focus:ring-red-600 focus:border-red-600 block w-full sm:text-baseborder-gray-300 rounded-md"
                        defaultValue={'Eg: Im in front of the bus stop'}
                      />

                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto"
                    onClick={() => setOpen4(false)}
                  >
                    Confirm
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:mt-0 sm:w-auto"
                    onClick={() => setOpen4(false)}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
      <Transition.Root show={open3} as={Fragment}>
        <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={() => setOpen3(true)}>
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full sm:p-6">
                <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                    onClick={() => setOpen3(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="">

                  <div className="mt-3 text-center sm:mt-0 sm:text-left">
                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                      Bharuth GO
                    </Dialog.Title>
                    <p className='text-gray-400 font-normal'>A regular comfortable hatchback that becomes your everyday ride</p>
                    <div className="mt-5">
                      <div className="relative grid grid-cols-1 md:grid-cols-2 gap-4">
                        <dt>
                          <CheckCircleIcon className="absolute h-6 w-6 text-gray-600" aria-hidden="true" />
                          <p className="ml-9 text-lg leading-6 font-normal text-gray-900">Comfy Hatch</p>
                        </dt>
                        <dt>
                          <CheckCircleIcon className="absolute h-6 w-6 text-gray-600" aria-hidden="true" />
                          <p className="ml-9 text-lg leading-6 font-normal text-gray-900">Luggage Space</p>
                        </dt>
                        <dt>
                          <CheckCircleIcon className="absolute h-6 w-6 text-gray-600" aria-hidden="true" />
                          <p className="ml-9 text-lg leading-6 font-normal text-gray-900">Pocket Friendly</p>
                        </dt>
                        <dt>
                          <CheckCircleIcon className="absolute h-6 w-6 text-gray-600" aria-hidden="true" />
                          <p className="ml-9 text-lg leading-6 font-normal text-gray-900">Cashless Rides</p>
                        </dt>
                      </div>
                      <p className='text-gray-900 text-lg font-bold mt-5'>Estimated fare : ₹25.36</p>

                      <p className='text-gray-900 text-lg font-normal mt-2'>Applied rate card</p>
                      <dl className="text-base mt-5">
                        <div className="pb-2 flex items-center justify-between">
                          <dt className="text-gray-400 font-normal">Base fare 1 hr 10 km</dt>
                          <dd className="font-normal text-gray-400">$72</dd>
                        </div>
                        <div className="py-2 flex items-center justify-between">
                          <dt className="text-gray-400 font-normal">Extra km fare after 10 km</dt>
                          <dd className="font-normal text-gray-400">₹11 per km  </dd>
                        </div>
                        <div className="py-2 flex items-center justify-between">
                          <dt className="text-gray-400 font-normal">Extra ride time fare after 1 hr</dt>
                          <dd className="font-normal text-gray-400">₹2 per min </dd>
                        </div>
                      </dl>
                      <div className='mt-2'>
                        <p className="font-normal text-gray-400">Extra changes on exceeding package. GST and toll, if applicable, will be added to the bill. Please pay parking as and when required. Base fare amount is the minimum bill amount a customer has to pay for the package.</p>
                      </div>
                      <hr className='mt-4' />

                      <h3 className='text-lg font-bold mt-4'>Our fleet</h3>

                      <img className='w-56' src='./image/mini-car.png' alt='' />
                      <p className="font-normal text-gray-400">Tata Indica, Nissan Micra, Maruti Ritz</p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto"
                    onClick={() => setOpen3(false)}
                  >
                    Done
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:mt-0 sm:w-auto"
                    onClick={() => setOpen3(false)}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
      <Transition.Root show={open6} as={Fragment}>
        <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={() => setOpen6(true)}>
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full sm:p-6">
                <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                    onClick={() => setOpen6(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="">

                  <div className="mt-3 text-center sm:mt-0 sm:text-left">
                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                      Rental package rules & restrictions
                    </Dialog.Title>
                    <p className='text-gray-400 font-normal'>For usage beyond 2 hrs 20 km package, additional fare will be applicable as per your selected category.</p>
                    <div className="mt-5">
                      <div className="relative grid grid-cols-1 md:grid-cols-2 gap-4">
                        <dt className='border border-gray-200 py-3 px-3'>
                          <h3 className='text-lg'>Prime Sedan</h3>
                          <p className="text-lg leading-6 font-normal text-gray-400">₹12 / km </p>
                          <p className="text-lg leading-6 font-normal text-gray-400">₹2.5 / min</p>
                        </dt>
                        <dt className='border border-gray-200 py-3 px-3'>
                          <h3 className='text-lg'>Mini</h3>
                          <p className="text-lg leading-6 font-normal text-gray-400">₹12 / km </p>
                          <p className="text-lg leading-6 font-normal text-gray-400">₹2 / min</p>
                        </dt>
                      </div>
                      <p className='text-gray-400 text-base font-normal mt-5'>Rental can be used for local travels only. Package cannot be changed after booking is confirmed.</p>
                      <p className='text-gray-400 text-base font-normal mt-5'>For usage beyond selected package, additional fare will be applicable as per rates above.</p>
                      <p className='text-gray-400 text-base font-normal mt-5'>Additional GST applicable on fare. Toll will be added in the final bill if applicable, please pay parking fee when required.  </p>
                      <p className='text-gray-400 text-base font-normal mt-5'>For ride lated bookings, advance booking fee of ₹100 will be added to total fare.  </p>
                      <p className='text-gray-400 text-base font-normal mt-5'>Base fare amount is the minimum bill amount a customer has to pay for the package </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-600 sm:ml-3 sm:w-auto"
                    onClick={() => setOpen6(false)}
                  >
                    Done
                  </button>

                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:mt-0 sm:w-auto"
                    onClick={() => setOpen6(false)}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}

export default Add;
