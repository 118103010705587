import AddEditSection from "../../../components/AddEditSection";
import Breadcrumb from "../../../parts/Breadcrumb";
import React, { useEffect, useState } from "react";
import labels from "../../../constants/Vehicle";
import configuration from '../../../config';
import { toast } from 'react-toastify';
import { pluck } from 'underscore';

// For Edit State
let Id = "";
function Edit({ history }) {
  let [makeList, setMakeList] = useState([]);
  let [modelList, setModelList] = useState([]);
  let [yearList, setYearList] = useState([]);
  const statusOpt = [
    { id: 1, title: "Active", value: "active" },
    { id: 2, title: "Inactive", value: "inactive" },
  ];
  const [rideOption, setRideOption] = useState([])
  // const [mobilityOption, setMobilityOption] = useState([])
  const fieldArr = [
    {
      id: 0,
      title: "Vehicle Maker",
      name: "vehicle_make_id",
      type: "Dropdown",
      options: makeList,
    },
    {
      id: 1,
      title: "Vehicle Model",
      name: "vehicle_model_id",
      type: "Dropdown",
      options: modelList,
    },
    {
      id: 2,
      title: "Vehicle Year",
      name: "vehicle_year_id",
      type: "Dropdown",
      options: yearList,
    },
    {
      id: 3,
      title: "Status",
      name: "status",
      type: "Dropdown",
      options: statusOpt,
    },
    {
      id: 4,
      title: "Supported Ride",
      name: "vehicle_ride_option_id",
      type: "Dropdown",
      options: rideOption,
    },
    /* {
      id: 5,
      title: "Supported Ride Mobility",
      name: "vehicle_mobility_id",
      type: "MultiSelect",
      options: mobilityOption,
    } */
  ];
  const [errors, setErrors] = useState({
    title: "",
    description: "",
    status: "",
  });
  const [fields, setFields] = useState({
    vehicle_ride_option_id: [],
    vehicle_mobility_id: []
  });
  function handleValidation() {
    let flag = true;
    let error = {}
    if (!fields.vehicle_make_id) {
      error['vehicle_make_id'] = "Please select vehicle maker"
      flag = false
    }
    if (!fields.vehicle_model_id) {
      error['vehicle_model_id'] = "Please select vehicle model"
      flag = false
    }
    if (!fields.vehicle_year_id) {
      error['vehicle_year_id'] = "Please select vehicle year"
      flag = false
    }
    if (!fields.vehicle_ride_option_id) {
      error['vehicle_ride_option_id'] = "Please select supported ride"
      flag = false
    }

    if (!fields.status) {
      error['status'] = "Please select status"
      flag = false
    }
    setErrors({ ...error })
    return flag;
  }
  const handleSubmit = () => {
    if (handleValidation()) {
      fields.vehicleMobility = pluck(fields.vehicle_mobility_id, 'id')
      fields.vehicleRideOption = [fields.vehicle_ride_option_id]
      // console.log(fields)
      configuration.postAPI({ url: 'vehicle/update', params: fields }).then((data) => {
        if (data.status === 200) {
          history.push('/vehicle')
        } else if (data.status === 404) {
          return toast.error(data.error.message)
        } else {
          return toast.error('Something went wrong')
        }
      }).catch(error => {
        return toast.error(error.message)
      });
    }
  }

  function getMake() {
    configuration.getAPI({ url: 'make/list', params: { status: "active" } }).then((data) => {
      if (data.status === 200) {
        if (data.payload.length > 0) {
          data.payload.forEach((element) => {
            element.title = element.title;
            element.value = element.vehicle_make_id;
            element.id = element.vehicle_make_id;
            return element;
          });
        }
        setMakeList(data.payload)
      }
    }).catch(error => {
      return toast.error(error.message)
    });
    configuration.getAPI({ url: 'ride-option/list', params: { status: "active" } }).then((data) => {
      if (data.status === 200) {
        if (data.payload.length > 0) {
          /* data.payload.forEach((element) => {
            element.label = element.title;
            element.id = element.vehicle_ride_option_id;
            return element;
          }); */
          data.payload.forEach((element) => {
            element.title = element.title;
            element.value = element.vehicle_ride_option_id;
            element.id = element.vehicle_ride_option_id;
            return element;
          });
        }

        setRideOption(data.payload)
      }
    }).catch(error => {
      return toast.error(error.message)
    });
    /* configuration.getAPI({ url: 'mobility-option/list', params: { status: "active" } }).then((data) => {
      if (data.status === 200) {
        if (data.payload.length > 0) {
          data.payload.forEach((element) => {
            element.label = element.title;
            element.id = element.vehicle_mobility_id;
            return element;
          });
        }
        setMobilityOption(data.payload)
      }
    }).catch(error => {
      return toast.error(error.message)
    }); */
  }
  function getModel(Id) {
    setYearList([])
    configuration.getAPI({ url: 'model/list', params: { status: "active", vehicle_make_id: Id } }).then((data) => {
      if (data.status === 200) {
        if (data.payload.length > 0) {
          data.payload.forEach((element) => {
            element.title = element.title;
            element.value = element.vehicle_model_id;
            element.id = element.vehicle_model_id;
            return element;
          });
        }
        setModelList(data.payload)
      }
    })
  }
  function getYear(Id) {
    setFields({ ...fields, vehicle_year_id: '' })
    configuration.getAPI({ url: 'year/list', params: { status: "active", vehicle_model_id: Id } }).then((data) => {
      if (data.status === 200) {
        if (data.payload.length > 0) {
          data.payload.forEach((element) => {
            element.title = element.title;
            element.value = element.vehicle_year_id;
            element.id = element.vehicle_year_id;
            return element;
          });
        }
        setYearList(data.payload)
      }
    })
  }
  useEffect(() => {
    // console.log(props.location)
    var url = window.location.href;
    Id = url.substring(url.lastIndexOf("/") + 1);
    configuration.getAPI({ url: 'vehicle/list', params: { vehicle_id: Id } }).then((data) => {
      // console.log(data)
      if (data.status === 200) {
        getModel(data.payload.vehicle_make_id);
        getYear(data.payload.vehicle_model_id)
        /* setFields(data.payload) */
        setFields({ ...data.payload, vehicle_ride_option_id: data.payload.vehicle_ride_option_id?.[0].id })
      }
    }).catch(error => {
      // console.log(error)
      return toast.error(error.message)
    });
    getMake();
    // console.log(configuration.baseURL);
  }, []);
  function handleChange(field, e) {
    if (field == 'vehicle_make_id') {
      getModel(e.target.value);
      setFields({ ...fields, vehicle_model_id: '', vehicle_year_id: '', [field]: e.target.value })
    } else if (field == 'vehicle_model_id') {
      getYear(e.target.value);
      setFields({ ...fields, vehicle_year_id: '', [field]: e.target.value })
    } else if (field == 'vehicle_mobility_id') {
      setFields({ ...fields, [field]: e })
    } else {
      setFields({ ...fields, [field]: e.target.value })
    }
  }
  return (
    <>
      <Breadcrumb module={labels.editVehicle} />
      <AddEditSection title="Edit Vehicle" path="/vehicle" fields={fieldArr} fieldValue={fields} fieldError={errors} handleChange={handleChange} handleSubmit={handleSubmit} />
    </>
  );
}

export default Edit;
