import React, { useEffect, useState } from "react";
import Alert from "../../components/Alert";
import Breadcrumb from "../../parts/Breadcrumb";
import { Link } from "react-router-dom";
import ListSection from "../../components/ListSection";
import labels from "../../constants/Subscription";
import DeleteModel from "../../components/DeleteModel";
import configuration from "../../config";
import { toast } from "react-toastify";
import { pluck } from "underscore";

// List all help categories
function View(location) {
  const columns = [
    {
      title: "ID",
      field: "id",
      render: (rowData) => (
        <Link className="text-indigo-600 hover:text-indigo-900" to={`/subscription/edit/${rowData._id}`}>
          {rowData._id}
        </Link>
      ),
    },
    { title: "Title", field: "title" },
    { title: "Price", field: "price" },
    { title: "Type", field: "type" },
    {
      title: "Status",
      field: "status",
      render: (rowData) => (
        <span
          className={
            rowData.status === "active"
              ? "inline-flex items-center px-3 py-0.5 rounded-full text-basefont-medium bg-green-100 text-green-800 capitalize"
              : "inline-flex items-center px-3 py-0.5 rounded-full text-basefont-medium bg-red-100 text-red-800 capitalize"
          }
        >
          {" "}
          {rowData.status}
        </span>
      ),
    },
  ];
  const btnGroups = [
    { id: 1, title: "Add New", type: "addNew", path: "/subscription/add" },
    { id: 2, title: "Make Active", type: "makeActive", path: "/subscription" },
    {
      id: 3,
      title: "Make Inactive",
      type: "makeInactive",
      path: "/subscription",
    },
    { id: 4, title: "Archive", type: "archive", path: "/subscription" },
    { id: 5, title: "Delete", type: "delete", path: "/subscription" },
  ];
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState([]);
  const [ids, setIds] = useState([]);

  useEffect(() => {
    if (location.state && location.state.is_added) {
      setShow(true);
      setTimeout(() => setShow(false), 3000);
    }
    getDatas();
  }, [location.state]);

  function getDatas() {
    configuration
      .getAPI({ url: "subscription/list", params: {} })
      .then((data) => {
        if (data.status === 200) {
          setData(data.payload);
        }
      })
      .catch((error) => {
        return toast.error(error.message);
      });
  }

  function handleAction(type) {
    if (ids.length < 1) {
      return toast.error("Please select at least one record");
    } else if (type === "delete") {
      setShowModal(true);
      return;
    }
    const sendData = {
      ids,
      type: type === "makeActive" ? "active" : type === "makeInactive" ? "inactive" : type,
    };
    configuration
      .postAPI({ url: "subscription/action", params: sendData })
      .then((data) => {
        if (data.status === 200) {
          getDatas();
          setIds([]);
          return toast.success(type === "delete" ? "Record deleted successfully" : "Record update successfully");
        } else if (data.error) {
          return toast.error(data.error.message);
        } else {
          return toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        return toast.error(error.message);
      });
  }
  function handleDelete() {
    const sendData = {
      ids,
      type: "delete",
    };
    configuration
      .postAPI({ url: "subscription/action", params: sendData })
      .then((data) => {
        if (data.status === 200) {
          getDatas();
          setIds([]);
          setShowModal(false);
          return toast.success("Record deleted successfully");
        } else if (data.error) {
          return toast.error(data.error.message);
        } else {
          return toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        return toast.error(error.message);
      });
  }
  function handleCancel() {
    setShowModal(false);
  }

  const onSelectionChange = (e) => {
    let idArr = pluck(e, "_id");
    setIds(idArr);
  };

  return (
    <>
      <Breadcrumb module={labels.subscription} />
      <Alert show={show} setShow={setShow} bgColorClass="green" caption="Success" message="Record added successfully" />
      <DeleteModel mode={showModal} handleDelete={handleDelete} handleCancel={handleCancel} />
      <ListSection
        captionTitle="View Subscription"
        columns={columns}
        data={data}
        btnGroups={btnGroups}
        handleAction={handleAction}
        onSelectionChange={onSelectionChange}
      />
    </>
  );
}

export default View;
