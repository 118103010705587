import AddEditSection from "../../../components/AddEditSection";
import Breadcrumb from "../../../parts/Breadcrumb";
import React, { useEffect, useState } from "react";
import labels from "../../../constants/Services";
import configuration from '../../../config';
import { toast } from 'react-toastify';
import { each } from 'underscore';

// For Edit State
let Id = "";
function Edit({ history }) {
  const statusOpt = [
    { id: 1, title: "Active", value: "active" },
    { id: 2, title: "Inactive", value: "inactive" },
  ];
  const typeOpt = [
    { id: 1, title: "Percentage", value: "percentage" },
    { id: 2, title: "Amount", value: "amount" },
  ];
  const [languages, setLanguages] = useState([])
  const [fieldArr, setFieldArr] = useState([
    { id: 0, title: "Image or Icon ", name: "icon", type: "FileInput" },
    { id: 1, title: "Code ", name: "code", type: "TextInput", is_read_only: true },
    { id: 2, title: "Discount Type ", name: "discount_type", type: "Dropdown", options: typeOpt },
    { id: 3, title: "Discount Value ", name: "discount_value", type: "TextInput", is_number: true },
    { id: 4, title: "Discount Apply", name: "is_discount", type: "SwitchInput" },
    {
      id: 5,
      title: "Status",
      name: "status",
      type: "Dropdown",
      options: statusOpt,
    },
  ]);
  const [icon, setIcon] = useState(null);
  const [errors, setErrors] = useState({
    title: "",
    description: "",
    status: "",
  });
  const [fields, setFields] = useState({});
  function handleValidation() {
    let flag = true;
    let error = {}
    each(languages, (lang, ind) => {
      if (!fields['title_' + lang.code]) {
        error['title_' + lang.code] = "Please enter title in " + lang.title
        flag = false
      }
    })
    if (!fields.discount_type) {
      error['discount_type'] = "Please select discount type"
      flag = false
    }
    if (!fields.discount_value) {
      error['discount_value'] = "Please enter discount value"
      flag = false
    }
    if (!fields.status) {
      error['status'] = "Please select status"
      flag = false
    }
    setErrors({ ...error })
    return flag;
  }
  const handleSubmit = () => {
    if (handleValidation()) {
      const formData = new FormData();
      if (icon) {
        formData.append('icon', icon);
      }
      formData.append('fields', JSON.stringify({ ...fields, lang_code: 'EN' }));
      configuration.postFormDataAPI({ url: 'service/update', params: formData }).then((data) => {
        if (data.status === 200) {
          history.push('/services')
        } else if (data.status === 404) {
          return toast.error(data.error.message)
        } else {
          return toast.error('Something went wrong')
        }
      }).catch(error => {
        return toast.error(error.message)
      });
    }
  }
  function handleChange(field, e) {
    if (field === 'icon') {
      setIcon(e.target.files[0]);
      setFields({ ...fields, [field]: URL.createObjectURL(e.target.files[0]) })
    } else if (field == 'is_discount') {
      setFields({ ...fields, [field]: e })
    } else {
      setFields({ ...fields, [field]: e.target.value })
    }
  }
  function cancleImage(field, e) {
    setIcon(null);
    setFields({ ...fields, [field]: '' })
  }
  useEffect(() => {
    configuration.getAPI({ url: 'common/list-order-no', params: { lang_code: "EN", collectionName: 'services' } }).then((data) => {
      if (data.status === 200) {
        let orderNoArr = []
        each(data.payload, (singleRec) => {
          orderNoArr.push({ 'title': singleRec.title, 'value': singleRec.order_no, 'id': singleRec.order_no })
        })
        const orderNoObj = {
          id: 1,
          title: "Order No",
          name: "order_no",
          type: "Dropdown",
          options: orderNoArr,
        }
        fieldArr.push(orderNoObj)
        configuration.getAPI({ url: 'language/list', params: { status: 'active' } }).then((data1) => {
          if (data1.status === 200) {
            setLanguages(data1.payload)
            let languages = data1.payload;
            let fieldArr_data = [];
            languages.map((language, index) => {
              fieldArr_data.push({
                id: 3 + index,
                title: `Title [${language.title}]`,
                name: `title_${language.code}`,
                type: "TextInput"
              });
            })
            setFieldArr([...fieldArr_data, ...fieldArr])
          }
        }).catch(error => {
          return toast.error(error.message)
        });
      }
    }).catch(error => {
      return toast.error(error.message)
    });

    // console.log(props.location)
    var url = window.location.href;
    Id = url.substring(url.lastIndexOf("/") + 1);
    configuration.getAPI({ url: 'service/list', params: { service_id: Id } }).then((data) => {
      // console.log(data)
      if (data.status === 200) {
        setFields(data.payload)
      }
    }).catch(error => {
      // console.log(error)
      return toast.error(error.message)
    });

    // console.log(configuration.baseURL);
  }, []);
  return (
    <>
      <Breadcrumb module={labels.editServices} />
      <AddEditSection title="Edit Services" path="/services" fields={fieldArr} fieldValue={fields} fieldError={errors} handleChange={handleChange} cancleImage={cancleImage} handleSubmit={handleSubmit} />
    </>
  );
}

export default Edit;
