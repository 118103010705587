import React, { useState, useEffect } from "react";
import Alert from "../../../components/Alert";
import Breadcrumb from "../../../parts/Breadcrumb";
import { Link } from "react-router-dom";
import { pluck } from "underscore";
import BtnSaveRecord from "../../../components/Form/Button/BtnSaveRecord";
import ListSection from "../../../components/ListSection";
import Dropdown from "../../../components/Form/Dropdown";
import Label from "../../../components/Form/Label";
import labels from "../../../constants/Price";
import configuration from '../../../config';
import { toast } from 'react-toastify';
import { each, without } from 'underscore';
import PropTypes from "prop-types";

// List all users
function View({ location }) {
  const [countryOpt, setCurrencyOpt] = useState([]);
  const [normalPrice, setNormalPrice] = useState([]);
  const [advancedPrice, setAdvancedPrice] = useState({});
  const [stateOpt, setStateOpt] = useState([]);
  const [cityOpt, setCityOpt] = useState([]);
  const [currTime, setCurrTime] = useState(new Date());
  const [rides, setRides] = useState([]);
  const [slots, setSlots] = useState([]);
  const [applied_days, setAppliedDays] = useState([]);
  const [days, setDays] = useState(['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']);
  const [timeSlot, setTimeSlot] = useState({
    Sunday: [],
    Monday: [],
    Tuesday: [],
    Wednesday: [],
    Thursday: [],
    Friday: [],
    Saturday: []
  });
  const fieldArr = [
    {
      id: 0,
      title: "Country",
      name: "country_id",
      type: "Dropdown",
      options: countryOpt,
    },
    {
      id: 1,
      title: "State",
      name: "state_id",
      type: "Dropdown",
      options: stateOpt,
    },
    {
      id: 1,
      title: "City",
      name: "city_id",
      type: "Dropdown",
      options: cityOpt,
    }];
  const [fields, setFields] = useState({});
  const [showPrice, setShowPrice] = useState(false);
  const [errors, setErrors] = useState({});

  function getCountryDatas() {
    configuration
      .getAPI({ url: "country/list", params: {} })
      .then((data) => {
        if (data.status === 200) {
          if (data.payload.length > 0) {
            data.payload.forEach((element) => {
              element.title = element.name;
              element.value = element.country_id;
              element.id = element.country_id;

              return element;
            });
          }
          setCurrencyOpt(data.payload);
        }
      })
      .catch((error) => {
        return toast.error(error.message);
      });
  }

  function getStateDatas(params) {
    configuration
      .getAPI({ url: "state/list", params })
      .then((data) => {
        if (data.status === 200) {
          if (data.payload.length > 0) {
            data.payload.forEach((element) => {
              element.title = element.name;
              element.value = element.state_id;
              element.id = element.state_id;

              return element;
            });
          }
          setStateOpt(data.payload);
        }
      })
      .catch((error) => {
        return toast.error(error.message);
      });
  }

  function getCities(params) {
    configuration
      .getAPI({ url: "city/list", params })
      .then((data) => {
        if (data.status === 200) {
          if (data.payload.length > 0) {
            data.payload.forEach((element) => {
              element.title = element.name;
              element.value = element.city_id;
              element.id = element.city_id;

              return element;
            });
          }
          setCityOpt(data.payload);
        }
      })
      .catch((error) => {
        return toast.error(error.message);
      });
  }

  useEffect(() => {
    getCountryDatas();
    configuration.getAPI({ url: 'ride-option/list', params: { status: 'active' } }).then((data) => {
      if (data.status === 200) {
        setRides(data.payload)
        for (var i = 0; i < data.payload.length; i++) {
          normalPrice[data.payload[i].vehicle_ride_option_id] = {}
        }
        for (var i = 0; i < days.length; i++) {
          let obj = {};
          for (var k = 0; k < data.payload.length; k++) {
            obj[data.payload[k].vehicle_ride_option_id] = {};
          }
          advancedPrice[days[i]] = obj
        }
        setAdvancedPrice(advancedPrice)
        setNormalPrice(normalPrice)
      }
    }).catch(error => {
      return toast.error(error.message)
    });

    configuration.getAPI({ url: 'price/slots', params: { status: 'active' } }).then((data) => {
      if (data.status === 200) {
        setSlots(data.payload)
      }
    }).catch(error => {
      return toast.error(error.message)
    });
    // getStateDatas();
  }, []);

  function handleValidation() {
    let flag = true;
    let error = {}
    if (!fields.country_id) {
      error['country_id'] = "Please enter country"
      flag = false
    }

    if (!fields.state_id) {
      error['state_id'] = "Please enter state"
      flag = false
    }

    if (!fields.city_id) {
      error['city_id'] = "Please enter city"
      flag = false
    }
    setErrors({ ...error })
    return flag;
  }

  function handleChange(field, e) {
    if (field === "country_id" && e.target.value) {
      setShowPrice(false)
      setStateOpt([]);
      setCityOpt([]);
      getStateDatas({ country_id: e.target.value, status: 'active' });
      setFields({ ...fields, ["city_id"]: '', ["state_id"]: '', [field]: e.target.value });
    } else if (field === "state_id" && e.target.value) {
      setShowPrice(false)
      setCityOpt([]);
      getCities({ state_id: e.target.value, status: 'active' });
      setFields({ ...fields, ["city_id"]: '', [field]: e.target.value });
    } else if (field === 'city_id') {
      setShowPrice(true)
      setFields({ ...fields, [field]: e.target.value })
      getNormalPrice(fields.country_id, fields.state_id, e.target.value)
      getAdvancedPrice(fields.country_id, fields.state_id, e.target.value)
    }
  }
  function getNormalPrice(country_id, state_id, city_id) {
    configuration.getAPI({ url: 'price/price', params: { country_id: country_id, city_id: city_id, state_id: state_id } }).then((data) => {
      if (data.status === 200) {
        if (data.payload === 'empty') {
          let obj = {};
          each(rides, single => {
            obj[single.vehicle_ride_option_id] = {
              "base_fare": 0,
              "per_km_fare": 0,
              "per_min_fare": 0,
              "min_base_km": 0,
              "min_base_fare": 0,
              "min_base_fare_for_scheduled_rides": 0,
              "wait_fees": 0,
              "free_minutes_before_wait_fees_apply": 0,
            }
          })
          setNormalPrice(obj);
        } else {
          setNormalPrice(data.payload);
        }
      }
    }).catch(error => {
      return toast.error(error.message)
    });
    // setCurrTime(new Date())
  }

  function getAdvancedPrice(country_id, state_id, city_id) {
    configuration.getAPI({ url: 'price/slot-price', params: { country_id: country_id, city_id: city_id, state_id: state_id } }).then((data) => {
      if (data.status === 200) {
        if (data.payload == 'empty') {
          let mainObj = {};
          let timeSlotObj = {};
          each(days, elem => {
            let obj = {};
            each(rides, element => {
              obj[element.vehicle_ride_option_id] = {
                "base_fare": 0,
                "per_km_fare": 0,
                "per_min_fare": 0,
                "min_base_km": 0,
                "min_base_fare": 0,
                "min_base_fare_for_scheduled_rides": 0,
                "wait_fees": 0,
                "free_minutes_before_wait_fees_apply": 0,
              }
            })
            mainObj[elem] = obj
            timeSlotObj[elem] = []
          })
          setAdvancedPrice(mainObj);
          setTimeSlot(timeSlotObj);
          setAppliedDays([])
        } else {
          setAdvancedPrice(data.payload.advancedPrice);
          setTimeSlot(data.payload.timeSlot);
          setAppliedDays(data.payload.applied_days)
        }
      }
    }).catch(error => {
      return toast.error(error.message)
    });
    // setCurrTime(new Date())
  }
  function handleChangeNormal(e, field, title) {
    console.log(e.target.value)
    console.log(field)
    console.log(title)
    console.log(normalPrice)
    normalPrice[title][field] = (e.target.value === '') ? e.target.value : Number(e.target.value);
    setNormalPrice(normalPrice);
    setCurrTime(new Date())
  }

  function handleChangeAdvanced(e, field, title, day) {

    advancedPrice[day][title][field] = (e.target.value === '') ? e.target.value : Number(e.target.value);
    setAdvancedPrice(advancedPrice)
    setCurrTime(new Date())
  }

  function handleChangeAppliedDay(e, day) {
    console.log(applied_days)
    if (applied_days.includes(day) == false) {
      // applied_days.push(day);        
      setAppliedDays([...applied_days, day])
    }
    else {
      // console.log(without(applied_days, day))
      setAppliedDays(without(applied_days, day))
    }
  }

  function applyNormalPrice() {
    if (handleValidation()) {
      console.log(fields)
      configuration.postAPI({ url: "price/normal-price", params: { ...fields, normal_price: normalPrice } }).then((data) => {
        if (data.status === 200) {
          applyAdvancedPrice()
          // getNormalPrice(fields.country_id, fields.state_id, fields.city_id)
          // return toast.success('Price update successfully');
          // setData(data.payload)
        } else if (data.error) {
          return toast.error(data.error.message);
        } else {
          return toast.error("Something went wrong");
        }
      })
        .catch((error) => {
          return toast.error(error.message);
        });
    }
  }

  function applyAdvancedPrice() {
    console.log(fields)
    configuration.postAPI({ url: "price/day-slot-price", params: { ...fields, advancedPrice, timeSlot, applied_days } }).then((data) => {
      if (data.status === 200) {
        getNormalPrice(fields.country_id, fields.state_id, fields.city_id)
        getAdvancedPrice(fields.country_id, fields.state_id, fields.city_id)
        return toast.success('Price update successfully');
        // setData(data.payload)
      } else if (data.error) {
        return toast.error(data.error.message);
      } else {
        return toast.error("Something went wrong");
      }
    })
      .catch((error) => {
        return toast.error(error.message);
      });
  }

  function handleChangeTimeSlot(e, field, day) {
    const weekDay = timeSlot[day];
    if (weekDay.includes(e.target.value) == false) {
      weekDay.push(e.target.value);
    }
    else {
      weekDay.splice(weekDay.indexOf(e.target.value), 1);
    }
    console.log(weekDay)
    setTimeSlot(timeSlot)
    setCurrTime(new Date())
  }
  return (
    <>
      <Breadcrumb module={labels.price} />
      <div className="py-6 sm:px-6">
        <div className="relative">
          <section className="px-4" aria-labelledby="contact-heading">
            <div className="flex flex-col">
              <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                  <div className="mx-auto py-8 lg:flex lg:items-center lg:justify-between">
                    <div className="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-4xl text_theme">
                      <h2 className="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-4xl text_theme">
                        Vehicle Price
                      </h2>
                    </div>
                  </div>
                  <div className="space-y-8 divide-y divide-gray-200">
                    <div className="pt-1">
                      <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                        {fieldArr.map((field) => {
                          return (
                            <div className="sm:col-span-3" key={field.id}>
                              <Label title={field.title} />
                              <div className="mt-1">
                                {field.type === "Dropdown" ? (
                                  <Dropdown
                                    id={field.name}
                                    name={field.name}
                                    handleChange={handleChange}
                                    value={fields[field.name]}
                                    status={field.options || []}
                                  />
                                ) : null}
                              </div>
                              {errors[field.name] ? <Label title={field.title} fieldError={errors[field.name]} /> : null}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                  <div className="pt-5 border_color_theme">
                    <div className="flex justify-end">
                      <BtnSaveRecord handleSubmit={applyNormalPrice} title="Submit" path="/user" />
                    </div>
                  </div>
                  <div className="pt-5 border_color_theme">
                    {showPrice ?
                      <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg bg-white border-bottom-theme">
                        <div className="lg:gap-y-0 lg:gap-x-8">
                          <div class="table-responsive py-3 px-3">
                            <table class="table-outline mb-0  d-sm-table table table-hover" >
                              <thead class="thead-light">
                                <tr>
                                  <th style={{ border: '1px solid #cecece', borderBottom: '0', width: '300px', backgroundColor: '#f2f2f2' }}></th>
                                  <th colspan="5" className="bg-indigo-600 text-white border-gray-200 border" style={{ borderColor: 'rgb(164, 183, 193)' }}>Fare Estimate</th>
                                  <th colspan="4" className="bg-indigo-600 text-white border-gray-200 border" style={{ borderColor: 'rgb(164, 183, 193)' }}>Schedule Ride</th>
                                </tr>
                                <tr className="bg-gray">
                                  <th style={{ width: '300px', background: 'transparent', borderTop: 'none', backgroundColor: '#f2f2f2', }}>Vehicle</th>
                                  <th width="200px">Base Fare</th>
                                  <th width="200px">Per KM Fare</th>
                                  <th width="200px">Per Min Fare</th>
                                  <th width="200px">Min.Base KM</th>
                                  <th width="200px">Min.Base Fare</th>
                                  <th width="200px">Schedule Ride Base Fare</th>
                                  <th width="200px">Wait Fees</th>
                                  <th width="200px">Free Wait Mins</th>
                                </tr>
                              </thead>
                              <tbody>
                                {
                                  rides.map((option, key) => {
                                    return (<tr className="paddy">
                                      <td width="125px">{option.title}</td>
                                      <td><input type="number" class="table_input form-control" onChange={(e) => handleChangeNormal(e, 'base_fare', option.vehicle_ride_option_id)} value={(normalPrice[option.vehicle_ride_option_id]) ? normalPrice[option.vehicle_ride_option_id]['base_fare'] : ''} /></td>
                                      <td><input type="number" class="table_input form-control" onChange={(e) => handleChangeNormal(e, 'per_km_fare', option.vehicle_ride_option_id)} value={(normalPrice[option.vehicle_ride_option_id]) ? normalPrice[option.vehicle_ride_option_id]['per_km_fare'] : ''} /></td>
                                      <td><input type="number" class="table_input form-control" onChange={(e) => handleChangeNormal(e, 'per_min_fare', option.vehicle_ride_option_id)} value={(normalPrice[option.vehicle_ride_option_id]) ? normalPrice[option.vehicle_ride_option_id]['per_min_fare'] : ''} /></td>
                                      <td><input type="number" class="table_input form-control" onChange={(e) => handleChangeNormal(e, 'min_base_km', option.vehicle_ride_option_id)} value={(normalPrice[option.vehicle_ride_option_id]) ? normalPrice[option.vehicle_ride_option_id]['min_base_km'] : ''} /></td>
                                      <td><input type="number" class="table_input form-control" onChange={(e) => handleChangeNormal(e, 'min_base_fare', option.vehicle_ride_option_id)} value={(normalPrice[option.vehicle_ride_option_id]) ? normalPrice[option.vehicle_ride_option_id]['min_base_fare'] : ''} /></td>
                                      <td><input type="number" class="table_input form-control" onChange={(e) => handleChangeNormal(e, 'min_base_fare_for_scheduled_rides', option.vehicle_ride_option_id)} value={(normalPrice[option.vehicle_ride_option_id]) ? normalPrice[option.vehicle_ride_option_id]['min_base_fare_for_scheduled_rides'] : ''} /></td>
                                      <td><input type="number" class="table_input form-control" onChange={(e) => handleChangeNormal(e, 'wait_fees', option.vehicle_ride_option_id)} value={(normalPrice[option.vehicle_ride_option_id]) ? normalPrice[option.vehicle_ride_option_id]['wait_fees'] : ''} /></td>
                                      <td><input type="number" class="table_input form-control" onChange={(e) => handleChangeNormal(e, 'free_minutes_before_wait_fees_apply', option.vehicle_ride_option_id)} value={(normalPrice[option.vehicle_ride_option_id]) ? normalPrice[option.vehicle_ride_option_id]['free_minutes_before_wait_fees_apply'] : ''} /></td>
                                    </tr>)
                                  })
                                }
                              </tbody>
                            </table>
                          </div>
                          {/* <h3 className="foodie-hd-top-space pt30 pl-3 font-bold text-lg">Day & Time Slot Pricing</h3>
                          {
                            days.map((day, key1) => {
                              return(<div class="table-responsive py-3 px-3">
                                <div className="relative flex items-start">
                                  <div className="flex items-center h-5">
                                    <input
                                      id={day}
                                      checked={applied_days.includes(day)==true} onChange={(e)=>handleChangeAppliedDay(e, day)} value={day}
                                      type="checkbox"
                                      className="focus:ring-indigo-500 h-5 w-5 text-indigo-600 border-gray-300 rounded mt-1"
                                    />
                                  </div>
                                  <div className="ml-3 text-sm">
                                    <label htmlFor={day} className="font-medium text-gray-900 text-base">
                                      {day}
                                    </label>          
                                  </div>
                                </div>
                                
                                <Label htmlFor="status">Select Time Slot</Label>
                                <br />
                                {slots.map((e, key) => {
                                  return <div key={key} className="timeslot_check inline-block">
                                        <label>
                                            <input type="checkbox" checked={timeSlot[day].includes(e.time_slot_id)==true} onChange={(e)=>handleChangeTimeSlot(e,'time_slot_id', day)} value={e.time_slot_id}/><span>{e.title}</span>
                                        </label>
                                    </div>
                                })}
                                <table class="w-full table-outline mb-0  d-sm-table table table-hover" >
                                    <thead class="thead-light">
                                      <tr>                  
                                        <th style={{border: '1px solid #cecece',borderBottom: '0',width:'300px',backgroundColor: '#f2f2f2'}}></th>
                                        <th colspan="5" className="bg-indigo-600 text-white border-gray-200 border" style={{borderColor: 'rgb(164, 183, 193)'}}>Fare Estimate</th>
                                        <th colspan="4" className="bg-indigo-600 text-white border-gray-200 border" style={{borderColor: 'rgb(164, 183, 193)'}}>Schedule Ride</th>
                                      </tr>
                                      <tr className="bg-gray">        
                                        <th style={{width:'300px',background: 'transparent',borderTop: 'none',backgroundColor: '#f2f2f2',}}>Vehicle</th>                  
                                          <th width="200px">Base Fare</th>
                                          <th width="200px">Per KM Fare</th>
                                          <th width="200px">Per Min Fare</th>
                                          <th width="200px">Min.Base KM</th>
                                          <th width="200px">Min.Base Fare</th>
                                          <th width="200px">Schedule Ride Base Fare</th>
                                          <th width="200px">Wait Fees</th>
                                          <th width="200px">Free Wait Mins</th>                         
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {
                                        rides.map((option, key) => {
                                          return(<tr className="paddy">
                                            <td width="125px">{option.title}</td>
                                            <td><input type="number" class="table_input form-control" onChange={(e)=>handleChangeAdvanced(e, 'base_fare', option.vehicle_ride_option_id, day)} value={(advancedPrice[day][option.vehicle_ride_option_id])?advancedPrice[day][option.vehicle_ride_option_id]['base_fare']:''}/></td>
                                            <td><input type="number" class="table_input form-control" onChange={(e)=>handleChangeAdvanced(e, 'per_km_fare', option.vehicle_ride_option_id, day)} value={(advancedPrice[day][option.vehicle_ride_option_id])?advancedPrice[day][option.vehicle_ride_option_id]['per_km_fare']:''}/></td>
                                            <td><input type="number" class="table_input form-control" onChange={(e)=>handleChangeAdvanced(e, 'per_min_fare', option.vehicle_ride_option_id, day)} value={(advancedPrice[day][option.vehicle_ride_option_id])?advancedPrice[day][option.vehicle_ride_option_id]['per_min_fare']:''}/></td>
                                            <td><input type="number" class="table_input form-control" onChange={(e)=>handleChangeAdvanced(e, 'min_base_km', option.vehicle_ride_option_id, day)} value={(advancedPrice[day][option.vehicle_ride_option_id])?advancedPrice[day][option.vehicle_ride_option_id]['min_base_km']:''}/></td>
                                            <td><input type="number" class="table_input form-control" onChange={(e)=>handleChangeAdvanced(e, 'min_base_fare', option.vehicle_ride_option_id, day)} value={(advancedPrice[day][option.vehicle_ride_option_id])?advancedPrice[day][option.vehicle_ride_option_id]['min_base_fare']:''}/></td>
                                            <td><input type="number" class="table_input form-control" onChange={(e)=>handleChangeAdvanced(e, 'min_base_fare_for_scheduled_rides', option.vehicle_ride_option_id, day)} value={(advancedPrice[day][option.vehicle_ride_option_id])?advancedPrice[day][option.vehicle_ride_option_id]['min_base_fare_for_scheduled_rides']:''}/></td>
                                            <td><input type="number" class="table_input form-control" onChange={(e)=>handleChangeAdvanced(e, 'wait_fees', option.vehicle_ride_option_id, day)} value={(advancedPrice[day][option.vehicle_ride_option_id])?advancedPrice[day][option.vehicle_ride_option_id]['wait_fees']:''}/></td>
                                            <td><input type="number" class="table_input form-control" onChange={(e)=>handleChangeAdvanced(e, 'free_minutes_before_wait_fees_apply', option.vehicle_ride_option_id, day)} value={(advancedPrice[day][option.vehicle_ride_option_id])?advancedPrice[day][option.vehicle_ride_option_id]['free_minutes_before_wait_fees_apply']:''}/></td>                          
                                        </tr>)
                                        })
                                      }
                                    </tbody>
                                </table>
                              </div>)
                            })
                          } */}
                        </div>
                      </div> : ''
                    }
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default View;
