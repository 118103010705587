import AddEditSection from "../../../components/AddEditSection";
import Breadcrumb from "../../../parts/Breadcrumb";
import React, { useState, useEffect } from "react";
import labels from "../../../constants/RideOption";
import configuration from '../../../config';
import { toast } from 'react-toastify';
import { each } from 'underscore';

// For Add Role
function Add({ history }) {
  const statusOpt = [
    { id: 1, title: "Active", value: "active" },
    { id: 2, title: "Inactive", value: "inactive" },
  ];
  const [languages, setLanguages] = useState([])
  const [icon, setIcon] = useState(null);

  const [fieldArr, setFieldArr] = useState([
    { id: 1, title: "Image or Icon ", name: "icon", type: "FileInput" },
    { id: 2, title: "Capacity ", name: "max_seats", type: "TextInput", is_number: true },
    // { id: 3, title: "Insurance Amount ", name: "insurance_amount", type: "TextInput", is_number:true },
    // { id: 5, title: "Driver Minimum Wallet Balance ", name: "driver_min_wallet_balance", type: "TextInput", is_number: true },
    {
      id: 6,
      title: "Status",
      name: "status",
      type: "Dropdown",
      options: statusOpt,
    },
  ]);
  const [fields, setFields] = useState({});
  const [errors, setErrors] = useState({
    title: "",
    description: "",
    status: "",
  });
  useEffect(() => {
    configuration.getAPI({ url: 'language/list', params: { status: 'active' } }).then((data1) => {
      if (data1.status === 200) {
        setLanguages(data1.payload)
        let titleArr = [];
        let descArr = [];
        let fareArr = [];
        data1.payload.map((language, index) => {
          titleArr.push({
            id: 3 + index,
            title: `Title [${language.title}]`,
            name: `title_${language.code}`,
            type: "TextInput"
          });
          descArr.push({
            id: 3 + index,
            title: `Description [${language.title}]`,
            name: `caption_${language.code}`,
            type: "TextArea"
          });
          fareArr.push({
            id: 3 + index,
            title: `Fare Description [${language.title}]`,
            name: `fare_${language.code}`,
            type: "TextArea"
          });
        })
        configuration.getAPI({ url: 'insurance/list', params: {} }).then((data2) => {
          if (data2.status === 200) {
            let finalArr = [];
            each(data2.payload, single => {
              finalArr.push({ id: single.insurance_id, value: single.insurance_id, label: single.title })
            })
            // console.log(finalArr)
            // setInsuranceOpt(finalArr)
            configuration.getAPI({ url: 'feature/list', params: { status: 'active' } }).then((data) => {
              if (data.status === 200) {
                let finalArr2 = [];
                each(data.payload, single => {
                  finalArr2.push({ id: single.feature_id, value: single.feature_id, label: single.title })
                })
                const fieldArr2 = [
                  /* {
                    id: 2,
                    title: "Insurance",
                    name: "insurance_id",
                    type: "MultiDropdown",
                    options: finalArr,
                  }, */
                  {
                    id: 3,
                    title: "Feature",
                    name: "feature_id",
                    type: "MultiDropdown",
                    options: finalArr2,
                  }
                ]
                console.log(fieldArr2);
                setFieldArr([...titleArr, ...descArr, ...fareArr, ...fieldArr2, ...fieldArr])
                // setFeatureOpt(finalArr)
              }
            }).catch(error => {
              return toast.error(error.message)
            });
          }
        }).catch(error => {
          return toast.error(error.message)
        });
      }
    }).catch(error => {
      return toast.error(error.message)
    });
  }, []);

  function handleValidation() {
    let flag = true;
    let error = {}
    each(languages, (lang, ind) => {
      if (!fields['title_' + lang.code]) {
        error['title_' + lang.code] = "Please enter title in " + lang.title
        flag = false
      }
      if (!fields['caption_' + lang.code]) {
        error['caption_' + lang.code] = "Please enter description in " + lang.title
        flag = false
      }
    })

    if (!fields.icon) {
      error['icon'] = "Please select icon"
      flag = false
    }
    if (!fields.max_seats) {
      error['max_seats'] = "Please select capacity"
      flag = false
    }
    /* if (!fields.driver_min_wallet_balance) {
      error['driver_min_wallet_balance'] = "Please enter driver min wallet balance"
      flag = false
    } */

    if (!fields.status) {
      error['status'] = "Please select status"
      flag = false
    }
    setErrors({ ...error })
    return flag;
  }
  const handleSubmit = () => {
    if (handleValidation()) {
      const formData = new FormData();
      formData.append('icon', icon);
      formData.append('fields', JSON.stringify({ ...fields, lang_code: 'EN' }));
      configuration.postFormDataAPI({ url: 'ride-option/create', params: formData }).then((data) => {
        if (data.status === 200) {
          history.push('/rideoption')
        } else if (data.status === 404) {
          return toast.error(data.error.message)
        } else {
          return toast.error('Something went wrong')
        }
      }).catch(error => {
        return toast.error(error.message)
      });
    }
  }
  function cancleImage(field, e) {
    setIcon(null);
    setFields({ ...fields, [field]: '' })
  }

  function handleChange(field, e) {
    if (field === 'icon') {
      setIcon(e.target.files[0]);
      setFields({ ...fields, [field]: URL.createObjectURL(e.target.files[0]) })
    } else if (field === 'insurance_id' || field === 'feature_id') {
      setFields({ ...fields, [field]: e })
    } else {
      setFields({ ...fields, [field]: e.target.value })
    }
  }
  return (
    <>
      <Breadcrumb module={labels.AddRideOption} />
      <AddEditSection title="Add Ride Option" path="/rideoption" fields={fieldArr} fieldValue={fields} fieldError={errors} cancleImage={cancleImage} handleChange={handleChange} handleSubmit={handleSubmit} />
    </>
  );
}

export default Add;
