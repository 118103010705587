import React, { useState, useEffect } from "react";
import configuration from '../../../config';
import { toast } from 'react-toastify';
import moment from "moment/moment";

let Id = "";
function Subscription() {
  const [actions, setActions] = useState([]);
  useEffect(() => {
    var url = window.location.href;
    Id = url.substring(url.lastIndexOf("/") + 1);
    configuration.getAPI({ url: 'passenger/detail', params: { user_id: Id } }).then((data) => {
      if (data.status === 200) {
        setActions(data.payload)
      }
    }).catch(error => {
      return toast.error(error.message)
    });
  }, []);
  return (
    <div className="md:pl-8 sm:py-7 col-span-10">
      <section aria-labelledby="applicant-information-title">
        <div className="bg-white shadow sm:rounded-lg">
          <div className="px-4 py-5 sm:px-6">
            <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
              Subscription
            </h2>
            <p className="mt-1 max-w-2xl text-base text-gray-500 text_theme">This information is show driver subscription details.</p>
          </div>
          <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
            <div className="grid grid-cols-3 mb-5 gap-5">
              <div className="flex items-center">
                <label htmlFor="push-everything" className="mr-3 block text-basefont-medium text-gray-700 text_theme">
                  Is Subscription Enable:
                </label>
                <p htmlFor="push-everything" className="mr-3 block text-basefont-medium text-gray-700 text_theme capitalize">
                  {actions.isSubscriptionEnable ? "Yes" : "No"}
                </p>
              </div>
              <div className="flex items-center">
                <label htmlFor="push-everything" className="mr-3 block text-basefont-medium text-gray-700 text_theme">
                  Subscription Id:
                </label>
                <p htmlFor="push-everything" className="mr-3 block text-basefont-medium text-gray-700 text_theme capitalize">
                  {actions.subscriptionId}
                </p>
              </div>
              <div className="flex items-center">
                <label htmlFor="push-everything" className="mr-3 block text-basefont-medium text-gray-700 text_theme">
                  Subscription Expiry Date:
                </label>
                <p htmlFor="push-everything" className="mr-3 block text-basefont-medium text-gray-700 text_theme capitalize">
                  {moment(actions.subscriptionExpiryDate).format("DD-MM-YYYY")}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Subscription;
