import { initializeApp } from 'firebase/app';
import { getDatabase } from "firebase/database";
// import { getAuth} from "firebase/auth";
import configuration from './config';
const firebaseConfig = {
    apiKey: configuration.webAPIKey,
    authDomain: "peindia-cca90.firebaseapp.com",
    databaseURL: configuration.firebaseDBURL,
    projectId: 'peindia-cca90',
    storageBucket: "peindia-cca90.appspot.com",
    messagingSenderId: "339249311645",
    appId: "1:339249311645:web:04c96c7110b7b1bca84974",
    measurementId: "G-DBKDK5Q15K"
}
// const firebaseConfig = {
//     apiKey: "AIzaSyB-n-cV916Jswrm4DLFz-oIsKxsbgt1sW8",
//     authDomain: "peindia-af604.firebaseapp.com",
//     databaseURL: "https://peindia-af604-default-rtdb.firebaseio.com",
//     projectId: "peindia-af604",
//     storageBucket: "peindia-af604.appspot.com",
//     messagingSenderId: "1007818613150",
//     appId: "1:1007818613150:web:b89ba82d1a2f7b4873768f",
//     measurementId: "G-32MQWBZYLH"
// };
const app = initializeApp(firebaseConfig);
const database = getDatabase(app)
// const auth = getAuth(app);
export default database;