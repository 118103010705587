/* eslint-disable jsx-a11y/anchor-is-valid */
import Breadcrumb from "../../parts/Breadcrumb";
import React, { useState, useEffect } from "react";
import labels from "../../constants/Dashboard";
import { ArchiveIcon, InformationCircleIcon, DocumentRemoveIcon, UserIcon, MailIcon, AnnotationIcon, MailOpenIcon, InboxInIcon, SpeakerphoneIcon, OfficeBuildingIcon, CheckCircleIcon, UserCircleIcon } from '@heroicons/react/outline'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserGroup, faUser, faUserTie, faUserCheck, faPerson, faBuilding, faHandshake, faCar, faCalendar, faMotorcycle, faWheelchair } from '@fortawesome/free-solid-svg-icons'
import configuration from "../../config";
import TextInput from "../../components/Form/TextInput";
import Skeleton from 'react-loading-skeleton';
import { reactLocalStorage } from 'reactjs-localstorage';
import 'react-loading-skeleton/dist/skeleton.css'
import {
  Chart,
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip
} from 'chart.js';
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";


import { Line, Bar } from "react-chartjs-2";

Chart.register(
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip
);

const Tabs = ({ color }) => {

  const [openTab, setOpenTab] = React.useState(1);
  const history = useHistory();
  const options = {
    maintainAspectRatio: true,	// Don't maintain w/h ratio
  }


  const [Statistics, setStatistics] = useState([]);
  const [years, setYears] = useState([]);
  const [salesYear, setSalesYear] = useState('');
  const [profitYear, setProfitYear] = useState('');
  const [Sales, setSales] = useState([]);
  const [Profit, setProfit] = useState([]);
  const [Discount, setDiscount] = useState([]);
  const [Tax, setTax] = useState([]);
  const [subStatistics, setSubStatistics] = useState([]);

  const [vehicleData, setVehicleData] = useState([]);
  const [vehicleLabel, setVehicleLabel] = useState([]);
  const [driverData, setDriverData] = useState([]);
  const [driverLabel, setDriverLabel] = useState([]);
  const [companyData, setCompanyData] = useState([]);
  const [companyLabel, setCompanyLabel] = useState([]);
  const [partnerData, setPartnerData] = useState([]);
  const [partnerLabel, setPartnerLabel] = useState([]);
  const [dispatcherData, setDispatcherData] = useState([]);
  const [dispatcherLabel, setDispatcherLabel] = useState([]);
  const [userFields, setUserFields] = useState({
    user_radio: 'user_id',
    driver_radio: 'user_id',
    trip_radio: 'trip_id',
  });

  const UserMethods = [
    { id: 'user_id', title: 'User ID' },
    { id: 'email', title: 'Email' },
    { id: 'mobile', title: 'Phone' },
  ]
  const DriverMethods = [
    { id: 'user_id', title: 'Driver ID' },
    { id: 'mobile', title: 'Phone' },
    { id: 'plate_no', title: 'Vehicle No' },
  ]
  const RideMethods = [
    { id: 'trip_id', title: 'Ride ID' },
  ]

  // For Dashboard

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  function getSubSummary(user_type) {
    configuration.getAPI({ url: 'dashboard/sub-summary', params: { user_type } }).then((data) => {
      setSubStatistics(data.payload || []);
    }).catch(error => {
      // console.log(error)
      return toast.error(error.message)
    });
  }

  function totalSales(type, year) {
    configuration.getAPI({ url: 'dashboard/total-sales', params: { type, year } }).then((data) => {
      if (type === 'sales') {
        setSales(data.payload || []);
      }
      if (type === 'profit') {
        setProfit(data.payload || []);
      }
    }).catch(error => {
      // console.log(error)
      return toast.error(error.message)
    });
  }

  function topPerformings(type) {
    configuration.getAPI({ url: 'dashboard/top-performings', params: { type } }).then((data) => {
      if (type === 'driver') {
        setDriverData(data.payload.data || []);
        setDriverLabel(data.payload.labels || []);
      }
      if (type === 'vehicle') {
        setVehicleData(data.payload.data || []);
        setVehicleLabel(data.payload.labels || []);
      }
      if (type === 'partner') {
        setPartnerData(data.payload.data || []);
        setPartnerLabel(data.payload.labels || []);
      }
      if (type === 'company') {
        setCompanyData(data.payload.data || []);
        setCompanyLabel(data.payload.labels || []);
      }
      if (type === 'dispatcher') {
        setDispatcherData(data.payload.data || []);
        setDispatcherLabel(data.payload.labels || []);
      }
    }).catch(error => {
      // console.log(error)
      return toast.error(error.message)
    });
  }

  useEffect(() => {
    var logId = reactLocalStorage.get('user_id');
    setSalesYear(new Date().getFullYear())
    setProfitYear(new Date().getFullYear())
    const startYear = 2022;
    const endYear = new Date().getFullYear();
    for (let i = startYear; i <= endYear; i++) {
      years.push(i);
    }
    setYears(years)
    console.log(logId)
    configuration.getAPI({ url: 'dashboard/summary', params: { logId } }).then((data) => {
      if (data.status === 200) {
        setStatistics(data.payload || []);
      } else if (data.status === 406) {
        reactLocalStorage.set('token', "");
        reactLocalStorage.set('loginlog_id', "");
        reactLocalStorage.set('user_id', "");
        reactLocalStorage.set('email', "");
        history.push("/")
      }
    }).catch(error => {
      return toast.error(error.message)
    });
    configuration.getAPI({ url: 'dashboard/total-sales', params: { type: 'tax' } }).then((data) => {
      setTax(data.payload || []);
    }).catch(error => {
      // console.log(error)
      return toast.error(error.message)
    });
    configuration.getAPI({ url: 'dashboard/total-sales', params: { type: 'discount' } }).then((data) => {
      setDiscount(data.payload || []);
    }).catch(error => {
      // console.log(error)
      return toast.error(error.message)
    });
    totalSales('sales', new Date().getFullYear())
    totalSales('profit', new Date().getFullYear())
    getSubSummary('trip')
    topPerformings('vehicle')
    topPerformings('driver')
    topPerformings('partner')
    topPerformings('company')
    topPerformings('dispatcher')
  }, []);


  function handleChangeTab(type, id, e) {
    console.log(id)
    setSubStatistics([]);
    getSubSummary(type)
    setOpenTab(id);
  }

  function handleChange(type, e) {
    if (type === 'sales') {
      setSalesYear(e.target.value);
    } else {
      setProfitYear(e.target.value)
    }
    totalSales(type, e.target.value);
  }

  function handleChangeUser(field, e) {
    // console.log(field)
    // console.log(e.target.value)
    setUserFields({ ...userFields, [field]: e.target.value })
  }

  function handleFind(type) {
    // console.log(type)
    if (type === 'user' && (!userFields.user_info || userFields.user_info === '')) {
      return toast.error("Please Enter User Detail");
    } else if (type === 'driver' && (!userFields.driver_info || userFields.driver_info === '')) {
      return toast.error("Please Enter Driver Detail");
    } else if (type === 'ride' && (!userFields.ride_info || userFields.ride_info === '')) {
      return toast.error("Please Enter Trip Detail");
    } else {
      configuration.postAPI({ url: 'dashboard/search', params: { ...userFields, type } }).then((data) => {
        if (data.status === 200) {
          if (type === 'user') {
            history.push(`/passenger/profile/${data.payload.user_id}`)
          } else if (type === 'driver') {
            history.push(`/driver/profile/${data.payload.user_id}`)
          } else {
            history.push({
              pathname: `/tripsdetail/${userFields.ride_info}`,
              state: 'dashboard'
            })
          }
        } else if (data.status === 404) {
          return toast.error(data.error.message)
        } else {
          return toast.error('Something went wrong')
        }
      }).catch(error => {
        return toast.error(error.message)
      });
    }
    // console.log(userFields)
  }

  const Box = ({ children }) => (
    <div key={children} className="relative bg-gray-50 pt-5 px-6 pb-6  shadow rounded-lg overflow-hidden">
      <dt className="text-base font-medium text-red-600 truncate">{children}</dt>
      <dd className="mt-1 text-3xl font-semibold text-gray-900">{children}</dd>
    </div>
  );
  return (
    <>
      <Breadcrumb module={labels.dashboard} />

      {/* <div className="grid grid-cols-1 md:grid-cols-1">
        <div className="px-3 py-3 ">
          <div className="px-4 py-4 bg-white shadow-md rounded-md">
            <dl className="grid grid-cols-1 gap-5 sm:grid-cols-3 lg:grid-cols-6">
            </dl>
          </div>
        </div>
      </div> */}
      <div className=" px-3 py-3 grid md:grid-cols-3 gap-6">
        <div className="bg-white shadow sm:rounded-lg text-center">
          <div className="px-4 py-5 sm:px-6">
            <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
              User Details
            </h2>
            <div className="mt-1 flex rounded-md shadow-sm">
              {(userFields.user_radio === 'mobile') ?
                <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
                  +91
                </span> : ''
              }
              <input
                type={(userFields.user_radio === 'mobile') ? "number" : "text"}
                name={'user_info'}
                id={'user_info'}
                value={userFields.user_info}
                placeholder="Enter User ID/Phone/Email"
                onChange={(e) => handleChangeUser('user_info', e)}
                className="flex-1 min-w-0 block w-full px-3 py-2 rounded-none rounded-r-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300"
              />
            </div>
            <div>
              <fieldset className="mt-4">
                <legend className="sr-only">User Details</legend>
                <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                  {UserMethods.map((user) => (
                    <div key={`user_${user.id}`} className="flex items-center">
                      <input
                        id={`user_${user.id}`}
                        name="user-method"
                        value={user.id}
                        type="radio"
                        onChange={(e) => handleChangeUser('user_radio', e)}
                        defaultChecked={user.id === 'user_id'}
                        className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                      />
                      <label htmlFor={`user_${user.id}`} className="ml-3 block text-sm font-medium text-gray-700">
                        {user.title}
                      </label>
                    </div>
                  ))}
                </div>
              </fieldset>
            </div><br />
            <button
              type="button"
              onClick={(e) => handleFind('user')}
              className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 xl-admin-btn"
            // onClick={() => history.push(path)}
            >
              User Details
            </button>
          </div>
        </div>
        <div className="bg-white shadow sm:rounded-lg text-center">
          <div className="px-4 py-5 sm:px-6">
            <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
              Driver Details
            </h2>
            <div className="mt-1 flex rounded-md shadow-sm">
              {(userFields.driver_radio === 'mobile') ?
                <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm input_theme">
                  +91
                </span> : ''
              }
              <input
                type={(userFields.driver_radio === 'mobile') ? "number" : "text"}
                name={'driver_info'}
                id={'driver_info'}
                value={userFields.driver_info}
                placeholder="Enter Driver ID/Phone/Vehicle No"
                onChange={(e) => handleChangeUser('driver_info', e)}
                className="flex-1 min-w-0 block w-full px-3 py-2 rounded-none rounded-r-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300"
              />
            </div>
            <div>
              <fieldset className="mt-4">
                <legend className="sr-only">Driver Details</legend>
                <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                  {DriverMethods.map((user) => (
                    <div key={`driver_${user.id}`} className="flex items-center">
                      <input
                        id={`driver_${user.id}`}
                        name="driver-method"
                        type="radio"
                        value={user.id}
                        defaultChecked={user.id === 'user_id'}
                        onChange={(e) => handleChangeUser('driver_radio', e)}
                        className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                      />
                      <label htmlFor={`driver_${user.id}`} className="ml-3 block text-sm font-medium text-gray-700">
                        {user.title}
                      </label>
                    </div>
                  ))}
                </div>
              </fieldset>
            </div><br />
            <button
              type="button"
              onClick={(e) => handleFind('driver')}
              className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 xl-admin-btn"
            // onClick={() => history.push(path)}
            >
              Driver Details
            </button>
          </div>
        </div>
        <div className="bg-white shadow sm:rounded-lg text-center">
          <div className="px-4 py-5 sm:px-6">
            <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
              Ride Details
            </h2>
            <input
              type={"text"}
              name={'ride_info'}
              id={'ride_info'}
              value={userFields.ride_info}
              placeholder="Enter Ride ID"
              onChange={(e) => handleChangeUser('ride_info', e)}
              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full text-base border-gray-300 rounded-md input_theme"
            />
            <div>
              <fieldset className="mt-4">
                <legend className="sr-only">Ride Details</legend>
                <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                  {RideMethods.map((user) => (
                    <div key={`ride_${user.id}`} className="flex items-center">
                      <input
                        id={`ride_${user.id}`}
                        name="ride-method"
                        type="radio"
                        value={user.id}
                        defaultChecked={user.id === 'trip_id'}
                        onChange={(e) => handleChangeUser('trip_radio', e)}
                        className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                      />
                      <label htmlFor={`ride_${user.id}`} className="ml-3 block text-sm font-medium text-gray-700">
                        {user.title}
                      </label>
                    </div>
                  ))}
                </div>
              </fieldset>
            </div><br />
            <button
              type="button"
              onClick={(e) => handleFind('ride')}
              className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 xl-admin-btn"
            // onClick={() => history.push(path)}
            >
              Ride Details
            </button>
          </div>
        </div>
      </div>

      <div className=" px-3 py-3 grid md:grid-cols-2 gap-6">
        <div className="bg-white shadow sm:rounded-lg">
          <div className="px-4 py-5 sm:px-6">
            <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
              Sales Revenue
            </h2>
          </div>
          <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
            <div className="grid grid-cols-2 mb-5">
              <select
                id="country10"
                name="country10"
                autoComplete="country10-name"
                onChange={(e) => handleChange('sales', e)}
                value={salesYear}
                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-baseinput_theme"
              >
                {
                  years.map((e, key) => {
                    return <option key={key} value={e}>{e}</option>;
                  })
                }
              </select>
            </div>
            <div className="rounded-md bg-blue-50 p-4">
              <div className="flex">
                <div className="flex-shrink-0">
                  <InformationCircleIcon className="h-5 w-5 text-indigo-600" aria-hidden="true" />
                </div>
                <div className="ml-3 flex-1 md:flex md:justify-between">
                  <p className="text-sm text-indigo-600">This chart shows total trips revenue by month & year wise</p>
                </div>
              </div>
            </div>
            <div className=" mt-5  chart-wrapper">
              <Bar data={{
                labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "July", "Aug", "Sep", "Oct", "Nov", "Dec"],
                datasets: [
                  {
                    label: "Sales Revenue (₹)",
                    data: Sales,
                    fill: false,
                    backgroundColor: "#137DC5",
                  }
                ]
              }} />
              <p className="text-center w-full pt-4 ">Month</p>
            </div>
          </div>
        </div>
        <div className="bg-white shadow sm:rounded-lg">
          <div className="px-4 py-5 sm:px-6">
            <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
              Profit
            </h2>
          </div>
          <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
            <div className="grid grid-cols-2 mb-5">
              <select
                id="country10"
                name="country10"
                onChange={(e) => handleChange('profit', e)}
                value={profitYear}
                autoComplete="country10-name"
                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-baseinput_theme"
              >
                {
                  years.map((e, key) => {
                    return <option key={key} value={e}>{e}</option>;
                  })
                }
              </select>
            </div>
            <div className="rounded-md bg-blue-50 p-4">
              <div className="flex">
                <div className="flex-shrink-0">
                  <InformationCircleIcon className="h-5 w-5 text-indigo-600" aria-hidden="true" />
                </div>
                <div className="ml-3 flex-1 md:flex md:justify-between">
                  <p className="text-sm text-indigo-600">Profit Shows Company Earning ( Deducting from Trip Amount , Driver Earning , GST , etc )</p>
                </div>
              </div>
            </div>
            <div className="mt-5 chart-wrapper">
              <Line data={{
                labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "July", "Aug", "Sep", "Oct", "Nov", "Dec"],
                datasets: [
                  {
                    label: "Profit (₹)",
                    data: Profit,
                    fill: true,
                    backgroundColor: "rgb(19, 125, 197, 0.5)",
                    borderColor: "rgba(19, 125, 197)"
                  }
                ]
              }} />
              <p className="text-center w-full pt-4 ">Month</p>
            </div>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-1">
        <div className="px-3 py-3 ">
          <div className="px-4 py-4 bg-white shadow-md rounded-md">

            <dl className="grid grid-cols-1 gap-5 sm:grid-cols-3 lg:grid-cols-6">
              {/* <div className="relative bg-gray-50 pt-5 px-6 pb-6  shadow rounded-lg overflow-hidden">
                <dt>
                  <div className="absolute bg-indigo-600 rounded-md p-3">
                    <FontAwesomeIcon icon={faUser} size="lg" className="highlight" />
                  </div>
                  <p className="ml-16 text-basefont-medium text-gray-600 truncate">Staffs</p>
                </dt>
                <dd className="ml-16 flex items-baseline">
                  <p className="text-2xl font-semibold text-gray-900">{(Statistics.roleCount) ? Statistics.roleCount : <Skeleton width={40} height={25} />}</p>
                </dd>
              </div> */}
              <div className="relative bg-gray-50 pt-5 px-6 pb-6  shadow rounded-lg overflow-hidden">
                <dt>
                  <div className="absolute bg-indigo-600 rounded-md p-3">
                    <FontAwesomeIcon icon={faUserTie} size="lg" className="highlight" />
                  </div>
                  <p className="ml-16 text-basefont-medium text-gray-600 truncate">Roles</p>
                </dt>
                <dd className="ml-16 flex items-baseline">
                  <p className="text-2xl font-semibold text-gray-900">{(Statistics.roleCount) ? Statistics.roleCount : <Skeleton width={40} height={25} />}</p>
                </dd>
              </div>
              <div className="relative bg-gray-50 pt-5 px-6 pb-6  shadow rounded-lg overflow-hidden">
                <dt>
                  <div className="absolute bg-indigo-600 rounded-md p-3">
                    <FontAwesomeIcon icon={faUserGroup} size="lg" className="highlight" />
                  </div>
                  <p className="ml-16 text-basefont-medium text-gray-600 truncate">Passengers</p>
                </dt>
                <dd className="ml-16 flex items-baseline">
                  <p className="text-2xl font-semibold text-gray-900">{(Statistics.passengerCount) ? Statistics.passengerCount : <Skeleton width={40} height={25} />}</p>
                </dd>
              </div>
              <div className="relative bg-gray-50 pt-5 px-6 pb-6  shadow rounded-lg overflow-hidden">
                <dt>
                  <div className="absolute bg-indigo-600 rounded-md p-3">
                    <FontAwesomeIcon icon={faUserCheck} size="lg" className="highlight" />
                  </div>
                  <p className="ml-16 text-basefont-medium text-gray-600 truncate">Drivers</p>
                </dt>
                <dd className="ml-16 flex items-baseline">
                  <p className="text-2xl font-semibold text-gray-900">{(Statistics.driverCount) ? Statistics.driverCount : <Skeleton width={40} height={25} />}</p>
                </dd>
              </div>
              {/* <div className="relative bg-gray-50 pt-5 px-6 pb-6  shadow rounded-lg overflow-hidden">
                <dt>
                  <div className="absolute bg-indigo-600 rounded-md p-3">
                    <FontAwesomeIcon icon={faPerson} size="lg" className="highlight" />
                  </div>
                  <p className="ml-16 text-basefont-medium text-gray-600 truncate">Dispatchers</p>
                </dt>
                <dd className="ml-16 flex items-baseline">
                  <p className="text-2xl font-semibold text-gray-900">{(Statistics.dispatcherCount) ? Statistics.dispatcherCount : <Skeleton width={45} height={25} />}</p>
                </dd>
              </div>
              <div className="relative bg-gray-50 pt-5 px-6 pb-6  shadow rounded-lg overflow-hidden">
                <dt>
                  <div className="absolute bg-indigo-600 rounded-md p-3">
                    <FontAwesomeIcon icon={faBuilding} size="lg" className="highlight" />
                  </div>
                  <p className="ml-16 text-basefont-medium text-gray-600 truncate">Companies</p>
                </dt>
                <dd className="ml-16 flex items-baseline">
                  <p className="text-2xl font-semibold text-gray-900">{(Statistics.companyCount) ? Statistics.companyCount : <Skeleton width={45} height={25} />}</p>
                </dd>
              </div>
              <div className="relative bg-gray-50 pt-5 px-6 pb-6  shadow rounded-lg overflow-hidden">
                <dt>
                  <div className="absolute bg-indigo-600 rounded-md p-3">
                    <FontAwesomeIcon icon={faHandshake} size="lg" className="highlight" />
                  </div>
                  <p className="ml-16 text-basefont-medium text-gray-600 truncate">Partners</p>
                </dt>
                <dd className="ml-16 flex items-baseline">
                  <p className="text-2xl font-semibold text-gray-900">{(Statistics.partnerCount) ? Statistics.partnerCount : <Skeleton width={40} height={25} />}</p>
                </dd>
              </div> */}
              <div className="relative bg-gray-50 pt-5 px-6 pb-6  shadow rounded-lg overflow-hidden">
                <dt>
                  <div className="absolute bg-indigo-600 rounded-md p-3">
                    <FontAwesomeIcon icon={faCar} size="lg" className="highlight" />
                  </div>
                  <p className="ml-16 text-basefont-medium text-gray-600 truncate">Makes</p>
                </dt>
                <dd className="ml-16 flex items-baseline">
                  <p className="text-2xl font-semibold text-gray-900">{(Statistics.makeCount) ? Statistics.makeCount : <Skeleton width={40} height={25} />}</p>
                </dd>
              </div>
              <div className="relative bg-gray-50 pt-5 px-6 pb-6  shadow rounded-lg overflow-hidden">
                <dt>
                  <div className="absolute bg-indigo-600 rounded-md p-3">
                    <FontAwesomeIcon icon={faCar} size="lg" className="highlight" />
                  </div>
                  <p className="ml-16 text-basefont-medium text-gray-600 truncate">Models</p>
                </dt>
                <dd className="ml-16 flex items-baseline">
                  <p className="text-2xl font-semibold text-gray-900">{(Statistics.modelCount) ? Statistics.modelCount : <Skeleton width={40} height={25} />}</p>
                </dd>
              </div>
              <div className="relative bg-gray-50 pt-5 px-6 pb-6  shadow rounded-lg overflow-hidden">
                <dt>
                  <div className="absolute bg-indigo-600 rounded-md p-3">
                    <FontAwesomeIcon icon={faCalendar} size="lg" className="highlight" />
                  </div>
                  <p className="ml-16 text-basefont-medium text-gray-600 truncate">Years</p>
                </dt>
                <dd className="ml-16 flex items-baseline">
                  <p className="text-2xl font-semibold text-gray-900">{(Statistics.yearCount) ? Statistics.yearCount : <Skeleton width={40} height={25} />}</p>
                </dd>
              </div>
              <div className="relative bg-gray-50 pt-5 px-6 pb-6  shadow rounded-lg overflow-hidden">
                <dt>
                  <div className="absolute bg-indigo-600 rounded-md p-3">
                    <FontAwesomeIcon icon={faMotorcycle} size="lg" className="highlight" />
                  </div>
                  <p className="ml-16 text-basefont-medium text-gray-600 truncate">Ride Options</p>
                </dt>
                <dd className="ml-16 flex items-baseline">
                  <p className="text-2xl font-semibold text-gray-900">{(Statistics.rideCount) ? Statistics.rideCount : <Skeleton width={50} height={25} />}</p>
                </dd>
              </div>
              {/* <div className="relative bg-gray-50 pt-5 px-6 pb-6  shadow rounded-lg overflow-hidden">
                <dt>
                  <div className="absolute bg-indigo-600 rounded-md p-3">
                    <FontAwesomeIcon icon={faWheelchair} size="lg" className="highlight" />
                  </div>
                  <p className="ml-16 text-basefont-medium text-gray-600 truncate">Mobility Options</p>
                </dt>
                <dd className="ml-16 flex items-baseline">
                  <p className="text-2xl font-semibold text-gray-900">{(Statistics.mobilityCount) ? Statistics.mobilityCount : <Skeleton width={60} height={25} />}</p>
                </dd>
              </div> */}
            </dl>
          </div>
        </div>
      </div>

      <div className=" px-3 py-3 grid md:grid-cols-2 gap-6">
        <div className="bg-white shadow sm:rounded-lg">
          <div className="px-4 py-5 sm:px-6">
            <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
              Collected Tax
            </h2>
          </div>
          <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
            <div className="rounded-md bg-blue-50 p-4">
              <div className="flex">
                <div className="flex-shrink-0">
                  <InformationCircleIcon className="h-5 w-5 text-indigo-600" aria-hidden="true" />
                </div>
                <div className="ml-3 flex-1 md:flex md:justify-between">
                  <p className="text-sm text-indigo-700">This shows GST or Tax COLLECTED Month wise</p>
                </div>
              </div>
            </div>
            <div className="mt-5 chart-wrapper">
              <Line options={options} data={{
                labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "July", "Aug", "Sep", "Oct", "Nov", "Dec"],
                datasets: [
                  {
                    label: "Highest Tax (₹)",
                    data: Tax,
                    fill: true,
                    backgroundColor: "rgb(14, 165, 233,0.2)",
                    borderColor: "rgba(14, 165, 233,1)"
                  }
                ]
              }} />
              <p className="text-center w-full pt-4 ">Month</p>
            </div>
          </div>
        </div>
        <div className="bg-white shadow sm:rounded-lg">
          <div className="px-4 py-5 sm:px-6">
            <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
              Discount
            </h2>
          </div>
          <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
            <div className="rounded-md bg-blue-50 p-4">
              <div className="flex">
                <div className="flex-shrink-0">
                  <InformationCircleIcon className="h-5 w-5 text-indigo-600" aria-hidden="true" />
                </div>
                <div className="ml-3 flex-1 md:flex md:justify-between">
                  <p className="text-sm text-indigo-600">This shows how much discount applied from all over trip month wise</p>
                </div>
              </div>
            </div>
            <div className="mt-5 chart-wrapper">
              <Line options={options} data={{
                labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "July", "Aug", "Sep", "Oct", "Nov", "Dec"],
                datasets: [
                  {
                    label: "Highest Discount (₹)",
                    data: Discount,
                    fill: true,
                    backgroundColor: "rgb(19, 125, 197, 0.5)",
                    borderColor: "rgba(19, 125, 197)"
                  }
                ]
              }} />
              <p className="text-center w-full pt-4 ">Month</p>
            </div>
          </div>
        </div>
      </div>

      <div className=" px-3 py-3 grid md:grid-cols-1 gap-6">
        <div className="bg-white shadow sm:rounded-lg">
          <div className="px-4 py-5 sm:px-6">
            <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
              Statistics
            </h2>
          </div>
          <div className="border-t border-gray-200">
            <div className="mb-5 chart-wrapper">
              <ul
                className="flex mb-0 list-none flex-wrap pt-3 flex-row border-b border-gray-200 font-medium"
                role="tablist"
              >
                <li className="-mb-px mr-2 last:mr-0 flex-auto text-center cursor-pointer">
                  <a
                    className={
                      "text-lg text-base px-5 py-3 block " +
                      (openTab === 1
                        ? "text-indigo-600 border-b-2 border-indigo-600 bg-" + color + "-600"
                        : "text-" + color + "-600 bg-white")
                    }
                    onClick={e => handleChangeTab('trip', 1, e)}
                    data-toggle="tab"
                    role="tablist"
                  >
                    Trips
                  </a>
                </li>
                <li className="-mb-px mr-2 last:mr-0 flex-auto text-center cursor-pointer">
                  <a
                    className={
                      "text-lg text-base px-5 py-3 block " +
                      (openTab === 2
                        ? "text-indigo-600 border-b-2 border-indigo-600 bg-" + color + "-600"
                        : "text-" + color + "-600 bg-white")
                    }
                    onClick={e => handleChangeTab('passenger', 2, e)}
                    data-toggle="tab"
                    role="tablist"
                  >
                    Passengers
                  </a>
                </li>
                <li className="-mb-px mr-2 last:mr-0 flex-auto text-center cursor-pointer">
                  <a
                    className={
                      "text-lg text-base px-5 py-3 block " +
                      (openTab === 3
                        ? "text-indigo-600 border-b-2 border-indigo-600 bg-" + color + "-600"
                        : "text-" + color + "-600 bg-white")
                    }
                    onClick={e => handleChangeTab('driver', 3, e)}
                    data-toggle="tab"
                    role="tablist"
                  >
                    Drivers
                  </a>
                </li>

                {/*<li className="-mb-px mr-2 last:mr-0 flex-auto text-center cursor-pointer">
                  <a
                    className={
                      "text-lg text-base px-5 py-3 block " +
                      (openTab === 4
                        ? "text-red-600 border-b-2 border-red-600 bg-" + color + "-600"
                        : "text-" + color + "-600 bg-white")
                    }
                    onClick={e => handleChangeTab('coupon', 4, e)}
                    data-toggle="tab"
                    role="tablist"
                  >
                    Coupon Codes
                  </a>
                </li>*/}
              </ul>

              <div className={openTab === 1 ? "block" : "hidden"} id="link1">
                {(subStatistics.length > 0) ?
                  <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-6 px-4">
                    {subStatistics.map((item) => (
                      <div key={item.name} className="relative bg-gray-50 pt-5 px-6 pb-6  shadow rounded-lg overflow-hidden">
                        <dt className="text-base font-medium text-indigo-600 truncate">{item.name}</dt>
                        <dd className="mt-1 text-3xl font-semibold text-gray-900">{(item) ? item.stat : <Skeleton width={40} height={25} />}</dd>
                      </div>
                    ))}
                  </dl>
                  : <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-6 px-4">
                    <Skeleton wrapper={Box} />
                    <Skeleton wrapper={Box} />
                    <Skeleton wrapper={Box} />
                    <Skeleton wrapper={Box} />
                    <Skeleton wrapper={Box} />
                    <Skeleton wrapper={Box} />
                  </dl>}
              </div>
              <div className={openTab === 2 ? "block" : "hidden"} id="link1">
                {(subStatistics.length > 0) ?
                  <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-6 px-4">
                    {subStatistics.map((item) => (
                      <div key={item.name} className="relative bg-gray-50 pt-5 px-6 pb-6  shadow rounded-lg overflow-hidden">
                        <dt className="text-base font-medium text-indigo-600 truncate">{item.name}</dt>
                        <dd className="mt-1 text-3xl font-semibold text-gray-900">{(item) ? item.stat : <Skeleton width={40} height={25} />}</dd>
                      </div>
                    ))}
                  </dl>
                  : <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-6 px-4">
                    <Skeleton wrapper={Box} />
                    <Skeleton wrapper={Box} />
                    <Skeleton wrapper={Box} />
                    <Skeleton wrapper={Box} />
                    <Skeleton wrapper={Box} />
                    <Skeleton wrapper={Box} />
                  </dl>}
              </div>
              <div className={openTab === 3 ? "block" : "hidden"} id="link1">
                {(subStatistics.length > 0) ?
                  <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-6 px-4">
                    {subStatistics.map((item) => (
                      <div key={item.name} className="relative bg-gray-50 pt-5 px-6 pb-6  shadow rounded-lg overflow-hidden">
                        <dt className="text-base font-medium text-indigo-600 truncate">{item.name}</dt>
                        <dd className="mt-1 text-3xl font-semibold text-gray-900">{(item) ? item.stat : <Skeleton width={40} height={25} />}</dd>
                      </div>
                    ))}
                  </dl>
                  : <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-6 px-4">
                    <Skeleton wrapper={Box} />
                    <Skeleton wrapper={Box} />
                    <Skeleton wrapper={Box} />
                    <Skeleton wrapper={Box} />
                    <Skeleton wrapper={Box} />
                    <Skeleton wrapper={Box} />
                  </dl>}
              </div>
              {/*<div className={openTab === 4 ? "block" : "hidden"} id="link1">
                <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-6 px-4">
                  {subStatistics.map((item) => (
                    <div key={item.name} className="relative bg-gray-50 pt-5 px-6 pb-6  shadow rounded-lg overflow-hidden">
                      <dt className="text-base font-medium text-red-600 truncate">{item.name}</dt>
                      <dd className="mt-1 text-3xl font-semibold text-gray-900">{item.stat}</dd>
                    </div>
                  ))}
                </dl>
              </div>*/}
            </div>
          </div>
        </div>
      </div>

      <div className=" px-3 py-3 grid md:grid-cols-3 gap-6">
        <div className="bg-white shadow sm:rounded-lg">
          <div className="px-4 py-5 sm:px-6">
            <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
              Top Performing Vehicles
            </h2>
          </div>
          <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
            <div className="shadow-lg mt-5 mb-5 chart-wrapper">
              <Line data={{
                labels: vehicleLabel,
                datasets: [
                  {
                    label: "Vehicles",
                    data: vehicleData,
                    fill: true,
                    backgroundColor: "rgb(19, 125, 197,0.5)",
                    borderColor: "rgba(19, 125, 197)"
                  }
                ]
              }} />
            </div>
          </div>
        </div>
        <div className="bg-white shadow sm:rounded-lg">
          <div className="px-4 py-5 sm:px-6">
            <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
              Top Performing Drivers
            </h2>
          </div>
          <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
            <div className="shadow-lg mt-5 mb-5 chart-wrapper">
              <Line data={{
                labels: driverLabel,
                datasets: [
                  {
                    label: "Driver",
                    data: driverData,
                    fill: true,
                    backgroundColor: "rgb(19, 125, 197,0.5)",
                    borderColor: "rgba(19, 125, 197)"
                  }
                ]
              }} />
            </div>
          </div>
        </div>
        {/* <div className="bg-white shadow sm:rounded-lg">
          <div className="px-4 py-5 sm:px-6">
            <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
              Top Performing Companies
            </h2>
          </div>
          <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
            <div className="shadow-lg mt-5 mb-5 chart-wrapper">
              <Line data={{
                labels: companyLabel,
                datasets: [
                  {
                    label: "Company",
                    data: companyData,
                    fill: true,
                    backgroundColor: "rgb(19, 125, 197,0.5)",
                    borderColor: "rgba(19, 125, 197)"
                  }
                ]
              }} />
            </div>
          </div>
        </div>
        <div className="bg-white shadow sm:rounded-lg">
          <div className="px-4 py-5 sm:px-6">
            <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
              Top Performing Dispatchers
            </h2>
          </div>
          <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
            <div className="shadow-lg mt-5 mb-5 chart-wrapper">
              <Line data={{
                labels: dispatcherLabel,
                datasets: [
                  {
                    label: "Dispatcher",
                    data: dispatcherData,
                    fill: true,
                    backgroundColor: "rgb(19, 125, 197,0.5)",
                    borderColor: "rgba(19, 125, 197)"
                  }
                ]
              }} />
            </div>
          </div>
        </div>
        <div className="bg-white shadow sm:rounded-lg">
          <div className="px-4 py-5 sm:px-6">
            <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
              Top Performing Partners
            </h2>
          </div>
          <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
            <div className="shadow-lg mt-5 mb-5 chart-wrapper">
              <Line data={{
                labels: partnerLabel,
                datasets: [
                  {
                    label: "Partner",
                    data: partnerData,
                    fill: true,
                    backgroundColor: "rgb(19, 125, 197,0.5)",
                    borderColor: "rgba(19, 125, 197)"
                  }
                ]
              }} />
            </div>
          </div>
        </div> */}
      </div>
    </>
  );

}

export default function TabsRender() {
  return (
    <>
      <Tabs color="white" />
    </>
  );
}